import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SimpleAlertComponent } from './simple-alert/simple-alert.component';

export type SimpleAlertOptions = {
  message: string;
  details?: string;
  okText?: string;
  cancelText?: string;
  defaultInput?: string;
};

export const SimpleAlertOptionsDefaults: SimpleAlertOptions = {
  message: '',
  okText: 'Ok',
  cancelText: 'Cancel'
};

@Injectable({
  providedIn: 'root'
})
export class SimpleUIService {
  modalRef!: BsModalRef;
  currentOptions: SimpleAlertOptions = { message: '' };

  constructor(private modalService: BsModalService) { }

  public async showSimpleAlert(args: any[]): Promise<boolean> {
    if (args.length === 1 && typeof args[0] === 'string') {
      alert(args[0]);
      return Promise.resolve(true);
    }
    const options = args[0] as SimpleAlertOptions;
    this.currentOptions = { ...SimpleAlertOptionsDefaults, ...options };

    return new Promise((resolve) => {
      this.modalRef = this.modalService.show(SimpleAlertComponent, { ignoreBackdropClick: true, });
      (<SimpleAlertComponent>this.modalRef.content).options = this.currentOptions;
      (<SimpleAlertComponent>this.modalRef.content).exitModal = (ok?: string) => {
        this.modalRef.hide();
        this.modalRef = undefined!;
        resolve(ok !== undefined); // Changed this line to correctly resolve based on which button was clicked
      };
    });
  }

  public async showTextPrompt(args: any[]): Promise<string | undefined> {
    let options = args[0] as SimpleAlertOptions;
    options = { ...SimpleAlertOptionsDefaults, ...options };

    return new Promise((resolve) => {
      this.modalRef = this.modalService.show(SimpleAlertComponent, { ignoreBackdropClick: true, });
      (<SimpleAlertComponent>this.modalRef.content).options = options;
      (<SimpleAlertComponent>this.modalRef.content).promptForInput = true;
      (<SimpleAlertComponent>this.modalRef.content).exitModal = (ok?: string) => {
        resolve(ok);
        this.modalRef.hide();
        this.modalRef = undefined!;
      };
    });
  }
}
