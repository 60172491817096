import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  API_BASE,
  ROLES,
  PERMISSIONS,
  DEFAULT_USER_ROLE,
} from '../../constants/general.constants';
import { Observable, Subject, of, throwError, timer, MonoTypeOperatorFunction } from 'rxjs';
import { combineLatestWith, map, catchError, tap, retryWhen, concatMap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  GlobalRole,
  Permission,
  PermissionId,
} from '../../interfaces/global-role.interface';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissionsSource = new Subject<Permission[]>();
  private rolesSource = new Subject<GlobalRole[]>();
  private readonly maxRetries = 3;
  private readonly initialRetryDelay = 1000; // 1 second

  permissions$ = this.permissionsSource.asObservable();
  roles$ = this.rolesSource.asObservable();

  permissions = toSignal<Permission[], Permission[]>(this.permissions$, {
    initialValue: [],
  });
  roles = toSignal<GlobalRole[], GlobalRole[]>(this.roles$, {
    initialValue: [],
  });

  private isAuthorized = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(AuthenticationService) private authService: AuthenticationService
  ) { }

  private checkAuthentication(): boolean {
    if (!this.authService.isAuthenticated()) {
      //this.router.navigate(['/not-authenticated']);
      return false;
    }
    return true;
  }

  private retryWithBackoff<T>(): MonoTypeOperatorFunction<T> {
    return retryWhen(errors =>
      errors.pipe(
        concatMap((error, index) => {
          const retryAttempt = index + 1;
          if (retryAttempt > this.maxRetries) {
            return throwError(() => error);
          }
          console.log(`Retry attempt ${retryAttempt} for permissions/roles...`);
          // Exponential backoff formula: 2^x * initialDelay
          const delay = Math.pow(2, retryAttempt) * this.initialRetryDelay;
          return timer(delay);
        })
      )
    );
  }

  getRoles(): Observable<GlobalRole[]> {
    if (!this.checkAuthentication()) {
      return of([]);
    }

    if (this.isAuthorized && this.roles().length > 0) {
      return of(this.roles());
    }

    return this.http.get<GlobalRole[]>(`${API_BASE}${ROLES}`).pipe(
      tap((roles) => {
        if (roles && roles.length > 0) {
          this.rolesSource.next(roles);
          this.isAuthorized = true;
        } else {
          throw new Error('Empty roles array received');
        }
      }),
      this.retryWithBackoff<GlobalRole[]>(),
      catchError((error: HttpErrorResponse | Error) => {
        console.error('Error fetching roles after retries:', error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.isAuthorized = false;
        }
        return of([]);
      })
    );
  }

  getPermissions(): Observable<Permission[]> {
    if (!this.checkAuthentication()) {
      return of([]);
    }

    if (this.isAuthorized && this.permissions().length > 0) {
      return of(this.permissions());
    }

    return this.http.get<Permission[]>(`${API_BASE}${PERMISSIONS}`).pipe(
      tap((permissions) => {
        if (permissions && permissions.length > 0) {
          this.permissionsSource.next(permissions);
        } else {
          throw new Error('Empty permissions array received');
        }
      }),
      this.retryWithBackoff<Permission[]>(),
      catchError((error: HttpErrorResponse | Error) => {
        console.error('Error fetching permissions after retries:', error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          this.isAuthorized = false;
        }
        return of([]);
      })
    );
  }

  roleWithId(roleId: number): GlobalRole | undefined {
    return this.roles().find((role) => role.roleId === roleId);
  }

  roleHasPermission(
    role: GlobalRole | undefined,
    permissionId: PermissionId
  ): boolean {
    return role?.permissions.includes(permissionId) || false;
  }

  getUserRoleWithPermissions(user: any): Observable<any> {
    if (!this.checkAuthentication()) {
      return of({});
    }

    return this.roles$.pipe(
      combineLatestWith(this.permissions$),
      map(([roles, permissions]) => {
        const userRole =
          roles.find(({ roleId }) => roleId === user.roleId) ||
          DEFAULT_USER_ROLE;
        const userPermissions = permissions
          .filter(
            ({ id }) =>
              typeof userRole !== 'string' && userRole.permissions.includes(id)
          )
          .map(({ name }) => name);

        return {
          [typeof userRole !== 'string' ? userRole.name : 'default']:
            userPermissions,
        };
      })
    );
  }
}
