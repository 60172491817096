import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileItem } from 'src/app/shared/interfaces/file.interface';

@Component({
  selector: 'app-file-info-dialog',
  templateUrl: './file-info-dialog.component.html',
  styleUrls: ['./file-info-dialog.component.scss']
})
export class FileInfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<FileInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public file: FileItem
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
