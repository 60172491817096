import {
  Inject,
  Injectable,
  Optional,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DARK_MODE_OPTIONS } from './dark-mode-options';
import { defaultOptions } from './default-options';
import { isNil } from './isNil';
import { MediaQueryService } from './media-query.service';
import { DarkModeOptions } from './types';

export type ThemeMode = 'system' | 'dark' | 'light';

@Injectable({ providedIn: 'root' })
export class DarkModeService {
  private readonly options: DarkModeOptions;
  private readonly renderer: Renderer2;
  private readonly darkModeSubject$: BehaviorSubject<boolean>;
  private readonly themeModeSubject$: BehaviorSubject<ThemeMode>;

  constructor(
    private rendererFactory: RendererFactory2,
    private mediaQueryService: MediaQueryService,
    @Optional()
    @Inject(DARK_MODE_OPTIONS)
    private providedOptions: DarkModeOptions | null
  ) {
    this.options = { ...defaultOptions, ...(this.providedOptions || {}) };
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.themeModeSubject$ = new BehaviorSubject<ThemeMode>(
      this.getInitialThemeMode()
    );
    this.darkModeSubject$ = new BehaviorSubject(this.getInitialDarkModeValue());

    this.initializeTheme();
    this.setupSystemThemeListener();
    this.removePreloadingClass();
  }

  get darkMode$(): Observable<boolean> {
    return this.darkModeSubject$.asObservable().pipe(distinctUntilChanged());
  }

  get themeMode$(): Observable<ThemeMode> {
    return this.themeModeSubject$.asObservable().pipe(distinctUntilChanged());
  }

  setTheme(mode: ThemeMode): void {
    this.themeModeSubject$.next(mode);
    this.saveThemeModeToStorage(mode);
    this.updateTheme(mode);
  }

  private updateTheme(mode: ThemeMode): void {
    if (mode === 'system') {
      const systemPrefersDark = this.mediaQueryService.prefersDarkMode();
      systemPrefersDark ? this.enable() : this.disable();
    } else if (mode === 'dark') {
      this.enable();
    } else {
      this.disable();
    }
  }

  private setupSystemThemeListener(): void {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent | MediaQueryList) => {
      if (this.themeModeSubject$.getValue() === 'system') {
        e.matches ? this.enable() : this.disable();
      }
    };

    mediaQuery.addEventListener('change', handleChange);
  }

  private enable(): void {
    const { element, darkModeClass, lightModeClass } = this.options;
    this.renderer.removeClass(element, lightModeClass);
    this.renderer.addClass(element, darkModeClass);
    this.darkModeSubject$.next(true);
  }

  private disable(): void {
    const { element, darkModeClass, lightModeClass } = this.options;
    this.renderer.removeClass(element, darkModeClass);
    this.renderer.addClass(element, lightModeClass);
    this.darkModeSubject$.next(false);
  }

  private initializeTheme(): void {
    const currentTheme = this.themeModeSubject$.getValue();
    this.updateTheme(currentTheme);
  }

  private getInitialThemeMode(): ThemeMode {
    const savedTheme = this.getThemeModeFromStorage();
    return savedTheme || 'system';
  }

  private getInitialDarkModeValue(): boolean {
    const currentTheme = this.getInitialThemeMode();
    if (currentTheme === 'system') {
      return this.mediaQueryService.prefersDarkMode();
    }
    return currentTheme === 'dark';
  }

  private saveThemeModeToStorage(mode: ThemeMode): void {
    localStorage.setItem(
      this.options.storageKey,
      JSON.stringify({ themeMode: mode })
    );
  }

  private getThemeModeFromStorage(): ThemeMode | null {
    const storageItem = localStorage.getItem(this.options.storageKey);

    if (storageItem) {
      try {
        return JSON.parse(storageItem)?.themeMode;
      } catch (error) {
        console.error(
          'Invalid themeMode localStorage item:',
          storageItem,
          'falling back to system preference'
        );
      }
    }

    return null;
  }

  private removePreloadingClass(): void {
    setTimeout(() => {
      this.renderer.removeClass(
        this.options.element,
        this.options.preloadingClass
      );
    });
  }
}
