import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { User } from '../../interfaces/user.interface';
import { UserService } from '../../services/user/user.service';
import {
  EMPTY,
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { RoleService } from '../../services/role/role.service';
import { GlobalRole } from '../../interfaces/global-role.interface';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectInviteResponse } from '../../interfaces/project-invite.interface';
import { Project } from '../../interfaces/project.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { CONTRIBUTOR_ROLE_ID } from '../../constants/general.constants';

@AutoUnsubscribe()
@Component({
  selector: 'app-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: ['./manage-team.component.scss'],
})
export class ManageTeamComponent implements OnInit, OnDestroy {
  @Input() projectId!: any;

  userCtrl = new FormControl();

  public usersForm = this._formBuilder.group({
    user: this.userCtrl,
    role: ['', [Validators.required]],
    // role: ['', [this.projectVersionToRoleValidator.bind(this)]],
  });

  project: Project | any = {};

  users: User[] | any = [];

  projectUsers: User[] | any = [];

  projectInvites: ProjectInviteResponse[] | any = [];

  roles: GlobalRole[] | any = [];

  separatorKeysCodes = [COMMA, ENTER];

  // ────────────────────────────────────────────────────────────────────────────────
  // User Autocomplete
  usersAutocompleteData!: Observable<unknown> | undefined | any;

  userLoading = false;
  noUsersFound = false;
  // ────────────────────────────────────────────────────────────────────────────────

  constructor(
    private userService: UserService,
    private roleService: RoleService,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.userCtrl.setValidators(Validators.required);

    this.getProject(this.projectId);
    this.getRoles();
    this.prepareUserAutocomplete();

    if (this.roles.length > 0) {
      this.usersForm.get('role')?.setValue(this.roles[0]);
    }
  }

  // eslint-disable-next-line
  ngOnDestroy() { }

  // ────────────────────────────────────────────────────────────────────────────────
  // User autocomplete
  // ────────────────────────────────────────────────────────────────────────────────

  private prepareUserAutocomplete() {
    this.usersAutocompleteData = this.userCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((val: string): any => {
        return this.getUsers(val);
      })
    );
  }

  private getUsers(val: string): Observable<any[]> {
    if (typeof val !== 'string') {
      return EMPTY;
    }
    val = GeneralHelpers.removeSpaces(val);
    this.noUsersFound = false;
    return this.userService.getUsers().pipe(
      map((response) =>
        response.filter((option: any) => {
          if (!!option.name === false || val === '') {
            return;
          }
          return option.name.toLowerCase().indexOf(val.toLowerCase()) === 0;
        })
      ),
      tap((user: User[]) => {
        if (user.length === 0) {
          this.noUsersFound = true;
        }
      })
    );
  }

  public displayUser(user: User | any) {
    if (user === null) {
      return '';
    }
    if (typeof user === 'object') {
      return `${user.email} | ${user.name}`;
    }
    return '';
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Api calls
  // ────────────────────────────────────────────────────────────────────────────────

  private getInvitesForProject(): void {
    this.projectService
      .getInvitesForProject(this.projectId)
      .subscribe((invites: ProjectInviteResponse[]) => {
        this.projectInvites = this.processInvites(invites);
      });
  }

  private getRoles(): void {
    this.roleService.get().subscribe((roles: any) => {
      this.roles = roles;
      this.getInvitesForProject();
    });
  }

  private getProject(projectId: number): void {
    this.projectService.getById(`${projectId}`).subscribe((project: any) => {
      this.project = project;
      this.projectUsers = project.users;
    });
  }

  public sendUserInvite(): void {
    if (this.usersForm.invalid) {
      return;
    }

    const user = this.usersForm.get('user')?.value as User;
    const role = this.usersForm.get('role')?.value as unknown as GlobalRole;
    const inviteData: { inviteeId: number; roleId: number } = {
      inviteeId: user.userId ? user.userId : 0,
      roleId: role.roleId,
    };

    this.projectService
      .inviteUserToProject(this.projectId, inviteData)
      .subscribe(() => {
        this.toastr.success('User invited successfully');
        this.usersForm.reset();
        this.getInvitesForProject();
        this.resetFrom();
      });
  }

  public manageUserInvitation(invite: ProjectInviteResponse): void {
    if (invite.response === 'Accepted') {
      this.removeUserFromProjectTeam(invite);
    } else if (invite.response === 'Rejected') {
      this.cancelInvite(invite);
    } else if (invite.response === 'Open') {
      this.cancelInvite(invite);
    }
  }

  private cancelInvite(invite: ProjectInviteResponse): void {
    this.projectService
      .cancelInviteForProject(invite.projectId, invite.inviteId)
      .subscribe(() => {
        this.toastr.success('Invite cancelled successfully');
        this.getInvitesForProject();
      });
  }

  private removeUserFromProjectTeam(invite: ProjectInviteResponse): void {
    this.projectService
      .removeUserFromProjectTeam(invite.projectId, invite.inviteeId)
      .subscribe(() => {
        this.toastr.success('User was removed successfully from team');
        this.getInvitesForProject();
      });
  }

  private resetFrom(): void {
    GeneralHelpers.resetForm(this, 'usersForm');
  }

  public getDisplayName(name: string, isHidden: boolean): string {
    return isHidden ? '*'.repeat(name.length) : name;
  }

  public getInviteStatusClass(invite: ProjectInviteResponse): string {
    if (invite.response === 'Accepted') {
      return 'invite-accepted';
    } else if (invite.response === 'Rejected') {
      return 'invite-rejected';
    } else if (invite.response === 'Open') {
      return 'invite-open';
    }
    return '';
  }

  private processInvites(
    invites: ProjectInviteResponse[]
  ): ProjectInviteResponse[] {
    return invites.map((invite: ProjectInviteResponse) => ({
      ...invite,
      roleName:
        this.roles.find((role: GlobalRole) => role.roleId === invite.roleId)
          ?.name || 'Unknown Role',
    }));
  }

  private projectVersionToRoleValidator(formControl: FormControl): any {
    const role = formControl?.value;
    let validation = null;

    if (!role) {
      validation = { required: true };
    }

    if (role?.roleId === CONTRIBUTOR_ROLE_ID && this.project?.versions?.length <= 1) {
      validation = { invalidRole: true };
    }

    return validation;
  }
  // ────────────────────────────────────────────────────────────────────────────────
  // Api calls
  // ────────────────────────────────────────────────────────────────────────────────
}
