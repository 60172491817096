import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import {
  VERSION,
  PROJECT,
  BOOK,
  SNAPSHOT,
  API_BASE,
  FILES,
} from '../../constants/general.constants';
import { AppStateService } from '../app-state/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class SnapshotService {
  #appState = inject(AppStateService);

  constructor(private http: HttpClient) {}

  // Create a snapshot for a notebook
  createSnapshot(data: {
    name: string;
    cacheId: number;
    content: any;
  }): Observable<any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();

    return this.http.post(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${SNAPSHOT}`,
      data
    );
  }

  // Get a list of snapshots for a notebook
  getSnapshots(): Observable<any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();

    return this.http.get(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${SNAPSHOT}`
    );
  }

  // Get details of a specific snapshot
  getSnapshot(snapshotId: number): Observable<any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();

    if (!!snapshotId === false || snapshotId === 0) {
      return EMPTY;
    }

    return this.http.get(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${SNAPSHOT}/${snapshotId}`
    );
  }

  // Delete a specific snapshot
  deleteSnapshot(snapshotId: number): Observable<any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();

    if (!!snapshotId === false || snapshotId === 0) {
      return EMPTY;
    }

    return this.http.delete(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${SNAPSHOT}/${snapshotId}`
    );
  }

  // Get files associated with a snapshot
  getSnapshotFile(
    snapshotId: number,
    fileId: string,
    responseType: 'text' | 'blob' | 'arraybuffer' | 'document' | 'json' = 'text'
  ): Observable<any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();

    return this.http.get(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${SNAPSHOT}/${snapshotId}/${FILES}/${fileId}`,
      { responseType } as any
    );
  }

  // New method to get snapshot ID from the current URL
  getSnapshotIdFromUrl(): number | null {
    const url = new URL(window.location.href);
    const snapshotId = url.searchParams.get('snapshotId');
    return snapshotId ? parseInt(snapshotId, 10) : null;
  }
}
