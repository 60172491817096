export const DEBUG_ACTIONS = [
  {
    icon: 'skip_previous',
    action: 'next-command',
    tooltip: 'next command',
    color: 'primary',
    aria: 'next command',
    testId: 'next-command'
  },
  {
    icon: 'skip_next',
    action: 'previous-command',
    tooltip: 'previous command',
    color: 'primary',
    aria: 'previous command',
    testId: 'previous-command'
  },
  {
    icon: 'backspace',
    action: 'clear-console',
    tooltip: 'clear console',
    color: 'primary',
    aria: 'clear console',
    testId: 'clear-console'
  },
]
