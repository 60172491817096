import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowExplorerComponent } from './arrow-explorer/arrow-explorer.component';
import { AlertModule } from 'ngx-bootstrap/alert'
import { TabsModule } from 'ngx-bootstrap/tabs';
import {MatIconModule} from '@angular/material/icon';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { SchemaViewerComponent } from './schema-viewer/schema-viewer.component'
import { ViewsListComponent } from './views-list/views-list.component';
import { DatasetViewerComponent } from './dataset-viewer/dataset-viewer.component';
import { MatTabsModule } from '@angular/material/tabs';
import { TableListModule } from '../table-list/table-list.module';
import { TableExplorerComponent } from './table-explorer/table-explorer.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileExplorerComponent } from './file-explorer/file-explorer.component';
import { MatTableModule } from '@angular/material/table'

@NgModule({
  declarations: [
    ArrowExplorerComponent,
    SchemaViewerComponent,
    ViewsListComponent,
    DatasetViewerComponent,
    TableExplorerComponent,
    FileExplorerComponent,
  ],
  imports: [
    CommonModule,
    AlertModule,
    MatCardModule,
    MatIconModule,
    MatTableModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    GuiGridModule,
    TabsModule,
    TableListModule,
  ],
  exports: [ ArrowExplorerComponent ],
})
export class ArrowExplorerModule { }
