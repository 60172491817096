import { Injectable } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import * as python from 'pyodide';
import {
  FILE_EXTENSIONS,
  LOAD_SNAPSHOT,
  RELOAD_FILES,
  RELOAD_NOTEBOOK_FILES,
} from '../../constants/general.constants';
import {
  EHQ,
  CUSTOM_METHODS_EXPORT,
  CUSTOM_METHODS_IMPORT,
  ARROW_FLIGHT,
  STORE,
  CONVERT,
} from '../../constants/additional-methods.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import {
  ChunkContext,
  ExecutionContext,
} from '../../interfaces/chunk/chunk-context.interface';
import { FileItem, FileModule, NotebookFile } from '../../interfaces/file.interface';
import { FileService } from '../file/file.service';
import { NotebookVariablesService } from '../notebook-variables/notebook-variables.service';
import { MessageService } from '../message/message.service';
import { RenderService } from '../render/render.service';
import { CanvasService } from '../canvas/canvas.service';
import { ArrowWebsocketService } from '../arrow-websocket/arrow-websocket.service';
import { NotebookFilesService } from '../notebook-files/notebook-files.service';
import { CacheService } from '../cache/cache.service';
import { FileUnifiedService } from '../file-unified/file-unified.service';
import { ConvertService } from '../convert/convert.service';
// Env
import { environment } from '../../../../environments/environment';
import { DuckDbService } from '../duck-db/duck-db.service';
import { WasmStoreService } from '../wasm/store/wasm-store.service';
import { WasmSearchService } from '../wasm/search/wasm-search.service';
import { WasmViewService } from '../wasm/viewer/wasm-viewer.service';
import { SimpleUIService } from '../simple-ui/simple-ui.service';
import { ProjectVariablesService } from '../project-variables/project-variables.service';
import { CdnModuleService } from '../cdn-module/cdn-module.service';
// python modules
import { PythonModulesService } from './python-modules.service';
import { PythonRuntimeService } from './python-runtime.service';
import { PythonModuleManager } from './python-module-manager.service';
import { PythonHelper } from './python.helper';
import { ChunkService } from '../chunk/chunk.service';

@Injectable({
  providedIn: 'root',
})
export class PythonService {
  protected static jsExternalModulesNamespaces = [EHQ, ARROW_FLIGHT, STORE, CONVERT]
  public python: any;
  private chunkData: any;
  private chunkContext!: ExecutionContext | ChunkContext;
  // private result = '';
  private isLoaded: boolean = false;
  private messageShown: boolean = false;
  private readonly mountDir = '/wheels';
  private micropip: any;
  private notebookFilesShouldBeReloaded: boolean = true;
  private notebookFiles: NotebookFile[] = [];
  // ─────────────────────────────────────────────────────────────────────

  // Message subscription
  private readonly messageSubscription!: Subscription;

  constructor(
    private readonly notebookVariablesService: NotebookVariablesService,
    private readonly messageService: MessageService,
    private readonly notebookFilesService: NotebookFilesService,
    // should be presented in constructor for making it available in the service scope
    // for calling related functionality from pyodide scope with properly services context
    private readonly fileService: FileService,
    private readonly cacheService: CacheService,
    private readonly fileUnifiedService: FileUnifiedService,
    private readonly projectVariablesService: ProjectVariablesService,
    private readonly chunkService: ChunkService,
    // ─────────────────────────────────────────────────────────────────────
    // Services for custom methods
    private readonly wasmStoreService: WasmStoreService,
    private readonly wasmSearchService: WasmSearchService,
    private readonly wasmViewService: WasmViewService,
    private readonly renderService: RenderService,
    private readonly canvasService: CanvasService,
    private readonly arrowWebsocketService: ArrowWebsocketService,
    private readonly duckDbService: DuckDbService,
    private readonly simpleUIService: SimpleUIService,
    private readonly convertService: ConvertService,
    // ─────────────────────────────────────────────────────────────────────
    private readonly cdnModuleService: CdnModuleService,
    private readonly pythonModulesService: PythonModulesService,
    private readonly pythonRuntime: PythonRuntimeService,
    private readonly moduleManager: PythonModuleManager,
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe(async (message: any) => {
        if ([LOAD_SNAPSHOT, RELOAD_NOTEBOOK_FILES, RELOAD_FILES].some((action) => action === message?.text)) {
          this.notebookFilesShouldBeReloaded = true;
        }
      });
  }

  async init() {
    this.python =
      this.python ??
      (await python.loadPyodide({
        indexURL: `https://cdn.jsdelivr.net/pyodide/${environment.pyodideVersion}/full/`,
        fullStdLib: true,
      }));

    // Initialize filesystem
    this.python.FS.mkdir(this.mountDir);
    this.python.FS.mount(
      this.python.FS.filesystems.MEMFS,
      { root: '.' },
      this.mountDir
    );

    // Load required packages
    await this.python.loadPackage('micropip');
    this.micropip = this.python.pyimport('micropip');

    // Initialize all services
    await this.pythonRuntime.initialize(
      this.python, PythonService.jsExternalModulesNamespaces
    );
    await this.moduleManager.initialize(
      this.python, PythonService.jsExternalModulesNamespaces
    );
    await this.pythonModulesService.initialize(
      this.python, PythonService.jsExternalModulesNamespaces
    );

    try {
      await this.installCodeAutoformatter();
    } catch (error) {
      console.error('Error installing autoformatter:', error);
    }

    this.isLoaded = true;
  }

  async run(context: ChunkContext): Promise<Chunk | undefined> {
    try {
      this.chunkContext = context;
      this.chunkData = context.getChunk();

      // Handle notebook files
      if (this.notebookFilesShouldBeReloaded) {
        this.notebookFiles = await this.notebookFilesService.getNotebookFiles();
        if (this.notebookFiles.length > 0) {
          await this.addFilesToPyodide(this.notebookFiles);
        }
        this.notebookFilesShouldBeReloaded = false;
      }

      let content = this.getContentWithVariablesReplaced(context.getChunkContent()).trim();

      if (!content) {
        this.chunkData = null;
        return;
      }

      if (!this.isLoaded) {
        if (!this.messageShown) {
          context.addMessage('Python runtime still loading', 'info');
          this.messageShown = true;
        }
        this.chunkData = null;
        return;
      }

      // Should be presented in strict order
      // 1) Add global variables
      this.addGlobalVariables(this.chunkData);
      // 2) Register external JS modules
      content = this.registerExternalJSModules(content);
      // 3) Process JS modules and imports
      await this.processGlobalModules();
      // 4) Process dependencies modules created from a chunks first
      await this.processModuleDependencies(content, context as any);
      // 5) Process imports
      await this.processLoadingPackagesFromImports(content);
      // --------------------------------------

      // Now handle chunks with modules - global data is already available
      const registration = await this.pythonModulesService.registerChunkAsModule(
        this.chunkData,
        content,
        context as any
      );

      // Execute code
      const result = await this.pythonRuntime.executeCode(content, null, registration.moduleContext.context_id);

      // TODO: should be uncommented if diagnostics info will be needed
      // await this.pythonModulesService.diagnoseSystemState();

      // Process results - global data is available in Python's globals()
      let globalVariables = this.python.globals.get('data').toJs();
      this.python.globals.get('data').destroy();
      globalVariables = GeneralHelpers.fromMapToObject(globalVariables);

      this.notebookVariablesService.addVarsFromRawToList(
        globalVariables,
        this.chunkData
      );

      // Cleanup
      this.unregisterExternalJSModules(content);
      const innerPyodideFiles = this.getMountedFiles();
      await this.addFilesToNotebook(innerPyodideFiles);

      if (result.output.includes('Error')) {
        throw new Error(result.output);
      }

      context.addOutput(result.output, 'success', 'python');

      context.setBusy(false, 'done');

      return context.getChunk();
    } catch (error: any) {
      context.addMessage('Script execution failed: ' + error.message, 'danger');
      context.addOutput(error, 'error', 'python');
      context.setBusy(false, 'done');

      return context.getChunk();
    } finally {
      this.chunkData = null;
    }
  }

  private getContentWithVariablesReplaced(content: string) {
    const chunkContent = GeneralHelpers.replaceGlobals(
      content,
      this.projectVariablesService.variableList
    );
    return chunkContent;
  }

  private async addFilesToPyodide(files: NotebookFile[] | FileItem[], context = this.chunkContext) {
    for await (const file of files) {
      const pathName = `${file.name}`;

      try {
        return this.python.FS.stat(pathName);
      } catch (error) {
        console.error(error);
      }

      const notebookFile = await this.fileUnifiedService.getFile([file.name], context);

      try {
        const fileData = await GeneralHelpers.convertToUint8Array(
          notebookFile,
          (file as any).fileType
        );

        if (fileData) {
          this.python.FS.writeFile(pathName, fileData);
        }
      } catch (error) {
        console.log('Error sync files into pyodide: ', pathName, error);
      }
    }
  }

  /**
   * Processes and adds files from Pyodide filesystem to notebook
   * @param files Map of timestamp and file paths from Pyodide filesystem
   */
  private async addFilesToNotebook(files: Map<string, string>): Promise<void> {
    try {
      // Get current notebook files
      let notebookFiles: any[] = await firstValueFrom(
        this.cacheService.getCacheContent()
      );
      if (!notebookFiles?.length) {
        notebookFiles = this.notebookFiles;
      }

      let notebookFilesShouldBeReloaded = false;

      for (const [timestamp, filePath] of files) {
        const fileName = GeneralHelpers.getNameWithExtension(filePath);
        const existingFile = GeneralHelpers.findElementInArrayByProp(
          notebookFiles,
          'name',
          fileName
        );

        if (!existingFile?.fileId) {
          console.log(`File ${fileName} not found in notebook files, skipping`);
          continue;
        }

        try {
          existingFile.content = await firstValueFrom(
            this.cacheService.getCacheContentFile(existingFile.fileId, 'arraybuffer')
          );
        } catch (error) {
          console.warn(`Error getting file ${fileName} content:`, error);
        }

        if (!existingFile.content) {
          try {
            existingFile.content = await this.notebookFilesService.getFileContent(existingFile.fileId, true, 'arraybuffer');
          } catch (error) {
            console.warn(`Error processing file ${fileName}:`, error);
          }
        }

        if (!existingFile.content) {
          try {
            existingFile.content = await this.notebookFilesService.getFileContent(existingFile.fileId, false, 'arraybuffer');
          } catch (error) {
            console.warn(`Error processing file ${fileName}:`, error);
          }
        }

        if (!existingFile.content) {
          try {
            existingFile.content = await firstValueFrom(
              this.fileService.getById(existingFile.fileId, 'arraybuffer')
            );
          } catch (error) {
            console.warn(`Error processing file ${fileName}:`, error);
          }
        }

        try {
          // console.log(`Processing file: ${fileName}`);
          // Read file content from Pyodide filesystem
          const fileContent = this.python.FS.readFile(filePath, {
            encoding: 'binary',
          });

          // Calculate hash of new content
          const newHash = await PythonHelper.calculateFileHash(fileContent);

          // If file exists, compare hashes
          if (existingFile) {
            // Calculate hash of existing content
            const existingHash = await PythonHelper.calculateFileHash(existingFile.content);

            if (newHash === existingHash) {
              console.log(`File ${fileName} unchanged (hash: ${newHash}), skipping`);
              continue;
            }

            console.log(`File ${fileName} changed (hash: ${newHash} vs ${existingHash}), updating`);
          }

          // Create file objects
          const blob = new Blob([fileContent], {
            type: 'application/octet-stream',
          });
          const file = new File([blob], fileName, {
            type: 'application/octet-stream',
            lastModified: Number(timestamp),
          });

          // Prepare form data
          const formData = new FormData();
          formData.append('payload', file);

          if (existingFile) {
            // Update existing file
            await firstValueFrom(
              this.cacheService.updateCacheContentFile(existingFile.id, formData)
            );
            console.log(`Updated file: ${fileName} (hash: ${newHash})`);
          } else {
            // Create new file
            await firstValueFrom(
              this.cacheService.createCacheContent(formData)
            );
            console.log(`Created new file: ${fileName} (hash: ${newHash})`);
          }

          notebookFilesShouldBeReloaded = true;
        } catch (error) {
          console.error(`Error processing file ${fileName}:`, error);
        }
      }

      if (notebookFilesShouldBeReloaded) {
        this.messageService.sendMessage(RELOAD_NOTEBOOK_FILES);
      }
    } catch (error) {
      // TODO: think about how to handle this error and why it's happen after success chunk running
      console.warn('Error managing notebook files:', error);
    }
  }

  private async processLoadingPackagesFromImports(content: string) {
    if (content.includes('import')) {
      // loading modules regarding https://pyodide.org/en/latest/usage/loading-packages.html#loading-packages
      await this.python.loadPackagesFromImports(content, {
        checkIntegrity: true,
        errorCallback: (message: string) => {
          console.log(message);
        },
        messageCallback: (message: string) => {
          console.log(message);
        },
      });
    }
  }

  // Global chunk variables should be added from calling
  private addGlobalVariables(chunkData: Chunk) {
    const globalDataVariables: { [key: string]: string } = {};

    if (
      Array.isArray(this.notebookVariablesService.variableList) &&
      this.notebookVariablesService.variableList.length > 0
    ) {
      for (const element of this.notebookVariablesService.variableList) {

        if (chunkData.sortOrder >= element.sortOrder) {
          globalDataVariables[element.name] = element.value;
        }
      }
    }

    // Now run Python code that loads the data
    this.python.runPython(`
        import json

        def recursive_json_loads(obj):
            if isinstance(obj, dict):
                # Recursively process each value in the dictionary
                return {k: recursive_json_loads(v) for k, v in obj.items()}
            elif isinstance(obj, list):
                # Recursively process each element in the list
                return [recursive_json_loads(item) for item in obj]
            elif isinstance(obj, str):
                # Try to parse the string as JSON
                try:
                    parsed = json.loads(obj)
                    # If successful, recursively process the parsed result as well
                    return recursive_json_loads(parsed)
                except (json.JSONDecodeError, TypeError):
                    # If it's not valid JSON, return the original string
                    return obj
            else:
                # For other data types (int, float, bool, None), just return as-is
                return obj

        globals()['data'] = recursive_json_loads(${JSON.stringify(JSON.stringify(globalDataVariables))})
      `);

    return globalDataVariables;
  }

  private getMountedFiles(): Map<string, string> {
    const files = new Map<string, string>();
    const iterateFileSystem = (startPath = '/') => {
      const explore = (path: string) => {
        if (path === this.mountDir) {
          return;
        }

        const contents = this.python.FS.readdir(path);
        contents.forEach((item: any) => {
          if (item === '.' || item === '..') {
            // Skip the current and parent directory entries
            return;
          }
          const fullPath = `${path}/${item}`;
          const stats = this.python.FS.stat(fullPath);

          if (this.python.FS.isDir(stats.mode)) {
            // If it's a directory, recurse into it
            // console.log(`Found dir: ${fullPath}`);
            explore(fullPath);
          } else if (this.python.FS.isFile(stats.mode)) {
            // If it's a file, perform some action
            console.log(`Found Mounted file: ${fullPath}`);
            files.set(stats.mtime.getTime(), fullPath);
          }
        });
      };

      try {
        explore(startPath);
      } catch (error) {
        console.log('Finishing filesystem iteration: ', error);
      }
    };

    iterateFileSystem();

    return files;
  }

  private processCustomMethods() {
    const mainHandler: any = [];

    // Export methods
    // Methods will be called from Python
    CUSTOM_METHODS_EXPORT.forEach((element: any) => {
      if (!mainHandler[element.globalName]) {
        mainHandler[element.globalName] = {};
      }

      mainHandler[element.globalName][element.method] = (...args: any) => {
        args = args.map((arg: any) => PythonHelper.pythonToJs(arg, this.python));

        // hot to propagate runtime name into calling context
        (this as any)[element.serviceToUse][element.methodToCall](args, this.chunkContext, 'python');
      };
    });
    // end

    // Import methods
    // Methods will be called from Python
    CUSTOM_METHODS_IMPORT.forEach((element: any) => {
      if (!mainHandler[element.globalName]) {
        mainHandler[element.globalName] = {};
      }

      mainHandler[element.globalName][element.method] = async (...args: any) => {
        // convert pyodide objects to JS objects
        args = args.map((arg: any) => PythonHelper.pythonToJs(arg, this.python));

        const result = await (this as any)[element.serviceToUse][
          element.methodToCall
        ](args, this.chunkContext);

        return result && this.jsToPython(result);
      };
    });
    // end

    return mainHandler;
  }

  private getPresentedExternalJSModules(content: string) {
    const modules = PythonService.jsExternalModulesNamespaces;
    const presentedExternalJSModules = modules.filter((jsModuleName) =>
      content.includes(`${jsModuleName}.`)
    );

    return presentedExternalJSModules;
  }

  private registerExternalJSModules(content: string) {
    const presentedExternalJSModules = this.getPresentedExternalJSModules(content);
    let importsExternalJSModules = '';

    if (presentedExternalJSModules.length > 0) {
      const mainHandler = this.processCustomMethods();

      presentedExternalJSModules.forEach((jsModuleName) => {
        this.python.registerJsModule(jsModuleName, mainHandler[jsModuleName]);

        importsExternalJSModules += `import ${jsModuleName}\n`;
      });
    }

    return `${importsExternalJSModules}${content}`;
  }

  private unregisterExternalJSModules(content: string) {
    const presentedExternalJSModules =
      this.getPresentedExternalJSModules(content);

    if (presentedExternalJSModules.length > 0) {
      presentedExternalJSModules.forEach((jsModuleName) =>
        this.python.unregisterJsModule(jsModuleName)
      );
    }
  }

  private async processGlobalModules(): Promise<any> {
    try {
      const mainHandler = this.processCustomMethods();

      PythonService.jsExternalModulesNamespaces.forEach(jsModuleName => {
        this.python.registerJsModule(jsModuleName, mainHandler[jsModuleName]);
      });

      const list: FileModule[] = await this.fileService.getFileModuleList([
        FILE_EXTENSIONS.whl,
      ]);
      const hasModules = Array.isArray(list) && list.length > 0;
      const hasGlobal =
        hasModules && list.some((fileModule: FileModule) => fileModule.isGlobal);

      if (hasGlobal) {
        const results = [];

        for await (const item of list) {
          const file = await this.fileService.getLocalforageItem(item.name);
          const resultedFile = file || this.fileService.fileList.find(({ name }) => item.name === name);

          if (resultedFile) {
            results.push(resultedFile);
          } else {
            results.push(item);
          }
        }

        if (results.length === 0) {
          console.warn('ProcessGlobalModules: No valid files found for global modules');
          return;
        }

        // Define proper interfaces for the objects
        interface ModuleItem {
          name: string;
          fsitemId: string | number;
          [key: string]: any;
        }

        interface FileItem {
          name: string;
          content?: ArrayBuffer;
          [key: string]: any;
        }

        const modulesList = (list as ModuleItem[]).filter((value: ModuleItem, index: number, self: ModuleItem[]) => {
          return self.findIndex((item: ModuleItem) => item.name === value.name) === index;
        });

        const files = (results as FileItem[]).filter((value: FileItem, index: number, self: FileItem[]) => {
          return self.findIndex((item: FileItem) => item.name === value.name) === index;
        });

        for (const element of modulesList) {
          let file = files.find((file: FileItem) => file.name === element.name);

          if (!file?.content) {
            file = await firstValueFrom(
              this.fileService.getById(element.fsitemId, 'arraybuffer')
            ) as FileItem;
          }

          if (file && (file instanceof ArrayBuffer || file.content)) {
            await this.installPackageWheel({
              ...element,
              content: file instanceof ArrayBuffer ? file : file.content,
              notebookId: element['notebookId'] || '',
              isGlobal: element['isGlobal'] || true,
              extension: element['extension'] || '.whl'
            });

            console.log('Global module installed:', element.name);
          }
        }
      }
    } catch (error) {
      console.warn('Error in processGlobalModules:', error);
      throw error;
    }
  }

  private async installPackageWheel(file: FileModule & { content: any }) {
    // File system should be implemented here and related files could be loaded
    // https://github.com/pyodide/micropip/blob/274d0b3e6186f1b7dff210fff3cc7dc494072d64/micropip/_commands/install.py
    // https://pyodide.org/en/stable/usage/api/js-api.html#pyodide.FS
    // https://emscripten.org/docs/api_reference/Filesystem-API.html
    const filePath = `${this.mountDir}/${file.name}`;

    try {
      this.python.FS.stat(filePath);
    } catch (error) {
      console.log('Error python.FS.stat: ', error);
    }

    if (file.content instanceof ArrayBuffer) {
      try {
        const fileData = new Uint8Array(file.content);

        this.python.FS.writeFile(filePath, fileData, { encoding: 'utf8' });

        return await this.micropip.install(`emfs:${filePath}`);
      } catch (error) {
        console.warn('Error micropip.install: ', error);
      }
    }
  }

  private jsToPython(jsObject: any): any {
    const jsToPython = `
      from pyodide.ffi import JsProxy

      def process_data(data):
          # Handle ArrayBuffer
          # if isinstance(data, memoryview):
          #  return np.frombuffer(data, dtype=np.uint8).tobytes()

          # Check if it's a JsProxy object
          if isinstance(data, JsProxy):
              # Convert other JsProxy objects to Python objects
              data = data.to_py()

              # Check for Uint8Array specifically
              if hasattr(data, 'BYTES_PER_ELEMENT') and hasattr(data, 'buffer'):
                  # Convert Uint8Array to Python bytes
                  return bytes(data)

          if isinstance(data, dict):
              for key, value in data.items():
                  data[key] = process_data(value) # Recursive call

          elif isinstance(data, list):
              for i, value in enumerate(data):
                  data[i] = process_data(value) # Recursive call

          elif isinstance(data, set):
              data = {process_data(value) for value in data} # Recursive call

          return data
    `;
    const jsObjectName = `jsObjectToPython_${GeneralHelpers.randomNumber()}`;
    (window as any)[jsObjectName] = jsObject;
    const result = this.python.runPython(`
      from js import ${jsObjectName}
      ${jsToPython}
      process_data(${jsObjectName})
    `);

    // TODO: think about how to remove this from window scope and move to python registry js data
    delete (window as any)[jsObjectName];

    return result;
  }

  private async installCodeAutoformatter(): Promise<void> {
    // Install black if not already installed
    try {
      console.log('Installing python autoformatter...');

      const packages = await firstValueFrom(this.cdnModuleService.getFromPyPi('black'));
      const codeAutoFormatter = packages.files?.findLast((file: any) => file.url.endsWith('.whl'));

      await this.micropip.install(codeAutoFormatter.url);

      console.log('Autoformatter installed successfully');
    } catch (error) {
      console.error('Error installing autoformatter:', error);
    };
  }

  async formatCode(
    code: string,
    options = {
      lineLength: 100,
      fast: 'True',
      targetVersion: ['PY37'],
    },
  ): Promise<string> {
    if (!this.isLoaded) {
      throw new Error('Python runtime is not loaded');
    }

    try {
      const formatCode = `
        import black
        import io

        def format_code(source_code, options):
            try:
                # Configure black with default options
                mode = black.FileMode(
                    line_length=options.get('line_length', 88),
                    target_versions={black.TargetVersion[v] for v in options.get('target_version', ['PY37'])}
                )

                # Format the code
                formatted_code = black.format_file_contents(
                    source_code,
                    fast=options.get('fast', False),
                    mode=mode
                )

                return formatted_code
            except Exception as e:
                return f"Error formatting code: {str(e)}"

        # Format the input code
        resultAfterFormatting = format_code(codeBeforeFormatting, {
            'line_length': ${options.lineLength},
            'fast': ${options.fast},
            'target_version': ${JSON.stringify(options.targetVersion)}
        })
      `;

      // Set the code in Python's global namespace
      this.python.globals.set('codeBeforeFormatting', code);

      // Run the formatting code
      await this.pythonRuntime
        .getPythonRuntime()
        .runPythonAsync(formatCode);

      // Get the formatted result
      const result = this.python.globals.get('resultAfterFormatting');

      // Clean up globals
      this.python.globals.delete('codeBeforeFormatting');
      this.python.globals.delete('resultAfterFormatting');

      // Check if the result contains error messages
      if (typeof result === 'string') {
        if (result.startsWith('Syntax Error:')) {
          throw new Error(result);
        }
        else if (result.startsWith('Error formatting code')) {
          return code;
        }
      }

      return result;
    } catch (error: any) {
      console.error('Error formatting Python code:', error);
      throw new Error(`Failed to format Python code: ${error.message}`);
    }
  }

  private async processModuleDependencies(
    content: string,
    context: ChunkContext | any,
  ): Promise<void> {
    try {
      const { dependencies } = this.moduleManager.analyzeDependencies(content);
      const depsRegistration: any = [];

      if (dependencies.length) {
        for (const dep of dependencies) {
          const chunk = this.chunkService.chunkList.find(
            chunk => this.moduleManager.sanitizeModuleName(chunk.name) === dep
          );

          if (!chunk) {
            throw new Error(`Dependency module ${dep} not found`);
          }

          const meta = await this.pythonModulesService.registerChunkAsModule(
            chunk,
            chunk.content,
            context
          )

          depsRegistration.push({
            chunk,
            meta,
          });
        };
      }

      if (depsRegistration.length) {
        for (const { chunk, meta } of depsRegistration) {
          const depResult = await this.pythonRuntime.executeCode(
            chunk.content,
            null,
            meta.moduleContext.context_id
          );

          if (depResult.error) {
            throw new Error(`Error executing dependency ${chunk.name}: ${depResult.error}`);
          }
        }
      }

      await this.pythonModulesService.importModulesForChunk(content, context);
    } catch (error) {
      console.error('Error processing module dependencies:', error);
      throw error;
    }
  }
}
