import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSearchComponent } from './header-search.component';

// ─────────────────────────────────────────────────────────────────────────────
// Auto complete
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// ─────────────────────────────────────────────────────────────────────────────
// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ─────────────────────────────────────────────────────────────────────────────
// Pipes
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    // Material
    MatFormFieldModule,
    MatInputModule,
    // Auto complete
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,
    // Pipes
    PipesModule,
  ],
  declarations: [HeaderSearchComponent],
  exports: [HeaderSearchComponent]
})
export class HeaderSearchModule { }
