import { CollectionViewer, DataSource } from "@angular/cdk/collections"
import { BehaviorSubject, Observable } from "rxjs"
import { CloudNode } from "../cloud-schema.interface"
import { computed, signal } from "@angular/core"

export class CloudFileDataSource implements DataSource<CloudNode> {
  readonly #dataSubject = new BehaviorSubject<CloudNode[]>([]);
  readonly #rootNode: CloudNode;
  readonly historyStack = signal<CloudNode[]>([]);
  readonly displayedNode = computed(() => this.historyStack()[this.historyStack().length - 1]);

  selectedIndex = signal<number>(-1);

  constructor(rootNode: CloudNode) {
    this.#rootNode = rootNode;
    this.historyStack.set([rootNode]);
    this.#dataSubject.next(this.#rootNode.children);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  connect(_collectionViewer: CollectionViewer): Observable<readonly CloudNode[]> {
    return this.#dataSubject.asObservable();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disconnect(_collectionViewer: CollectionViewer): void {
    // reset to root data source
    this.#dataSubject.next(this.#rootNode.children);
  }

  selectRow(row: CloudNode): void {
    this.selectedIndex.set(this.#dataSubject.value.indexOf(row));
  }

  displayFolder(folder: CloudNode): void {
    if (this.displayedNode() !== folder) {
      this.historyStack.set([...this.historyStack(), folder]);
    }
    this.#dataSubject.next(folder.children);
  }

  get showingRootNode(): boolean {
    return this.historyStack().length === 1;
  }

  backToParent(): void {
    if (this.showingRootNode) {
      return;
    }
    this.historyStack.set(this.historyStack().slice(0, -1));
    this.#dataSubject.next(this.historyStack()[this.historyStack().length - 1].children);
  }
}
