import { Pipe, PipeTransform } from '@angular/core';
import { GeneralHelpers } from '../helpers/general.helper';

@Pipe({
  name: 'prettyjson',
  pure: true,
})
export class PrettyJsonPipe implements PipeTransform {
  transform(value: any, args: any[]): any {
    try {
      return this.applyColors(
        typeof value === 'object' ? value : JSON.parse(
          GeneralHelpers.normalizeSingleQuotesInJson(value)
        ),
        args[0],
        args[1],
        args[2],
      );
    } catch (e) {
      console.warn('PrettyJsonPipe Invalid JSON:', value, e);
      return this.applyColors({ error: 'Invalid JSON' }, args[0], args[1], args[2]);
    }
  }

  private getThemeClass(value: string): string {
    if (value.startsWith('"')) {
      return value.endsWith(':') ? 'key' : 'string';
    }
    if (/^(true|false)$/.test(value)) {
      return 'boolean';
    }
    if (value === 'null') {
      return 'null';
    }
    return 'number';
  }

  applyColors(
    obj: any,
    showNumberLine: boolean = false,
    padding: number = 4,
    enableColors: boolean = true
  ) {
    let line = 1;

    if (typeof obj !== 'string') {
      obj = JSON.stringify(obj, undefined, 3);
    }

    // Handle HTML special characters
    obj = obj.replace(/[&<>]/g, (char: string | number) => {
      const entities: { [key: string]: string } = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
      };
      return entities[char];
    });

    if (enableColors) {
      // Split the complex regex into smaller parts
      const patterns = [
        { pattern: /"[^"]*"(\s*:)?/g, type: 'quoted' }, // Strings and keys
        { pattern: /\b(true|false)\b/g, type: 'boolean' }, // Booleans
        { pattern: /\bnull\b/g, type: 'null' }, // Null
        { pattern: /-?\d+(\.\d+)?([eE][+-]?\d+)?/g, type: 'number' }, // Numbers
      ];

      // Apply formatting for each pattern
      for (const { pattern } of patterns) {
        obj = obj.replace(pattern, (match: string) => {
          const themeClass = this.getThemeClass(match);
          return `<span class="${themeClass}">${match}</span>`;
        });
      }
    }

    return showNumberLine
      ? obj.replace(
        /^/gm,
        () =>
          `<span class="number-line pl-3 select-none">${String(line++).padEnd(
            padding
          )}</span>`
      )
      : obj;
  }
}
