import { RawChunkType } from '../interfaces/chunk/chunk.interface';

export class ChunkType {
  private readonly _rawData: RawChunkType;

  public get chunktypeId() { return this._rawData.chunktypeId; };
  public get name() { return this._rawData.name; };
  public get modifiesEnv() { return this._rawData.modifiesEnv; };
  public get executable() { return this._rawData.executable; };
  public get format() { return this._rawData.format; };

  constructor(data: RawChunkType) {
    this._rawData = data;
  }

  public get isText() {
    return this.format === 'text';
  }

  public get isMarkdown() {
    return this.chunktypeId === 1;
  }

  public get isJavascript() {
    return this.chunktypeId === 2;
  }

  public get isPython() {
    return this.chunktypeId === 3;
  }

  public get isEquation() {
    return this.chunktypeId === 4;
  }

  public get isVegaLite() {
    return this.chunktypeId === 5;
  }

  public get isDuckDB() {
    return this.chunktypeId === 6;
  }

  public get isTable() {
    return this.chunktypeId === 7;
  }

  public get isForm() {
    return this.chunktypeId === 8;
  }

  public get outputIsFromCode() {
    return this.isJavascript || this.isPython || this.isDuckDB;
  }

  public get outputIsHtml() {
    return this.isMarkdown || this.isEquation;
  }

  public get supportsFullscreenOutput() {
    return !this.isForm;
  }
}
