import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { AppStateService } from '../app-state/app-state.service';
import {
  SearchRequest,
  SearchResponse,
  SearchMethod,
} from './models/search.types';
import { API_BASE } from '../../constants/general.constants';

const DEFAULT_SEARCH_LIMIT = 10;

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  readonly #appState = inject(AppStateService);
  
  public $searchResultsSubject = new Subject<SearchResponse>();
  public lastSearchResults: SearchResponse | null = null;

  constructor(private readonly http: HttpClient) {}

  // Helper method to construct search-related URLs
  private createSearchUrl(resource?: string): string {
    let url = `${API_BASE}/search`;
    if (resource) {
      url += `/${resource}`;
    }
    return url;
  }

  search(params: SearchRequest): Observable<SearchResponse> {
    const defaultParams: Partial<SearchRequest> = {
      method: 'like',
      versions: 'latest',
      offset: 0,
      limit: DEFAULT_SEARCH_LIMIT
    };

    const searchParams = { ...defaultParams, ...params };
    
    return this.http.post<SearchResponse>(this.createSearchUrl(), searchParams).pipe(
      retry(1),
      map((response: SearchResponse) => {
        this.lastSearchResults = response;
        this.$searchResultsSubject.next(response);
        return response;
      }),
      catchError(error => {
        console.error('Search failed:', error);
        return throwError(() => new Error(
          error.error?.message || 'Search failed. Please try again later.'
        ));
      })
    );
  }

  searchProjects(
    query: string, 
    method: SearchMethod = 'like',
    limit: number = DEFAULT_SEARCH_LIMIT
  ): Observable<SearchResponse> {
    return this.search({
      query,
      method,
      versions: this.#appState.isHeadVersion() ? 'head' : 'latest',
      project_search: true,
      chunk_search: false,
      limit
    });
  }

  searchProjectChunks(
    projectId: number, 
    query: string,
    notebookId?: number
  ): Observable<SearchResponse> {
    return this.search({
      query,
      method: 'like',
      versions: this.#appState.isHeadVersion() ? 'head' : 'latest',
      project_search: false,
      chunk_search: true,
      project_id: projectId,
      notebook_id: notebookId
    });
  }

  /**
   * Search across all content types within a specific project
   */
  searchWithinProject(
    projectId: number,
    query: string,
    method: SearchMethod = 'like'
  ): Observable<SearchResponse> {
    return this.search({
      query,
      method,
      versions: this.#appState.isHeadVersion() ? 'head' : 'latest',
      project_search: true,
      chunk_search: true,
      project_id: projectId
    });
  }

  /**
   * Clear the last search results and notify subscribers
   */
  clearSearchResults(): void {
    this.lastSearchResults = null;
    this.$searchResultsSubject.next({
      projects: [],
      notebooks: [],
      chunks: []
    });
  }

  /**
   * Filter search results by rank threshold
   */
  filterResultsByRank(
    results: SearchResponse, 
    threshold: number = 0.5
  ): SearchResponse {
    return {
      projects: results.projects.filter(p => p.rank >= threshold),
      notebooks: results.notebooks,
      chunks: results.chunks.filter(c => c.rank >= threshold)
    };
  }
}
