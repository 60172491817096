import { Injectable } from '@angular/core';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { ToastrService } from 'ngx-toastr';

interface ModuleRegistryEntry {
  id: string;
  name: string;
  code: string;
  namespace: any;
  isLoaded: boolean;
  lastUsed: number;
  useCount: number;
  dependencies: string[];
  exports: string[];
}

interface DependencyAnalysis {
  dependencies: string[];
  missingDependencies: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PythonModuleManager {
  private moduleRegistry: Map<string, ModuleRegistryEntry> = new Map();
  private moduleNameToId: Map<string, string> = new Map();
  private pythonRuntime: any;
  private jsExternalModulesNamespaces: any;
  private moduleCounter: number = 0;
  private isInitialized: boolean = false;

  constructor(private toastr: ToastrService) { }

  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    if (this.isInitialized) {
      return;
    }

    this.pythonRuntime = runtime;
    this.jsExternalModulesNamespaces = jsExternalModulesNamespaces;

    await this.setupModuleSystem();

    this.isInitialized = true;
  }

  private async setupModuleSystem() {
    const setupCode = `
      import sys
      import io
      import traceback
      from types import ModuleType

      class ModuleManager:
          def __init__(self):
              self._module_cache = {}
              self._name_mapping = {}  # Maps sanitized names to module IDs

          def create_module(self, module_id: str, module_name: str, module_code: str) -> bool:
              """Creates a new Python module with isolation."""
              try:
                  # Create new module object
                  module = ModuleType(module_id)
                  module.__file__ = f'<virtual_module>/{module_id}.py'
                  module.__package__ = None

                  # Create isolated namespace
                  namespace = module.__dict__
                  namespace['__builtins__'] = __builtins__
                  namespace['data'] = globals()['data']

                  # js_external_modules_namespaces = ${JSON.stringify(this.jsExternalModulesNamespaces)}
                  # print(js_external_modules_namespaces)

                  # for module in js_external_modules_namespaces:
                  #    if module in globals():
                  #        namespace[module] = globals()[module]
                  #        print(namespace[module])

                  # Register both the ID and sanitized name
                  sys.modules[module_id] = module
                  sanitized_name = module_name.lower().replace(' ', '_')
                  sys.modules[sanitized_name] = module
                  self._name_mapping[sanitized_name] = module_id

                  try:
                      # Execute module code in isolated namespace
                      exec(module_code, namespace)
                      self._module_cache[module_id] = module
                      return True
                  except Exception as e:
                      self._cleanup_module(module_id, sanitized_name)
                      raise e

              except Exception as e:
                  self._cleanup_module(module_id, module_name.lower().replace(' ', '_'))
                  raise RuntimeError(f"Module creation failed: {str(e)}")

          def _cleanup_module(self, module_id: str, sanitized_name: str):
              """Clean up module references."""
              if module_id in sys.modules:
                  del sys.modules[module_id]
              if sanitized_name in sys.modules:
                  del sys.modules[sanitized_name]
              if sanitized_name in self._name_mapping:
                  del self._name_mapping[sanitized_name]
              if module_id in self._module_cache:
                  del self._module_cache[module_id]

          def import_module(self, name: str) -> ModuleType:
              """Imports a module by name or ID."""
              sanitized_name = name.lower().replace(' ', '_')

              # Try direct import first
              if sanitized_name in sys.modules:
                  return sys.modules[sanitized_name]

              # Try mapped name
              if sanitized_name in self._name_mapping:
                  module_id = self._name_mapping[sanitized_name]
                  if module_id in sys.modules:
                      return sys.modules[module_id]

              raise ImportError(f"Module '{name}' not found")

          def get_module_exports(self, module_id: str) -> list:
              """Gets list of public exports from a module."""
              if module_id in sys.modules:
                  module = sys.modules[module_id]
              else:
                  sanitized_name = module_id.lower().replace(' ', '_')
                  if sanitized_name in sys.modules:
                      module = sys.modules[sanitized_name]
                  else:
                      raise ImportError(f"Module {module_id} not found")

              exports = []
              for name in dir(module):
                  if not name.startswith('_'):  # Only public attributes
                      exports.append(name)
              return exports

          def unload_module(self, module_id: str) -> bool:
              """Unloads a module from the system."""
              if module_id in self._module_cache:
                  sanitized_name = module_id.lower().replace(' ', '_')
                  self._cleanup_module(module_id, sanitized_name)
                  return True
              return False

      # Create global instance
      module_manager = ModuleManager()

      # Helper function to wrap module operations
      def wrap_module_operation(operation):
          """Wraps module operations with proper error handling."""
          try:
              return operation()
          except Exception as e:
              print(f"Error in module operation: {str(e)}")
              raise
    `;

    try {
      await this.pythonRuntime.runPythonAsync(setupCode);
    } catch (error) {
      console.error('Failed to setup module system:', error);
      this.toastr.error('Failed to initialize Python module system', 'Initialization Error');
      throw error;
    }
  }

  async registerModule(
    name: string,
    code: string,
    context?: ChunkContext
  ): Promise<boolean> {
    if (!this.isInitialized) {
      throw new Error('Module manager not initialized');
    }

    const moduleId = this.generateModuleId(name);
    const sanitizedName = this.sanitizeModuleName(name);

    try {
      // Create the Python code for module registration
      const registrationCode = `
        wrap_module_operation(lambda: module_manager.create_module(
            "${moduleId}",
            "${sanitizedName}",
            ${JSON.stringify(code.trim())}
        ))
      `;

      const success = await this.pythonRuntime.runPythonAsync(registrationCode);

      if (success) {
        // Get module exports
        const exportsCode = `module_manager.get_module_exports("${moduleId}")`;
        const exports = await this.pythonRuntime.runPythonAsync(exportsCode);

        // Update registry
        this.moduleRegistry.set(moduleId, {
          id: moduleId,
          name: sanitizedName,
          code: code.trim(),
          namespace: await this.pythonRuntime.globals.get(moduleId),
          isLoaded: true,
          lastUsed: Date.now(),
          useCount: 0,
          dependencies: [],
          exports
        });

        // Update name mapping
        this.moduleNameToId.set(sanitizedName, moduleId);

        return true;
      }

      return false;

    } catch (error) {
      const errorMessage = `Failed to register module ${name}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  async importModule(moduleName: string, context?: ChunkContext): Promise<any> {
    try {
      const sanitizedName = this.sanitizeModuleName(moduleName);
      const importCode = `wrap_module_operation(lambda: module_manager.import_module("${sanitizedName}"))`;

      return await this.pythonRuntime.runPythonAsync(importCode);

    } catch (error) {
      const errorMessage = `Failed to import module ${moduleName}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  private generateModuleId(name: string): string {
    const sanitizedName = this.sanitizeModuleName(name);
    return `${sanitizedName}_${this.moduleCounter++}`;
  }

  private sanitizeModuleName(name: string): string {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9_]/g, '_')
      .replace(/^[^a-z]/, 'mod_$&');
  }
  /**
   * Public method to analyze dependencies in Python code
   */
  async analyzeDependencies(code: string): Promise<DependencyAnalysis> {
    const dependencies = this.extractDependencies(code);
    const missingDependencies = dependencies.filter(
      dep => !this.moduleRegistry.has(dep)
    );

    return {
      dependencies,
      missingDependencies
    };
  }

  private async validateDependencies(
    dependencies: string[],
    context?: ChunkContext
  ): Promise<boolean> {
    for (const dep of dependencies) {
      if (!this.moduleRegistry.has(dep)) {
        const error = `Missing dependency: ${dep}`;
        if (context) {
          context.addMessage(error, 'danger');
        }
        throw new Error(error);
      }
    }
    return true;
  }

  private extractDependencies(code: string): string[] {
    const dependencies = new Set<string>();
    const importRegex = /^(?:from\s+(\S+)\s+import|import\s+([^as\s]+))/gm;

    let match;
    while ((match = importRegex.exec(code)) !== null) {
      const moduleName = (match[1] || match[2]).split('.')[0];
      if (this.moduleRegistry.has(moduleName)) {
        dependencies.add(moduleName);
      }
    }

    return Array.from(dependencies);
  }

  async updateModule(
    moduleId: string,
    newCode: string,
    context?: ChunkContext
  ): Promise<boolean> {
    try {
      const entry = this.moduleRegistry.get(moduleId);
      if (!entry) {
        throw new Error(`Module ${moduleId} not found`);
      }

      // Unload existing module
      await this.pythonRuntime.runPythonAsync(
        `module_manager.unload_module("${moduleId}")`
      );

      // Register updated module
      const success = await this.registerModule(entry.name, newCode, context);

      if (!success) {
        // Rollback to old version on failure
        await this.registerModule(entry.name, entry.code, context);
        throw new Error('Module update failed, rolled back to previous version');
      }

      return success;

    } catch (error) {
      const errorMessage = `Failed to update module ${moduleId}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  getModuleInfo(moduleId: string): ModuleRegistryEntry | undefined {
    return this.moduleRegistry.get(moduleId);
  }

  listModules(): Array<{ id: string, name: string, exports: string[] }> {
    return Array.from(this.moduleRegistry.values())
      .map(({ id, name, exports }) => ({ id, name, exports }));
  }

  async validateModuleDependencies(
    dependencies: string[],
    context?: ChunkContext
  ): Promise<boolean> {
    const missingDeps = dependencies.filter(dep => !this.moduleRegistry.has(dep));

    if (missingDeps.length > 0) {
      const error = `Missing dependencies: ${missingDeps.join(', ')}`;
      if (context) {
        context.addMessage(error, 'danger');
      }
      throw new Error(error);
    }

    return true;
  }
}
