import {
  Component,
  OnInit,
  Inject,
  inject,
  computed,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotebookVariablesService } from '../../services/notebook-variables/notebook-variables.service';
import { ProjectVariablesService } from '../../services/project-variables/project-variables.service';
import _ from 'lodash';
import { GeneralHelpers } from '../../helpers/general.helper';
import { GlobalVar } from '../../interfaces/global-var.interface';
import { MessageService } from '../../services/message/message.service';
import { Subscription } from 'rxjs';
import { ProjectConstant } from '../../interfaces/project-constants.interface';
import {
  EDIT_GLOBAL_VAR,
  EDIT_PROJECT_CONSTANT,
  INVALIDATE_CHUNKS,
} from '../../constants/general.constants';
import { AppStateService } from '../../services/app-state/app-state.service';
import { PermissionId } from '../../interfaces/global-role.interface';
import { ProjectService } from '../../services/project/project.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-global-var-dialog',
  templateUrl: './global-var-dialog.component.html',
  styleUrls: ['./global-var-dialog.component.scss'],
})
export class GlobalVarDialogComponent implements OnInit, OnDestroy {
  #appState = inject(AppStateService);
  #projectService = inject(ProjectService);

  hasWritePermission = computed(() =>
    this.#projectService.getCurrentUserHasProjectPermission(PermissionId.WRITE)
  );

  public globalVarForm!: FormGroup;
  public workMode: 'create' | 'update' = 'create';
  public serviceToUse: 'notebookVariablesService' | 'projectVariablesService' =
    'notebookVariablesService';
  public messageSubscription!: Subscription;
  public varList: GlobalVar | ProjectConstant | any = [];
  public generalHelpers = GeneralHelpers;
  public content: string = '';
  private chunkId!: number | any;
  public isMainVersion: boolean = this.#appState.isHeadVersion() ?? false;
  public showEmptyValueError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GlobalVarDialogComponent>,
    private _formBuilder: FormBuilder,
    private notebookVariablesService: NotebookVariablesService,
    private projectVariablesService: ProjectVariablesService,
    private messageService: MessageService
  ) {
    this.updateServiceUsage();
    this.varList = this[this.serviceToUse].variableList;
  }

  ngOnInit() {
    this.initForm();
    this.subscribeToMessages();

    if (this.data && this.data.variable) {
      this.updateForm(this.data.variable);
    }
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  private initForm() {
    // Only disable the form if we're in a version AND trying to create a new variable
    const shouldDisableForm = !this.isMainVersion && this.workMode === 'create';

    this.globalVarForm = this._formBuilder.group({
      name: [
        { value: '', disabled: shouldDisableForm },
        [
          Validators.required,
          // Allow letters (both cases), numbers, and symbols except spaces
          Validators.pattern(/^[a-zA-Z_$][a-zA-Z0-9_$]*$/),
        ],
      ],
      id: [],
    });
  }

  private subscribeToMessages() {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (
          message?.text === EDIT_GLOBAL_VAR ||
          message?.text === EDIT_PROJECT_CONSTANT
        ) {
          this.updateForm(message?.data?.variable);
        }
      });
  }

  public processGlobalVarForm(formValue: any, isValid: boolean, $event: Event) {
    $event.preventDefault();

    const contentIsEmpty = !this.content || this.content.trim().length === 0;
    this.showEmptyValueError = contentIsEmpty;

    if (!isValid || contentIsEmpty) {
      return;
    }

    if (!this.isUniqueName(formValue.name) && this.workMode !== 'update') {
      this.globalVarForm.controls['name'].setErrors({ notUnique: true });
      return;
    }

    const id = formValue.id || GeneralHelpers.getUuid();

    let value;
    try {
      // Try to parse the content as JSON first
      value = JSON.parse(this.content);
    } catch {
      // If it's not valid JSON, try to infer the type
      const trimmedContent = this.content.trim();
      if (trimmedContent === 'true') {
        value = true;
      } else if (trimmedContent === 'false') {
        value = false;
      } else if (trimmedContent === 'null') {
        value = null;
      } else if (trimmedContent === 'undefined') {
        value = undefined;
      } else if (!isNaN(Number(trimmedContent))) {
        value = Number(trimmedContent);
      } else {
        value = this.content; // Keep as string if no other type matches
      }
    }

    const processGlobalVarForm: any = {
      name: this.globalVarForm.getRawValue().name,
      value: value,
      id: id,
      sortOrder: 0,
    };

    if (this.workMode === 'create') {
      this[this.serviceToUse].add(processGlobalVarForm);
    } else {
      this[this.serviceToUse].update(id, processGlobalVarForm);
    }

    if (this.chunkId) {
      this.messageService.sendMessage(INVALIDATE_CHUNKS, [this.chunkId]);
      this.chunkId = null;
    }

    this.resetForm();
  }

  public edit(item: GlobalVar | any) {
    this.workMode = 'update';
    this[this.serviceToUse].edit(item.id);
    this.varList = this[this.serviceToUse].variableList;
  }

  public delete(item: GlobalVar | any) {
    this[this.serviceToUse].delete(item.id);
    this.varList = this[this.serviceToUse].variableList;
    this.resetForm();
  }

  private isUniqueName(name: string): boolean {
    return !_.some(
      this[this.serviceToUse].variableList,
      (item) =>
        item.name === name && item.id !== this.globalVarForm.get('id')?.value
    );
  }

  private updateForm(data: GlobalVar | ProjectConstant) {
    this.workMode = 'update';
    this.globalVarForm.patchValue({
      name: data.name,
      id: data.id,
    });
    this.content = this.setContent(data);
  }

  private resetForm() {
    this.workMode = 'create';
    this.content = '';
    this.showEmptyValueError = false;
    this.globalVarForm.reset();
    Object.values(this.globalVarForm.controls).forEach((control) => {
      control.setErrors(null);
      control.markAsUntouched();
      control.markAsPristine();
    });
  }

  public updateContent(data: any) {
    this.content = data;
    if (data && data.trim().length > 0) {
      this.showEmptyValueError = false;
    }
  }

  private updateServiceUsage() {
    if (this.data?.serviceToUse !== undefined) {
      this.serviceToUse = this.data.serviceToUse;
    }
  }

  public setFormTouched() {
    this.globalVarForm.markAsTouched();
  }

  private setContent(data: any): string {
    let value = data.value;
    if (typeof value === 'string') {
      try {
        const parsedValue = JSON.parse(value);
        value = parsedValue;
      } catch {
        // If it's not valid JSON, keep it as a string
      }
    }
    
    const type = GeneralHelpers.trueTypeOf(value);
    if (type === 'object' || type === 'array') {
      this.setChunkId(data);
      return GeneralHelpers.jsonStringify(value);
    }
    return value.toString();
  }

  private setChunkId(data: any) {
    if (data?.chunkId) {
      this.chunkId = data.chunkId;
    }
  }

  public getActionButtonText(): string {
    return this.workMode === 'update' ? 'Update' : 'Add';
  }
}
