<div class="row mt-2">
  <div class="col"><mat-spinner class="loading-spinner" *ngIf="this.loading()" [diameter]="9"/></div>
  <div class="col" *ngIf="this.loading()">{{ this.loadingStatus() }}</div>
</div>
<div class="row mt-2" *ngIf="this.dataSource">
  <div class="col">
    <button mat-icon-button (click)="this.dataSource.backToParent()" [disabled]="this.dataSource.showingRootNode" class="mr-4">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-button (click)="loadMore()" [disabled]="dataSource.displayedNode().nextMarker() === null" class="mr-4">Load More</button>
    <button mat-button (click)="loadRoot()">Reload</button>
  </div>
</div>
<div class="row mb-2">
  <div class="col" *ngIf="this.dataSource">
    <table mat-table [dataSource]="this.dataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th mat-header-cell class="size-cell" *matHeaderCellDef>Size</th>
        <td mat-cell class="size-cell" *matCellDef="let element">{{ element.filesize }}</td>
      </ng-container>
      <ng-container matColumnDef="lastModified">
        <th mat-header-cell class="date-cell" *matHeaderCellDef>Modified</th>
        <td mat-cell class="date-cell" *matCellDef="let element">{{ element.fileData?.last_modified ?? '-' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let index = index"
        [attr.id]="'cf' + index"
        (click)="singleClick(row)"
        (dblclick)="doubleClick(row)"
        (keydown.enter)="singleClick(row)"
        [ngClass]="{ highlighted: selectionModel.isSelected(row) }"
      ></tr>
    </table>
  </div>
</div>
