<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  <button mat-icon-button mat-dialog-close class="close-button">
    <mat-icon>close</mat-icon>
  </button>

  <div class="global-var-list-wrapper mt-2 mb-2">
    <div *ngFor="let item of varList" class="variable-item">
      <div class="variable-content">
        <strong>{{ item.name }} = {{ generalHelpers.trueTypeOf(item.value) === "array" || generalHelpers.trueTypeOf(item.value) === "object" ? (item.value | json) : item.value }}</strong>
      </div>
      <div class="variable-actions" *ngIf="hasWritePermission() && isMainVersion">
        <button mat-icon-button (click)="edit(item)" matTooltip="Edit">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(item)" matTooltip="Delete">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <form [formGroup]="globalVarForm" (ngSubmit)="processGlobalVarForm(globalVarForm.value, globalVarForm.valid, $event)" class="variable-form">
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px" class="mb-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Variable Name</mat-label>
        <input matInput formControlName="name" placeholder="Variable name">
        <mat-hint>Must start with a letter, $, or _. Can contain letters, numbers, $ and _</mat-hint>
        <mat-error *ngIf="globalVarForm.get('name')?.errors?.['required']">
          Name is required
        </mat-error>
        <mat-error *ngIf="globalVarForm.get('name')?.errors?.['pattern']">
          Invalid variable name format
        </mat-error>
        <mat-error *ngIf="globalVarForm.get('name')?.errors?.['notUnique']">
          Name must be unique
        </mat-error>
      </mat-form-field>

      <app-code-editor
        [content]="content"
        (updateContent)="updateContent($event)"
        (startEdit)="setFormTouched()"
        mode="javascript"
        class="w-100">
      </app-code-editor>

      <div *ngIf="showEmptyValueError" class="error-message">
        Value cannot be empty
      </div>
    </div>
  </form>

  <div class="action-buttons">
    <button mat-button mat-dialog-close class="cancel-button">
      Close
    </button>
    <button 
      mat-button 
      color="primary" 
      (click)="processGlobalVarForm(globalVarForm.value, globalVarForm.valid, $event)"
      [disabled]="!hasWritePermission() || !isMainVersion"
      class="action-button">
      {{ getActionButtonText() }}
    </button>
  </div>
</mat-dialog-content>