<div class="header">
  <div
    class="container-fluid"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div
      fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="40px"
      fxFlex="50"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div class="logo" routerLink="/projects"></div>
        <ng-container *ngIf="projectName() && notebookName()">
          <label
            class="project-notebook-name"
            [matTooltip]="projectName() + ': ' + notebookName()"
          >
            {{ projectName() }}: {{ notebookName() }}
          </label>
        </ng-container>
      </div>
      <app-header-search class="search-input-wrapper"></app-header-search>
    </div>
    <div
      fxFlex="40"
      class="user-info"
      fxLayout="row"
      fxLayoutAlign="end center"
      *ngIf="userProfile"
      fxLayoutGap="20px"
    >
      <ng-container *ngIf="project()">
        <div
          class="alert alert-warning header-alert"
          role="alert"
          *ngIf="!isMainVersion()"
        >
          Not editable ({{ currentVersion() }})
        </div>

        <div
          class="alert alert-warning header-alert"
          role="alert"
          *ngIf="showLockLabel"
        >
          Project is locked by {{ projectLockedBy }}
        </div>

        <div
          class="alert alert-info header-alert"
          role="alert"
          *ngIf="showOwnerUnlockLabel"
        >
          Unlock as owner
        </div>

        <button
          mat-raised-button
          color="accent"
          class="header-alert pt-0"
          *ngIf="showLockButton"
          (click)="doProjectLock()"
        >
          Lock
        </button>

        <button
          mat-raised-button
          color="warn"
          class="header-alert pt-0"
          *ngIf="showUnlockButton"
          (click)="doProjectUnlock()"
          [matTooltip]="
            'Locked by ' +
            (currentEditor.username &&
            currentEditor.username === currentUserName()
              ? 'you'
              : currentEditor.username)
          "
        >
          Unlock
        </button>

        <div class="role-label" *ngIf="userRole" [ngClass]="userRole">
          Role: {{ userRole }}
        </div>
      </ng-container>
      <button
        mat-icon-button
        [matMenuTriggerFor]="notificationList"
        (menuOpened)="onNotificationOpened()"
        [matBadge]="userNotifications.length"
        [matBadgeHidden]="userNotifications.length === 0"
        overlap="false"
        matBadgePosition="after"
        matBadgeColor="warn"
        matBadgeSize="medium"
        *ngIf="userNotifications.length > 0"
      >
        <mat-icon>notifications</mat-icon>
      </button>
      <div [matMenuTriggerFor]="menu">
        <div
          *ngIf="userProfile.picture !== null"
          class="user-info-avatar"
          [ngStyle]="{
            'background-image': 'url(' + userProfile.picture + ')'
          }"
        >
          <img
            [src]="userProfile.picture"
            appAvatarFallback
            alt="avatar"
            class="visibility-hidden"
          />
        </div>
        <div
          *ngIf="userProfile.picture === null"
          class="user-info-avatar"
        ></div>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="profile-menu" (opened)="onMenuOpened()">
  <div class="profile-menu-section">
    <div class="ml-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary">account_circle</mat-icon>
      <h5>Personal information</h5>
    </div>
    <button mat-menu-item routerLink="/my-profile">My Profile</button>
  </div>
  <div class="profile-menu-section">
    <div class="ml-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary">model_training</mat-icon>
      <h5>Annotation tool</h5>
    </div>
    <button mat-menu-item routerLink="/annotation-tool">Annotation tool</button>
  </div>
  <div class="profile-menu-section">
    <div class="ml-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon color="primary">folder</mat-icon>
      <h5>Recent projects</h5>
    </div>
    <button mat-menu-item routerLink="/projects">Projects</button>
  </div>
  <div class="profile-menu-section">
    <div class="ml-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>view_quilt</mat-icon>
      <h5>Panel Visibility</h5>
    </div>
    <mat-slide-toggle
      class="ml-3 mb-2"
      [checked]="leftPanelVisible"
      (change)="toggleLeftPanel()"
    >
      Left Panel
    </mat-slide-toggle>
    <mat-slide-toggle
      class="ml-3 mb-2"
      [checked]="rightPanelVisible"
      (change)="toggleRightPanel()"
    >
      Right Panel
    </mat-slide-toggle>
  </div>
  <div class="profile-menu-section" *ngIf="recentProjects.length > 0">
    <div class="ml-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>history</mat-icon>
      <h5>Recent projects</h5>
    </div>
    <div
      class="recent-projects-chips"
      fxLayout="row wrap"
      fxLayoutAlign="start center"
    >
      <mat-chip-set>
        <mat-chip
          *ngFor="let project of recentProjects"
          (click)="openRecentProject(project)"
          [matTooltip]="project.name ? project.name : 'unknown project'"
          matTooltipPosition="above"
          class="small-chip"
        >
          {{ project.name }}
        </mat-chip>
      </mat-chip-set>
    </div>
  </div>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Log Out</span>
  </button>
</mat-menu>

<mat-menu #notificationList="matMenu" class="notification-menu">
  <div class="notification-menu-header">
    <h5>Notifications</h5>
  </div>
  <div *ngFor="let entry of userNotifications" class="notification-entry">
    <div class="notification-entry-message">{{ entry.message }}</div>
    <div class="notification-entry-time">
      {{ entry.entryDate | date : "shortDate" }}
    </div>
  </div>
  <div class="mt-2 mb-2 pr-2 pl-2">
    <button mat-stroked-button routerLink="/my-profile">View All</button>
  </div>
</mat-menu>
