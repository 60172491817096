import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SimpleAlertComponent } from './simple-alert/simple-alert.component';

export type SimpleAlertOptions = {
  message: string;
  details?: string;
  okText?: string;
  cancelText?: string;
  defaultInput?: string;
};

export const SimpleAlertOptionsDefaults: SimpleAlertOptions = {
  message: '',
  okText: 'Ok',
  cancelText: 'Cancel'
};

@Injectable({
  providedIn: 'root'
})
export class SimpleUIService {
  modalRef!: BsModalRef;
  currentOptions: SimpleAlertOptions = { message: '' };

  constructor(private modalService: BsModalService) { }

  public async showSimpleAlert(args: any[]): Promise<boolean> {
    try {
      if (!args.length) {
        throw new Error('Missing arguments for alert');
      }

      if (args.length === 1 && typeof args[0] === 'string') {
        alert(args[0]);
        return true;
      }

      const options = args[0] as SimpleAlertOptions;
      if (!options || !options.message) {
        throw new Error('Invalid alert options: message is required');
      }

      this.currentOptions = { ...SimpleAlertOptionsDefaults, ...options };

      return new Promise((resolve) => {
        this.modalRef = this.modalService.show(SimpleAlertComponent, { ignoreBackdropClick: true, });
        (<SimpleAlertComponent>this.modalRef.content).options = this.currentOptions;
        (<SimpleAlertComponent>this.modalRef.content).exitModal = (ok?: string) => {
          this.modalRef.hide();
          this.modalRef = undefined!;
          resolve(ok !== undefined); // Changed this line to correctly resolve based on which button was clicked
        };
      });
    } catch (error: any) {
      console.error('Error showing alert:', error);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public async showTextPrompt(args: any[]): Promise<string | undefined> {
    try {
      if (!args.length) {
        throw new Error('Missing arguments for text prompt');
      }

      let options = args[0] as SimpleAlertOptions;
      if (!options || !options.message) {
        throw new Error('Invalid text prompt options: message is required');
      }

      options = { ...SimpleAlertOptionsDefaults, ...options };

      return new Promise((resolve) => {
        this.modalRef = this.modalService.show(SimpleAlertComponent, { ignoreBackdropClick: true, });
        (<SimpleAlertComponent>this.modalRef.content).options = options;
        (<SimpleAlertComponent>this.modalRef.content).promptForInput = true;
        (<SimpleAlertComponent>this.modalRef.content).exitModal = (ok?: string) => {
          resolve(ok);
          this.modalRef.hide();
          this.modalRef = undefined!;
        };
      });
    } catch (error: any) {
      console.error('Error showing text prompt:', error);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }
}
