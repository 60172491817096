import { GeneralHelpers } from '../../helpers/general.helper';
import { ExceutionHelperError } from '../../interfaces/errors.interface'
import { RuntimeResult } from '../../interfaces/runtime.interface';

export class PythonHelper {
  public static readonly renderSuccess = (value: any): RuntimeResult => {
    if (value === '' || value === undefined || value === null) {
      return {
        value: `empty result`,
        resultStatus: 'info',
      };
    }

    switch (GeneralHelpers.trueTypeOf(value)) {
      case 'array':
        value = GeneralHelpers.jsonStringify(value);
        break;
      case 'object':
        value = GeneralHelpers.jsonStringify(value);
        break;
      default:
        break;
    }

    value = GeneralHelpers.normalizeSingleQuotesInJson(value);

    return {
      value,
      resultStatus: 'success',
    };
  };

  public static readonly renderError = (error: ExceutionHelperError): RuntimeResult => {
    return {
      value: `${error.message}, ${error.name}, ${error.stack}`,
      resultStatus: 'error',
    };
  };

  /**
   * Calculates SHA-256 hash of file content
   * @param content File content as Uint8Array
   * @returns Hash as string
   */
  public static async calculateFileHash(
    content: Uint8Array | ArrayBuffer
  ): Promise<string> {
    const isArrayBuffer = content instanceof ArrayBuffer;
    const fileContent = isArrayBuffer ? content : content.buffer;

    try {
      // Use Web Crypto API for hashing
      const hashBuffer = await crypto.subtle.digest('SHA-256', fileContent);

      // Convert hash to hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, '0'))
        .join('');

      return hashHex;
    } catch (error) {
      console.error('Error calculating file hash:', error);
      // Fallback to length-based comparison if hashing fails
      return fileContent.byteLength.toString();
    }
  }

  // form pyodide python to JS types convertor
  public static pythonToJs(pyData: any, pythonRuntime: any): any {
    try {
      if (!pyData) {
        return pyData;
      }

      if (pyData.type === 'dict') {
        const jsObject: { [key: string]: any } = {};
        for (const key of pyData.keys()) {
          const value = pyData.get(key);
          if (value) {
            jsObject[key] = this.pythonToJs(value, pythonRuntime);
          }
        }
        return jsObject;
      } else if (pyData.type === 'list' || pyData.type === 'tuple') {
        const jsArray: any[] = [];
        for (const item of pyData) {
          if (item) {
            jsArray.push(this.pythonToJs(item, pythonRuntime));
          }
        }
        return jsArray;
      } else if (pyData.type === 'set') {
        const jsSet: Set<any> = new Set();
        for (const item of pyData) {
          if (item) {
            jsSet.add(this.pythonToJs(item, pythonRuntime));
          }
        }
        return jsSet;
      } else if (pyData.type === 'memoryview') {
        let data = pyData.toJs();
        if (data instanceof Uint8Array) {
          data = Array.from(data).join(',');
        }
        return data;
      } else if (
        pyData.type?.includes('ImageFile') ||
        pyData.type === 'DataFrame'
      ) {
        return pyData.toJs();
      } else if (
        pythonRuntime?.ffi?.PyProxy &&
        pyData instanceof (pythonRuntime?.ffi?.PyProxy ?? Object)
      ) {
        try {
          return pyData.toJs();
        } catch (error) {
          console.info(
            'Unknown converting type happens in PyProxy to JS:',
            error
          );
          return pyData;
        }
      } else {
        return pyData;
      }
    } catch (error) {
      console.info('Unknown converting type happens in PyProxy to JS:', error);
      return pyData;
    }
  }
}
