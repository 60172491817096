<mat-card>
  <mat-card-header>
    <mat-card-title>Arrow Alias Manager</mat-card-title>
    <button
      mat-icon-button
      mat-dialog-close
      class="small close"
      aria-label="Close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group preserveContent #tabGroup mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Cloud Aliases">
        <div class="row">
          <div class="col m-2" style="max-width: 300px">
            <div class="row">
              <div class="col">
                <button
                  mat-icon-button
                  matTooltip="Add Alias"
                  (click)="doAddAlias()"
                  tabindex="-1"
                >
                  <mat-icon>add_circle_outline</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="Duplicate"
                  [disabled]="selectedAlias === undefined"
                  (click)="doDuplicateAlias()"
                  tabindex="-1"
                >
                  <mat-icon>content_copy</mat-icon>
                </button>
                <button
                  mat-icon-button
                  matTooltip="Delete"
                  [disabled]="selectedAlias === undefined"
                  (click)="doDeleteAlias()"
                  tabindex="-1"
                >
                  <mat-icon>delete_outline</mat-icon>
                </button>
              </div>
            </div>
            <gui-grid
              #aliasGrid
              [virtualScroll]="true"
              [maxHeight]="500"
              [columns]="aliasListColumns"
              [source]="aliasList"
              [rowColoring]="0"
              [rowClass]="aliasListRowClass"
              (selectedRows)="onAliasSelected($event)"
            ></gui-grid>
          </div>

          <div class="col m-2">
            <form [formGroup]="aliasForm">
              <div class="row">
                <div class="col form-floating mb-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input
                      matInput
                      id="nameField"
                      placeholder="alias1"
                      formControlName="name"
                    />
                  </mat-form-field>
                </div>

                <div class="col form-floating mb-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Alias ID</mat-label>
                    <input
                      matInput
                      readonly
                      id="aliasId"
                      placeholder="1000"
                      formControlName="aliasId"
                      tabindex="-1"
                    />
                  </mat-form-field>
                </div>
              </div>
              <div formGroupName="cloudConfig">
                <div class="row">
                  <div class="col form-floating mb-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Bucket</mat-label>
                      <input
                        matInput
                        id="bucketField"
                        placeholder="bucket name"
                        formControlName="bucket"
                      />
                    </mat-form-field>
                  </div>

                  <div class="col form-floating mb-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Endpoint</mat-label>
                      <input
                        matInput
                        id="endpointField"
                        placeholder="https://nyc3.digitaloceanspaces.com/"
                        formControlName="endpoint"
                      />
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col form-floating mb-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Access key</mat-label>
                      <input
                        matInput
                        id="accessKeyField"
                        placeholder="AZ843"
                        formControlName="access_key"
                      />
                    </mat-form-field>
                  </div>

                  <div class="col form-floating mb-3">
                    <mat-form-field appearance="outline">
                      <mat-label>Region</mat-label>
                      <input
                        matInput
                        id="regionField"
                        placeholder="nyc3"
                        formControlName="region"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="row align-items-center justify-content-between">
                <div class="col form-floating mb-3">
                  <mat-form-field appearance="outline">
                    <mat-label>Cloud Secret</mat-label>
                    <mat-select formControlName="cloudConfigSecret" (selectionChange)="onSecretSelected($event)">
                      <mat-option value="">Select a secret</mat-option>
                      <mat-option *ngFor="let secret of availableSecrets" [value]="secret.secretId.toString()">
                        {{ secret.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col mb-3 text-end">
                  <button
                    mat-stroked-button
                    color="secondary"
                    [disabled]="disableButtons"
                    (click)="doResetForm()"
                  >
                    Reset
                  </button>
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="doSaveAlias()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="errorMessage !== undefined">
          <div class="col">
            <div class="alert alert-danger" role="alert">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="DB Aliases">
        <alias-database-list />
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
