<mat-card>
  <form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
    <mat-card-header>
      <mat-card-title>{{ data.title }}</mat-card-title>
      <button
        mat-icon-button
        [mat-dialog-close]="false"
        *ngIf="data.hasClose"
        class="small close"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-header>

    <mat-card-content>
      <p *ngIf="data.message" [innerHTML]="data.message"></p>

      <mat-form-field class="full-width">
        <mat-label>{{ data.inputLabel || "Input" }}</mat-label>
        <input
          matInput
          [type]="
            data.inputType === 'password' && showPassword ? 'password' : 'text'
          "
          [placeholder]="data.inputPlaceholder || ''"
          formControlName="inputValue"
        />
        <button
          mat-icon-button
          matSuffix
          type="button"
          *ngIf="data.inputType === 'password'"
          (click)="togglePasswordVisibility()"
        >
          <mat-icon>{{
            !showPassword ? "visibility" : "visibility_off"
          }}</mat-icon>
        </button>
        <mat-error *ngIf="inputForm.get('inputValue')?.invalid">
          <ng-container [ngSwitch]="true">
            <span
              *ngSwitchCase="inputForm.get('inputValue')?.errors?.['required']"
            >
              This field is required
            </span>
            <span
              *ngSwitchCase="inputForm.get('inputValue')?.errors?.['email']"
            >
              Please enter a valid email address
            </span>
            <span
              *ngSwitchCase="inputForm.get('inputValue')?.errors?.['pattern']"
            >
              Invalid format
            </span>
            <span
              *ngSwitchCase="inputForm.get('inputValue')?.errors?.['minlength']"
            >
              Minimum length is {{ data.inputMinLength }}
            </span>
            <span
              *ngSwitchCase="inputForm.get('inputValue')?.errors?.['maxlength']"
            >
              Maximum length is {{ data.inputMaxLength }}
            </span>
          </ng-container>
        </mat-error>
      </mat-form-field>
    </mat-card-content>

    <mat-card-actions [align]="'end'">
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        [disabled]="!inputForm.valid"
        *ngIf="data?.confirmCaption"
        class="mr-2"
      >
        {{ data.confirmCaption }}
      </button>
      <button
        mat-stroked-button
        color="warn"
        type="button"
        [mat-dialog-close]="false"
        *ngIf="data?.cancelCaption"
      >
        {{ data.cancelCaption }}
      </button>
    </mat-card-actions>
  </form>
</mat-card>
