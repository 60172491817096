import { Component, OnDestroy, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  of,
} from 'rxjs';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, ActivatedRoute } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import {
  SearchResponse,
  SearchProjectResult,
  SearchChunkResult,
  SearchNotebookResult,
  SearchMethod,
  VersionsFilter,
} from '../../services/search/models/search.types';

type SearchResultItem =
  | (SearchProjectResult & { resultType: 'project' })
  | (SearchNotebookResult & { resultType: 'notebook' })
  | (SearchChunkResult & { resultType: 'chunk' });

@AutoUnsubscribe()
@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDividerModule,
    MatTooltipModule,
  ],
})
export class HeaderSearchComponent implements OnDestroy, OnInit {
  #appState = inject(AppStateService);
  searchForm = new FormGroup({
    query: new FormControl(''),
    method: new FormControl<SearchMethod>('like'),
    versions: new FormControl<VersionsFilter>('latest'),
    projectSearch: new FormControl(true),
    chunkSearch: new FormControl(true),
  });
  
  filteredResults: Observable<SearchResultItem[]>;
  isLoading = false;
  
  searchMethods: { value: SearchMethod; label: string; description: string }[] = [
    { 
      value: 'like', 
      label: 'Simple Search',
      description: 'Basic text matching, searches for exact phrases'
    },
    { 
      value: 'fulltext', 
      label: 'Full Text',
      description: 'Advanced text search with word stemming and relevance ranking'
    },
    { 
      value: 'regex', 
      label: 'Regular Expression',
      description: 'Search using regular expressions for pattern matching'
    },
  ];
  
  versionFilters: { value: VersionsFilter; label: string; description: string }[] = [
    { 
      value: 'latest', 
      label: 'Latest',
      description: 'Search only in the latest published versions'
    },
    { 
      value: 'head', 
      label: 'Head',
      description: 'Search in the most recent versions, including unpublished changes'
    },
    { 
      value: 'all', 
      label: 'All',
      description: 'Search across all versions, including historical ones'
    },
  ];

  private currentProjectId?: number;
  private currentNotebookId?: number;

  constructor(
    private searchService: SearchService, 
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.filteredResults = this.searchForm.get('query')!.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((query) => this.searchAll(query || '')),
      map((results) => this.processSearchResults(results))
    );
  }

  ngOnInit() {
    // Extract project and notebook IDs from the current route
    this.route.params.subscribe(params => {
      this.currentProjectId = params['projectId'] ? Number(params['projectId']) : undefined;
      this.currentNotebookId = params['notebookId'] ? Number(params['notebookId']) : undefined;
    });
  }

  /**
   * Lifecycle hook handled by @AutoUnsubscribe decorator
   * @ignore
   */
  // eslint-disable-next-line
  ngOnDestroy(): void {
    // Cleanup handled by @AutoUnsubscribe decorator
  }

  private searchAll(query: string): Observable<SearchResponse> {
    if (!query || query.length < 2) {
      return of({ projects: [], notebooks: [], chunks: [] });
    }

    this.isLoading = true;
    const formValue = this.searchForm.value;

    return this.searchService
      .search({
        query,
        method: formValue.method || 'like',
        versions: formValue.versions || 'latest',
        project_search: formValue.projectSearch ?? true,
        chunk_search: formValue.chunkSearch ?? true,
        project_id: this.currentProjectId,
        notebook_id: this.currentNotebookId,
        limit: 5,
      })
      .pipe(
        map((response) => {
          this.isLoading = false;
          return response;
        })
      );
  }

  private processSearchResults(response: SearchResponse): SearchResultItem[] {
    const results: SearchResultItem[] = [
      ...response.projects.map((project) => ({
        ...project,
        resultType: 'project' as const,
      })),
      ...response.notebooks.map((notebook) => ({
        ...notebook,
        resultType: 'notebook' as const,
      })),
      ...response.chunks.map((chunk) => ({
        ...chunk,
        resultType: 'chunk' as const,
      })),
    ];

    return results;
  }

  displayResult = (result: SearchResultItem | null): string => {
    if (!result) return '';

    switch (result.resultType) {
      case 'project':
        return result.name;
      case 'notebook':
        return `${result.notebookName} (${result.projectName})`;
      case 'chunk':
        return `${result.chunkName} - ${result.notebookName}`;
    }
  }

  handleSelection(event: MatAutocompleteSelectedEvent) {
    const result = event.option.value as SearchResultItem;

    switch (result.resultType) {
      case 'project':
        this.#appState.navigateToProject(result.projectId);
        break;
      case 'notebook':
      case 'chunk':
        // Assuming navigateToNotebook now accepts a single parameter
        this.#appState.navigateToProject(result.projectId);
        break;
    }
    this.searchForm.get('query')?.setValue('');
  }
}
