<form class="search-form" [formGroup]="searchForm">
  <div class="main-search-container">
    <mat-form-field class="search-input" appearance="outline">
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        matInput
        placeholder="Search..."
        [matAutocomplete]="auto"
        formControlName="query"
      />
      <mat-spinner
        *ngIf="isLoading"
        matSuffix
        diameter="20"
      ></mat-spinner>
    </mat-form-field>

    <button
      mat-icon-button
      class="settings-button"
      [matMenuTriggerFor]="searchMenu"
      matTooltip="Search settings"
    >
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</form>

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="handleSelection($event)"
  [displayWith]="displayResult"
>
  <mat-option
    *ngFor="let result of filteredResults | async"
    [value]="result"
    [class]="'result-type-' + result.resultType"
  >
    <mat-icon>
      {{
        result.resultType === 'project'
          ? 'folder'
          : result.resultType === 'notebook'
          ? 'book'
          : 'code'
      }}
    </mat-icon>
    <span class="result-text">{{ displayResult(result) }}</span>
  </mat-option>
</mat-autocomplete>

<mat-menu #searchMenu="matMenu" class="search-settings-menu">
  <div class="menu-content" [formGroup]="searchForm">
    <div class="menu-section">
      <h4 class="menu-title">Search Method</h4>
      <div class="search-options-group">
        <mat-radio-group formControlName="method">
          <mat-radio-button
            *ngFor="let method of searchMethods"
            [value]="method.value"
            [matTooltip]="method.description"
          >
            {{ method.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="menu-section">
      <h4 class="menu-title">Version Filter</h4>
      <div class="search-options-group">
        <mat-radio-group formControlName="versions">
          <mat-radio-button
            *ngFor="let filter of versionFilters"
            [value]="filter.value"
            [matTooltip]="filter.description"
          >
            {{ filter.label }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="menu-section">
      <h4 class="menu-title">Search Scope</h4>
      <div class="checkbox-group">
        <mat-checkbox formControlName="projectSearch">
          Search in projects
        </mat-checkbox>
        <mat-checkbox formControlName="chunkSearch">
          Search in notebooks
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-menu>
