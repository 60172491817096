import { GeneralHelpers } from '../../helpers/general.helper';
import { RuntimeResult } from '../../interfaces/runtime.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';

import { FILE_EXTENSIONS, LOG_SEVERITY } from '../../constants/general.constants';
import { FileModule } from '../../interfaces/file.interface';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';

export class PythonHelper {
  public static renderSuccess = (value: any): RuntimeResult => {
    if (value === '' || value === undefined || value === null) {
      return {
        value: `empty result`,
        resultStatus: 'info',
      };
    }

    const surroundSingleQuotesMatcher = new RegExp(
      `${"('(?=(,s*')))|('(?=:))|((?<=([:,]s*))')|((?<=[{[,])')|('(?=[]},]))"}`,
      'g'
    );

    switch (GeneralHelpers.trueTypeOf(value)) {
      case 'array':
        value = GeneralHelpers.jsonStringify(value);
        break;
      case 'object':
        value = GeneralHelpers.jsonStringify(value);
        break;
      default:
        break;
    }

    return {
      value: value.replace(surroundSingleQuotesMatcher, `"`),
      resultStatus: 'success',
    };
  };

  public static renderError = (error: any): RuntimeResult => {
    return {
      value: `${error}`,
      resultStatus: 'error',
    };
  };

  /**
   * Calculates SHA-256 hash of file content
   * @param content File content as Uint8Array
   * @returns Hash as string
   */
  public static async calculateFileHash(content: Uint8Array | ArrayBuffer): Promise<string> {
    const isArrayBuffer = content instanceof ArrayBuffer;
    const fileContent = isArrayBuffer ? content : content.buffer;

    try {
      // Use Web Crypto API for hashing
      const hashBuffer = await crypto.subtle.digest(
        'SHA-256',
        fileContent
      );

      // Convert hash to hex string
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');

      return hashHex;
    } catch (error) {
      console.error('Error calculating file hash:', error);
      // Fallback to length-based comparison if hashing fails
      return fileContent.byteLength.toString();
    }
  }

  // form pyodide python to JS types convertor
  public static pythonToJs(pyData: any, pythonRuntime: any): any {
    try {
      if (pyData?.type === 'dict') {
        const jsObject: { [key: string]: any } = {};

        for (const key of pyData.keys()) {
          jsObject[key] = this.pythonToJs(pyData.get(key), pythonRuntime);
        }

        return jsObject;
      } else if (pyData?.type === 'list' || pyData?.type === 'tuple') {
        const jsArray: any[] = [];

        for (const item of pyData) {
          jsArray.push(this.pythonToJs(item, pythonRuntime));
        }

        return jsArray;
      } else if (pyData?.type === 'set') {
        const jsSet: Set<any> = new Set();

        for (const item of pyData) {
          jsSet.add(this.pythonToJs(item, pythonRuntime));
        }

        return jsSet;
      } else if (pyData?.type === 'memoryview') {
        let data = pyData.toJs();

        if (data instanceof Uint8Array) {
          data = Array.from(data).join(',');
        }

        return data;
      } else if (
        pyData?.type?.includes('ImageFile')
        || pyData?.type === 'DataFrame'
      ) {
        // TODO: found related data and fix issues with it
        // ask user to use uint8array instead of direct ImageFile
        return pyData.toJs();
      } else if (pyData instanceof pythonRuntime.ffi.PyProxy) {
        return pyData.toJs();
      } else {
        return pyData;
      }
    } catch (error) {
      console.warn('Unknown converting Python data to JS:', error);
      console.warn('pyData:', pyData?.type, pyData);
      return pyData;
    }
  }
}
