import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, from, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../services/authentication/authentication.service';

export const DynamicAuthGuard: CanActivateFn = (
  route,
  state
): Observable<boolean> => {
  const router = inject(Router);
  const authService = inject(AuthenticationService);

  if (environment.authProvider === 'auth0' || environment.authProvider === 'zitadel') {
    return from(authService.isAuthenticated()).pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        } else {
          authService.authenticate(state.url);
          return false;
        }
      })
    );
  } else {
    console.error('Invalid authProvider specified');
    router.navigate(['/login']);
    return new Observable<boolean>((observer) => observer.next(false));
  }
};
