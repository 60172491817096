import { Environment } from '../app/shared/interfaces/environment.interface';

export const environment: Environment = {
  production: false,
  wsApiUrl: 'wss://dev.enlightenhq.com:443/api/',
  apiUrl: 'api/',
  origin: window.location.origin,
  authProvider: 'zitadel', // Set to use Zitadel by default, can be 'auth0' or 'zitadel'
  adminWsUrl: 'ws://localhost:7777/api/admin',
  sam2WebsocketUrl: '', // New property for SAM2 WebSocket URL
  auth: {
    auth0: {
      domain: 'notecalc.us.auth0.com',
      clientId: 'U4rNVo8rYOLzdMOHDpLRxppXzghbpTD9',
      redirectUri: window.location.origin,
      audience: 'https://notecalc.us.auth0.com/api/v2/',
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    },
    zitadel: {
      allowedUrls: [
        'https://localhost:4222/api',
        'ws://localhost:7777/api/admin',
      ],
      projectId: '283197046582674408',
      issuer: 'https://auth.enlightenhq.com',
      clientId: '284771137014007213',
      redirectUri: window.location.origin + '/login',
      postLogoutRedirectUri: window.location.origin + '/logout',
      requireHttps: true,
      showDebugInformation: true,
    },
  },
  pyodideVersion: 'v0.27.0a2',
  newtonUrl: 'https://enlighten-newton-dev.nyc3.digitaloceanspaces.com',
};
