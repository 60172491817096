import { inject, Injectable } from '@angular/core';

import {
  API_BASE,
  BOOK,
  CHUNKS,
  ORDER,
  DEFAULT_CHUNK_ID,
  PROJECT,
  VERSION,
  INVALIDATE_CHUNKS,
} from '../../constants/general.constants';

import { EMPTY, map, Observable, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import {
  Chunk,
  CreateChunk,
  SaveChunkContent,
  UpdateChunk,
} from '../../interfaces/chunk/chunk.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../message/message.service';
import { AppStateService } from '../app-state/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class ChunkService {
  readonly #appState = inject(AppStateService);

  selectedChunkId!: number;

  allCollapsed: boolean = false;

  chunkList: Chunk[] = [];

  // ─────────────────────────────────────────────────────────────────────
  // Message subscription
  private readonly messageSubscription!: Subscription;

  constructor(
    private readonly http: HttpClient,
    private readonly toastr: ToastrService,
    private readonly messageService: MessageService
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === INVALIDATE_CHUNKS && message.data) {
          this.invalidateChunksById(message.data);
        }
      });
  }

  // ─────────────────────────────────────────────────────────────────────
  // Create chunk

  public create(
    data: Chunk,
    notebookId: string | null,
    sync: boolean = true
  ): Observable<object> {
    if (notebookId === null) {
      return EMPTY;
    }
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http
      .post<Chunk>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}`,
        data
      )
      .pipe(
        map((res: any) => {
          if (res === null) {
            return;
          }

          if (sync) {
            const chunkPosition = this.chunkList.findIndex(
              ({ chunkId }) => chunkId === DEFAULT_CHUNK_ID
            );

            if (chunkPosition > -1) {
              this.chunkList.splice(chunkPosition, 1, res);
            } else if (notebookId === `${res.notebookId}`) {
              this.chunkList.push(res);
            }
          }

          return res;
        })
      );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get Chunks

  public getById(notebookId: string, chunkId: string): Observable<Chunk> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http.get<Chunk>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}/${chunkId}`
    );
  }

  public get(notebookId: string | number): Observable<Chunk> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http
      .get<Chunk>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}`
      )
      .pipe(
        map((res: any) => {
          if (res === null) {
            return;
          }
          // Process chunks to add default properties
          const processedChunks = res.map((chunk: Chunk) => ({
            ...chunk,
            contentVisible: true, // default to true
            isCollapsed: false, // default to false
          }));

          this.chunkList = processedChunks;
          return processedChunks;
        })
      );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update chunk

  public update(
    data: UpdateChunk,
    notebookId: string | number | null
  ): Observable<Partial<Chunk & CreateChunk>> {
    if (notebookId === null) {
      return EMPTY;
    }
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();

    return this.http.put<Partial<Chunk & CreateChunk>>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}`,
      data
    );
  }

  public saveContent(
    data: SaveChunkContent,
    notebookId: string | null
  ): Observable<Chunk> {
    if (notebookId === null) {
      return EMPTY;
    }
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http.put<Chunk>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}`,
      data
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete chunk

  public delete(
    notebookId: string | null,
    chunkId: string | number
  ): Observable<object> {
    if (notebookId === null) {
      return EMPTY;
    }

    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();

    return this.http
      .delete<Chunk>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}/${chunkId}`
      )
      .pipe(
        map((res: any) => {
          if (res === null) {
            return;
          }

          const chunkPosition = this.chunkList.findIndex(
            ({ chunkId }) => chunkId === res.chunkId
          );

          if (chunkPosition > -1) {
            this.chunkList.splice(chunkPosition, 1);
          }

          return res;
        })
      );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Change order

  public reorder(data: number[], notebookId: string | number): Observable<object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http.put<Chunk>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CHUNKS}/${ORDER}`,
      data
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get selected chunk data by id
  public getSelectedChunkFromList(chunkList: Chunk[] = this.chunkList) {
    if (this.selectedChunkId === undefined) {
      this.toastr.warning('Please select chunk first');
      return;
    }
    let selectedChunk: Chunk | null = null;
    for (const element of chunkList) {
      if (element.chunkId === this.selectedChunkId) {
        selectedChunk = element;
        break;
      }
    }
    return selectedChunk;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update chunk by in for chunk list
  public updateChunkListById(chunk: Chunk) {
    const index = GeneralHelpers.findIndexInArrayByProp(
      this.chunkList,
      'chunkId',
      chunk.chunkId
    );

    if (index > -1) {
      for (const key in this.chunkList[index]) {
        if (Object.prototype.hasOwnProperty.call(this.chunkList[index], key) && key in chunk) {
          (this.chunkList as any)[index][key] = (chunk as any)[key];
        }
      }
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update chunk by in for chunk list
  public invalidateChunksFrom(processedChunk: Chunk) {
    const processedChunkIndex = GeneralHelpers.findIndexInArrayByProp(
      this.chunkList,
      'chunkId',
      processedChunk.chunkId
    );
    for (let index = 0; index < this.chunkList.length; index++) {
      const currentChunk: Chunk = this.chunkList[index];
      if (index > processedChunkIndex) {
        this.chunkList[index].invalidated = this.shouldInvalidate(
          processedChunk,
          currentChunk
        );
      }
      if (index === processedChunkIndex) {
        this.chunkList[index].invalidated = false;
      }
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get chunk by name from chunkList

  public getChunkByName(chunkName: string) {
    let chunk: Chunk | null = null;
    for (const element of this.chunkList) {
      if (element.name === chunkName) {
        chunk = element;
        break;
      }
    }
    return chunk;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Private methods
  private shouldInvalidate(
    processedChunk: Chunk,
    currentChunk: Chunk
  ): boolean {
    const sameType = processedChunk.chunktypeId === currentChunk.chunktypeId;
    const hasContent =
      processedChunk.content !== '' && currentChunk.content !== '';
    let hasSameVariables = false;
    const globalVarsProcessed: boolean | string[] | any =
      GeneralHelpers.detectGlobalVariable(processedChunk.content);
    const globalVarsCurrent: boolean | string[] | any =
      GeneralHelpers.detectGlobalVariable(currentChunk.content);
    if (globalVarsProcessed?.length > 0 && globalVarsCurrent?.length > 0) {
      const intersectedVariables = GeneralHelpers.getArrayIntersection(
        globalVarsProcessed,
        globalVarsCurrent
      );
      if (intersectedVariables?.length > 0) {
        hasSameVariables = true;
      }
    }
    return sameType && hasContent && hasSameVariables;
  }

  private invalidateChunksById(chunkIds: string[]) {
    if (chunkIds?.length > 0) {
      for (const chunkId of chunkIds) {
        const processedChunkIndex = GeneralHelpers.findIndexInArrayByProp(
          this.chunkList,
          'chunkId',
          chunkId
        );
        this.chunkList[processedChunkIndex].invalidated = true;
      }
    }
  }
}
