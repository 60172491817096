import { Injectable } from '@angular/core';
import { PythonHelper } from './python.helper';

interface RuntimeOutput {
  output: string;
  namespace: any;
}

@Injectable({
  providedIn: 'root',
})
export class PythonRuntimeService {
  private pythonRuntime: any;
  private jsExternalModulesNamespaces: any;

  constructor() { }

  async executeCode(
    code: string,
    namespace?: any
  ): Promise<RuntimeOutput> {
    try {
      const executionString = `runtime_manager.execute_code_in_context("""${code}""")`;

      // Execute the code without additional indentation
      let result = await this.pythonRuntime.runPythonAsync(executionString);
      result = PythonHelper.pythonToJs(result, this.getPythonRuntime);

      return {
        output: result.output || '',
        namespace: result.namespace || {}
      };
    } catch (error) {
      console.error('Error executing code:', error);
      throw error;
    }
  }

  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    this.pythonRuntime = runtime;
    this.jsExternalModulesNamespaces = jsExternalModulesNamespaces;

    await this.setupRuntime();
  }

  private async setupRuntime() {
    const runtimeSetup = `
      import sys
      import io
      import traceback
      from types import ModuleType

      class OutputCapture:
          def __init__(self):
              self.value = io.StringIO()
              self.stdout = sys.stdout
              self.stderr = sys.stderr

          def __enter__(self):
              sys.stdout = self.value
              sys.stderr = self.value
              return self.value

          def __exit__(self, exc_type, exc_value, traceback):
              sys.stdout = self.stdout
              sys.stderr = self.stderr

      class RuntimeManager:
          @staticmethod
          def execute_code_in_context(code, global_namespace=None):
              """
              Executes code with proper output capturing and namespace management
              Returns both the output and the namespace
              """
              global_namespace = {
                  '__name__': '__name__',
                  '__builtins__': __builtins__,
                  'data': globals()['data']
              }

              # Ensure js external modules is available in the namespace if it exists in globals
              # js_external_modules_namespaces = ${JSON.stringify(this.jsExternalModulesNamespaces)}

              # for module in js_external_modules_namespaces:
              #    if module in globals():
              #        global_namespace[module] = globals()[module]

              result = {
                  'output': '',
                  'namespace': global_namespace
              }

              try:
                  with OutputCapture() as output:
                      # Execute the code
                      exec(code, global_namespace)
                      # Get the output
                      result['output'] = output.getvalue() or "Code execution complete"

              except Exception as e:
                  # Capture the full traceback
                  with OutputCapture() as error_output:
                      traceback.print_exc()
                      result['output'] = error_output.getvalue()
                  raise Exception(result['output'])

              return result

      runtime_manager = RuntimeManager()
    `;

    try {
      await this.pythonRuntime.runPythonAsync(runtimeSetup);
    } catch (error) {
      console.error('Error setting up runtime:', error);
      throw error;
    }
  }

  getPythonRuntime() {
    return this.pythonRuntime;
  }
}
