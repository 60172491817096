import { signal } from '@angular/core'
import { filesize } from 'filesize';

export type CloudFile = {
  name: string;
  last_modified: string;
  size: number;
  e_tag: string;
};

export type CloudResponse = {
  next_marker: string | null;
  objects: CloudFile[];
  prefixes: string[];
}

export class CloudNode {
  readonly name: string;
  #children: CloudNode[];
  readonly fileData?: CloudFile;
  readonly nextMarker = signal<string | null>(null);

  constructor(name: string, children?: CloudNode[], fileData?: CloudFile) {
    this.name = name;
    this.#children = children ?? [];
    this.fileData = fileData;
  }

  static fromCloudFile(file: CloudFile): CloudNode {
    return new CloudNode(file.name, [], file);
  }

  get children(): CloudNode[] {
    return this.#children
  }

  get filesize() {
    return this.fileData ? filesize(this.fileData.size) : '-';
  }

  loadFromResponse(response: CloudResponse): void {
    // if (this.nextMarker() === null) {
    //   // don't have prefixes
      const prefixes = response.prefixes.map((prefix) => new CloudNode(prefix));
      this.#children.push(...prefixes);
    // }
    this.#children.push(...response.objects.map(CloudNode.fromCloudFile));
    if (response.next_marker) {
      this.nextMarker.set(response.next_marker);
      console.log('set next marker to', response.next_marker);
    } else {
      this.nextMarker.set(null);
    }
  }

  get isFile(): boolean {
    return !!this.fileData;
  }
}
