import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE, USERS } from '../../constants/general.constants';
import { User } from '../../interfaces/user.interface';
import { ProjectInviteResponse } from '../../interfaces/project-invite.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly usersEndpoint = `${API_BASE}${USERS}`;

  constructor(private http: HttpClient) {}

  // ────────────────────────────────────────────────────────────────────────────────
  // Get User Data

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.usersEndpoint);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get User Data
  getCurrent(): Observable<User> {
    return this.http.get<User>(`${this.usersEndpoint}/current`);
  }

  //--------------------------------------------------------------------------------
  // Update User Data
  updateCurrentUser(user: User): Observable<User> {
    return this.http.put<User>(`${this.usersEndpoint}/current`, user);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get Open Invites

  getOpenInvites(): Observable<ProjectInviteResponse[]> {
    const openInvitesEndpoint = `${this.usersEndpoint}/current/invites`;
    return this.http.get<ProjectInviteResponse[]>(openInvitesEndpoint);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Respond to Invite

  respondToInvite(
    inviteId: number,
    response: string
  ): Observable<ProjectInviteResponse> {
    const respondToInviteEndpoint = `${this.usersEndpoint}/current/invites`;
    const requestBody = {
      inviteId: Number(inviteId),
      response: response,
    };

    return this.http.put<ProjectInviteResponse>(respondToInviteEndpoint, requestBody);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get All Invites

  getAllInvites(): Observable<ProjectInviteResponse[]> {
    const allInvitesEndpoint = `${this.usersEndpoint}/current/invites/all`;
    return this.http.get<ProjectInviteResponse[]>(allInvitesEndpoint);
  }

  // Search for users by email
  searchUsersByEmail(email: string): Observable<User[]> {
    return this.getUsers().pipe(
      map(users => users.filter(user => 
        user.email && user.email.toLowerCase() === email.toLowerCase()
      ))
    );
  }
}
