import { Injectable, OnDestroy } from '@angular/core';

import { NotebookVariablesService } from '../notebook-variables/notebook-variables.service';

import { MessageService } from '../message/message.service';
import { Subscription } from 'rxjs';
import {
  ChunkContext,
  ExecutionContext,
} from '../../interfaces/chunk/chunk-context.interface';
import { FileService } from '../file/file.service';
import { RELOAD_JS_RUNTIME } from '../../constants/general.constants';
import { JsRunner, JavascriptRunnerService } from './javascript-runner.service';
import { JavaScriptHelper } from './javascript.helper';

// Services for custom methods
import { RenderService } from '../../services/render/render.service';
import { CanvasService } from '../canvas/canvas.service';
import { WasmStoreService } from '../wasm/store/wasm-store.service';
import { ArrowWebsocketService } from '../arrow-websocket/arrow-websocket.service';
import { CacheService } from '../cache/cache.service';
import { LocalforageService } from '../localforage/localforage.service';
import { FileUnifiedService } from '../file-unified/file-unified.service';
import { DuckDbService } from '../duck-db/duck-db.service';
import { ConvertService } from '../convert/convert.service';
import { WasmSearchService } from '../wasm/search/wasm-search.service';
import { WasmViewService } from '../wasm/viewer/wasm-viewer.service';
import { SimpleUIService } from '../simple-ui/simple-ui.service';

import {
  CUSTOM_METHODS_EXPORT,
  CUSTOM_METHODS_IMPORT,
} from '../../constants/additional-methods.constants';
import { GeneralHelpers } from '../../helpers/general.helper';
import { ProjectVariablesService } from '../project-variables/project-variables.service';
import { Sam2WebsocketService } from '../sam2-websocket/sam2-websocket.service';
import { NotebookDefaultFilesService } from '../notebook-default-file/notebook-default-file.service';

@Injectable({
  providedIn: 'root',
})
export class JavascriptService implements OnDestroy {
  private isLoaded: boolean = false;
  private messageShown: boolean = false;
  private readonly messageSubscription!: Subscription;

  constructor(
    private readonly localforageService: LocalforageService,
    // Services for custom methods
    private readonly wasmStoreService: WasmStoreService,
    private readonly wasmSearchService: WasmSearchService,
    private readonly wasmViewService: WasmViewService,
    private readonly renderService: RenderService,
    private readonly fileUnifiedService: FileUnifiedService,
    private readonly canvasService: CanvasService,
    private readonly arrowWebsocketService: ArrowWebsocketService,
    private readonly cacheService: CacheService,
    private readonly duckDbService: DuckDbService,
    private readonly simpleUIService: SimpleUIService,
    private readonly convertService: ConvertService,
    private readonly sam2WebsocketService: Sam2WebsocketService,
    private readonly notebookDefaultFilesService: NotebookDefaultFilesService,
    private readonly notebookVariablesService: NotebookVariablesService,
    private readonly messageService: MessageService,
    private readonly fileService: FileService,
    private readonly jsRunnerService: JavascriptRunnerService,
    private readonly projectVariablesService: ProjectVariablesService,
  ) {
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_JS_RUNTIME) {
          this.jsRunnerService.disposeRuntime();
          this.startRuntime();
        }
      });
  }

 // eslint-disable-next-line
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  public async init() {
    await this.jsRunnerService.init();
    this.isLoaded = true;
  }

  private async startRuntime() {
    await this.jsRunnerService.startRuntime();
    this.isLoaded = true;
  }

  public createRunner(chunkContext: ChunkContext): JsRunner {
    const runner = this.jsRunnerService.createRunner(chunkContext.getChunk());
    JavaScriptHelper.addConsoleLog(runner.context, chunkContext);
    this.processMethodsWithGlobalNames(runner.context, chunkContext);
    JavaScriptHelper.addFetchToContext(runner.context);
    JavaScriptHelper.addPrintToContext(runner.context, chunkContext);

    return runner;
  }

  public disposeRunner(runner: JsRunner) {
    this.jsRunnerService.disposeRunner(runner);
  }

  public async runScript(
    runner: JsRunner,
    script: string,
    context: ExecutionContext
  ) {
    if (!this.isLoaded) {
      if (!this.messageShown) {
        context.addMessage('JS runtime still loading', 'info');
        this.messageShown = true;
      }
      throw new Error('JS runtime is still loading');
    }

    try {
      JavaScriptHelper.addGlobalData(
        runner.context,
        runner.chunk,
        this.notebookVariablesService
      );

      return await this.jsRunnerService.runScript(runner, script);
    } catch (error: any) {
      if (error.quickJSError) {
        context.addOutput(
          runner.context.dump(error.quickJSError),
          'error',
          'javascript'
        );
      }

      context.addMessage('Script execution failed: ' + error.message, 'danger');

      throw error;
    }
  }

  public async run(chunkContext: ChunkContext) {
    chunkContext.setBusy(true, 'executing');
    chunkContext.clearMessages();
    chunkContext.clearLogs();
    let content = this.getContentWithVariablesReplaced(
      chunkContext.getChunkContent()
    );
    const chunkData = chunkContext.getChunk();

    if (typeof content !== 'string' || !!content === false) {
      return;
    }

    if (this.isLoaded === false) {
      if (!this.messageShown) {
        chunkContext.addMessage('JS runtime still loading', 'info');
        this.messageShown = true;
      }

      return;
    }

    let jsContext = this.jsRunnerService.createContext();

    jsContext = JavaScriptHelper.addGlobalData(
      jsContext,
      chunkData,
      this.notebookVariablesService
    );
    jsContext = JavaScriptHelper.addConsoleLog(jsContext, chunkContext);
    jsContext = this.processMethodsWithGlobalNames(jsContext, chunkContext);
    jsContext = JavaScriptHelper.addFetchToContext(jsContext);
    jsContext = JavaScriptHelper.addPrintToContext(jsContext, chunkContext);

    content = await JavaScriptHelper.processGlobalModules(
      content,
      this.fileService
    );

    if (!jsContext.alive) {
      throw new Error('Context is not alive');
    }

    try {
      const code = await jsContext.evalCodeAsync(content);
      if (code.error) {
        chunkContext.addOutput(jsContext.dump(code.error), 'error', 'javascript');
        code.error.dispose();
      } else {
        chunkContext.addOutput(jsContext.dump(code.value), 'success', 'javascript');
        jsContext.unwrapResult(code).dispose();
        this.notebookVariablesService.addVarsFromRawToList(
          jsContext.getProp(jsContext.global, 'data').consume(jsContext.dump),
          chunkData
        );
      }
    } catch (error: any) {
      throw new Error(error.message);
    }

    jsContext.dispose();
    chunkContext.setBusy(false, 'done');
    return chunkContext.getChunk();
  }

  /**
   * Prepare a runner for debugging. disposeRunner must be called after debugging is done
   * @param chunkContext The chunk/execution context to run the code in
   * @returns The JsRunner to debug the code
   */
  public startDebugger(chunkContext: ChunkContext): JsRunner {
    const runner = this.createRunner(chunkContext);
    JavaScriptHelper.addGlobalData(
      runner.context,
      chunkContext.getChunk(),
      this.notebookVariablesService
    );
    return runner;
  }

  /**
   * Execute JavaScript code in the debugger
   * @param runner The runner that startDebugger returned
   * @param content The JavaScript code to execute
   * @param execContext The chunk/execution context to run the code in
   * @param prepContent Should the content be prepped (handle file services, replace variables) before execution
   */
  public async executeInDebugger(runner: JsRunner, content: string, execContext: ExecutionContext, prepContent: boolean = false) {
    if (prepContent) {
      content = this.getContentWithVariablesReplaced(content);
      content = await JavaScriptHelper.processGlobalModules(
        content,
        this.fileService
      );
    }
    try {
      const code = await runner.context.evalCodeAsync(content);
      if (code.error) {
        execContext.addOutput(runner.context.dump(code.error), 'error', 'javascript');
        code.error.dispose();
      } else {
        execContext.addOutput(runner.context.dump(code.value), 'success', 'javascript');
        runner.context.unwrapResult(code).dispose();
        this.notebookVariablesService.addVarsFromRawToList(
          runner.context.getProp(runner.context.global, 'data').consume(runner.context.dump),
          runner.chunk
        );
      }
    }
    catch (error: any) {
      throw new Error(error.message);
    }
  }

  private processMethodsWithGlobalNames(
    jsContext: any,
    chunkContext: ChunkContext
  ) {
    const allMethods = CUSTOM_METHODS_EXPORT.concat(CUSTOM_METHODS_IMPORT);
    const globalNames = GeneralHelpers.getUniqueGlobalNames(allMethods);

    if (Array.isArray(globalNames) && globalNames.length > 0) {
      for (const element of globalNames) {
        const globalName = element;
        const exportMethods = CUSTOM_METHODS_EXPORT.filter(
          (method: any) => method.globalName === globalName
        );
        const importMethods = CUSTOM_METHODS_IMPORT.filter(
          (method: any) => method.globalName === globalName
        );
        this.processCustomMethods(
          jsContext,
          importMethods,
          exportMethods,
          globalName,
          chunkContext
        );
      }
    }

    return jsContext;
  }

  private processCustomMethods(
    context: any,
    importMethods: any[],
    exportMethods: any[],
    globalName: string,
    chunkContext: ChunkContext
  ) {
    const mainHandler = context.newObject();

    // Export methods
    // Methods will be called from JS
    for (const element of exportMethods) {
      const methodHandler = context.newFunction(
        element.method,
        (...args: any) => {
          const calledServiceContext = (this as any)[element.serviceToUse];

          calledServiceContext[element.methodToCall](
            args.map(context.dump),
            chunkContext,
            'javascript'
          );
        }
      );

      context.setProp(mainHandler, element.method, methodHandler);

      methodHandler.dispose();
    }
    // end

    // Import methods
    // Methods will be called from C
    for (const element of importMethods) {
      const methodHandle = context.newAsyncifiedFunction(
        element.method,
        async (...args: any) => {
          const argsProcessed: any[] = [];

          if (args?.length > 0) {
            args.forEach((arg: any) => argsProcessed.push(context.dump(arg)));
          }

          const calledServiceContext = (this as any)[element.serviceToUse];
          const data = await calledServiceContext[element.methodToCall](argsProcessed,
            chunkContext
          );

          return JavaScriptHelper.createContextEntity(context, data);
        }
      );
      methodHandle.consume((fn: any) =>
        context.setProp(mainHandler, element.method, fn)
      );
    }
    // end

    context.setProp(context.global, globalName, mainHandler);

    mainHandler.dispose();

    return context;
  }

  private getContentWithVariablesReplaced(content: string) {
    const chunkContent = GeneralHelpers.replaceGlobals(
      content,
      this.projectVariablesService.variableList
    );
    return chunkContent;
  }

  private async preloadModule(moduleName: string) {
    const module = await this.localforageService.getItem(moduleName);
    if (!module) {
      const scriptContent = fetch(`assets/js/${moduleName}.min.js`).then(
        (response) => response.text()
      );
      await this.localforageService.setItem(moduleName, scriptContent);
    }
  }

  private async addScriptToContent(scriptName: string, content: string) {
    const scriptContent = await this.localforageService.getItem(scriptName);
    if (scriptContent) {
      return `${scriptContent}
            ${content}`;
    }

    return content;
  }
}
