import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { GeneralHelpers } from '../helpers/general.helper';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Skip token injection for certain URLs
    if (this.shouldSkipRequest(request)) {
      return next.handle(request);
    }

    // Check for skip header
    const skipHeader = GeneralHelpers.getInterceptorSkipHeader();
    if (request.headers.has(skipHeader)) {
      const headers = request.headers.delete(skipHeader);
      return next.handle(request.clone({ headers }));
    }

    // Get token directly from OAuthService
    return from(this.getToken()).pipe(
      mergeMap(token => {
        if (token) {
          const headers = request.headers.set(
            'Authorization',
            `Bearer ${token}`
          );
          return next.handle(request.clone({ headers }));
        }
        return next.handle(request);
      }),
      catchError(error => this.handleError(error))
    );
  }

  private shouldSkipRequest(request: HttpRequest<any>): boolean {
    const issuerUrl = environment.auth.zitadel.issuer;

    // Skip requests to OAuth endpoints
    if (request.url.startsWith(issuerUrl)) {
      return true;
    }

    // Skip Sentry requests
    if (request.url.includes('sentry')) {
      return true;
    }

    // Skip requests to well-known endpoints
    if (request.url.includes('.well-known/openid-configuration')) {
      return true;
    }

    // Skip requests to token endpoints
    if (
      request.url.includes('/token') ||
      request.url.includes('/oauth/token') ||
      request.url.includes('/oauth2/token')
    ) {
      return true;
    }

    // Skip userinfo endpoint requests
    if (request.url.includes('/userinfo')) {
      return true;
    }

    // Skip JWKS endpoints
    if (request.url.includes('/jwks') || request.url.includes('/keys')) {
      return true;
    }

    return false;
  }

  private async getToken(): Promise<string | null> {
    try {
      // Switch to use ID token instead of access token
      if (this.oauthService.hasValidIdToken()) {
        return this.oauthService.getIdToken();
      }
      return null;
    } catch (error) {
      console.error('Error getting token:', error);
      return null;
    }
  }

  private handleError(error: any): Observable<never> {
    console.error('TokenInterceptor Error:', error);
    return from(Promise.reject(error));
  }
}
