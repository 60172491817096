import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
// ─────────────────────────────────────────────────────────────────────────────
// Layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
// ─────────────────────────────────────────────────────────────────────────────
// Directives
import { DirectivesModule } from '../../shared/directives/directives.module';
// ─────────────────────────────────────────────────────────────────────────────
// Router
import { RouterModule } from '@angular/router';
// ─────────────────────────────────────────────────────────────────────────────
// Search module
import { MatDividerModule } from '@angular/material/divider';
import { HeaderSearchComponent } from '../../shared/modules/header-search/header-search.component';

@NgModule({
  imports: [
    CommonModule,
    // Layout
    FlexLayoutModule,
    // Material
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatSlideToggleModule,
    MatBadgeModule,
    MatTooltipModule,
    MatChipsModule,
    MatDividerModule,
    // Directives
    DirectivesModule,
    // Router
    RouterModule,
    HeaderSearchComponent,
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent],
})
export class HeaderModule {}
