export interface CloudConfig {
  endpoint: string;
  region: string;
  bucket: string;
  access_key: string;
  secret_key?: string;
  bucket_whitelist?: string[];
}

export interface ArrowAlias {
  aliasId: number;
  projectId: number;
  name: string;
  url: string;
  cloudBased: boolean;
  cloudConfig: CloudConfig | null | string;
  cloudConfigSecret?: string;
  passwordSecretId?: number;
}

export interface ArrowAliasV2 {
  aliasId: number;
  projectId: number;
  name: string;
  url: string;
  cloudConfig: CloudConfig;
  cloudConfigSecret?: string;
  passwordSecretId?: number;
}

export interface CreateArrowAlias {
  name: string;
  url: string;
  token: string;
  cloudBased: boolean;
  cloudConfig: CloudConfig | null | string;
  cloudConfigSecret: string;
  passwordSecretId?: number;
}

export interface SaveArrowAlias {
  name?: string;
  url?: string;
  cloudConfig?: string;
  cloudConfigSecret?: string;
  passwordSecretId?: number;
}

export const DEFAULT_ARROW_URL = 'http://newton-rs:8080';

export type DataSet = {
  dataset_id: number;
  name: string;
  base_path: string;
  bucket_name: string;
  batch_size: number;
  partitionCols: [string, string][]; // an array of arrays of 2 strings, max 3 pairs
};

export type DataSetView = {
  view_id: number;
  dataset_id: number;
  name: string;
  last_modified: Date;
  expires_at: Date;
  file_count: number;
};

export interface ArrowClient {
  listDataSets(): Promise<DataSet[]>;
  createDataSet(
    base_path: string,
    bucket_name: string,
    batch_size: number,
    partition_cols: [string, string][]
  ): Promise<DataSet>;
  cacheDataSet(name: string): Promise<void>;
  getSchema(name: string): Promise<any>;
  deleteDataSet(name: string): Promise<void>;
  listViews(): Promise<DataSetView[]>;
  createView(
    dataset_id: number,
    name: string,
    expires_after?: number,
    partition_regex?: string,
    query?: string
  ): Promise<string>;
  deleteView(view_id: number): Promise<void>;
  read(dataset_id: number, view_id: number, query?: string): Promise<any>;
  initPut(...args: any[]): void;
  continuePut(...args: any[]): void;
  put(...args: any[]): void;
  endPut(...args: any[]): void;
}

export interface ArrowAliasWSMessage {
  message: string;
  error: string;
  description: string;
  trans_id: number;
}

export enum wsMessageTypes {
  AlreadyConnected = 'AlreadyConnected',
}
