import { Component, OnDestroy } from '@angular/core';
import { HEADER_TITLE } from '../../shared/constants/general.constants';
import { AutoUnsubscribe } from '../../shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {

  currentYear: number = new Date().getFullYear();
  title = HEADER_TITLE.toUpperCase();

  constructor() {
    // This is intentional
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // This is intentional
  }
}
