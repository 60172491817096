export enum ResponseType {
  SessionCreated = 0,
  HealthCheck = 1,
  SingleImageReady = 2,
  SingleImageReceived = 3,
  ReceivePrompt = 4,
  RefinePrompt = 4,
}

export enum RequestType {
  CreateSession = 0,
  HealthCheck = 1,
  SendSingleImage = 2,
  SendPrompt = 3,
  RefinePrompt = 4,
}

export enum ResponseStatus {
  Ok = 0,
  Error = 1,
  Warning = 2,
}
