import { Component, OnDestroy, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  of,
} from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { SearchService } from '../../services/search/search.service';
import { AppStateService } from '../../services/app-state/app-state.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import {
  SearchResponse,
  SearchProjectResult,
  SearchChunkResult,
  SearchNotebookResult,
} from '../../services/search/models/search.types';

type SearchResultItem =
  | (SearchProjectResult & { resultType: 'project' })
  | (SearchNotebookResult & { resultType: 'notebook' })
  | (SearchChunkResult & { resultType: 'chunk' });

@AutoUnsubscribe()
@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
})
export class HeaderSearchComponent implements OnDestroy {
  #appState = inject(AppStateService);
  searchCtrl = new FormControl('');
  filteredResults: Observable<SearchResultItem[]>;
  isLoading = false;

  constructor(private searchService: SearchService, private router: Router) {
    this.filteredResults = this.searchCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((query) => this.searchAll(query || '')),
      map((results) => this.processSearchResults(results))
    );
  }

  /**
   * Lifecycle hook handled by @AutoUnsubscribe decorator
   * @ignore
   */
  // eslint-disable-next-line
  ngOnDestroy(): void {
    // Cleanup handled by @AutoUnsubscribe decorator
  }

  private searchAll(query: string): Observable<SearchResponse> {
    if (!query || query.length < 2) {
      return of({ projects: [], notebooks: [], chunks: [] });
    }

    this.isLoading = true;
    return this.searchService
      .search({
        query,
        method: 'like',
        versions: this.#appState.isHeadVersion() ? 'head' : 'latest',
        project_search: true,
        chunk_search: true,
        limit: 5,
      })
      .pipe(
        map((response) => {
          this.isLoading = false;
          return response;
        })
      );
  }

  private processSearchResults(response: SearchResponse): SearchResultItem[] {
    const results: SearchResultItem[] = [
      ...response.projects.map((project) => ({
        ...project,
        resultType: 'project' as const,
      })),
      ...response.notebooks.map((notebook) => ({
        ...notebook,
        resultType: 'notebook' as const,
      })),
      ...response.chunks.map((chunk) => ({
        ...chunk,
        resultType: 'chunk' as const,
      })),
    ];

    return results;
  }

  displayResult(result: SearchResultItem | null): string {
    if (!result) return '';

    switch (result.resultType) {
      case 'project':
        return result.name;
      case 'notebook':
        return `${result.notebookName} (${result.projectName})`;
      case 'chunk':
        return `${result.chunkName} - ${result.notebookName}`;
    }
  }

  handleSelection(event: MatAutocompleteSelectedEvent) {
    const result = event.option.value as SearchResultItem;

    switch (result.resultType) {
      case 'project':
        this.#appState.navigateToProject(result.projectId);
        break;
      case 'notebook':
      case 'chunk':
        // Assuming navigateToNotebook now accepts a single parameter
        this.#appState.navigateToProject(result.projectId);
        break;
    }
    this.searchCtrl.setValue('');
  }
}
