<mat-card class="dialog-content">
  <mat-card-header>
    <mat-card-title>{{ data.title }}</mat-card-title>
    <mat-card-subtitle>
      <span *ngIf="fileItem.name">Name: {{ fileItem.name }}&nbsp;</span>
      <span *ngIf="sizeFormatted">Size: {{ sizeFormatted }}&nbsp;</span>
      <span *ngIf="fileItem.createdAt"
        >Created at: {{ fileItem.createdAt | date : "short" }}&nbsp;</span
      >
      <span *ngIf="fileItem.modifiedAt"
        >Modified at: {{ fileItem.modifiedAt | date : "short" }}</span
      >
    </mat-card-subtitle>
    <button
      mat-icon-button
      [mat-dialog-close]="false"
      *ngIf="data.hasClose"
      class="small close"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>

  <mat-card-content>
    <ng-container [ngSwitch]="fileItem.fileType">
      <div *ngSwitchCase="'image'">
        <img
          *ngIf="base64"
          appImgFallback
          class="preview-image img-fluid rounded mx-auto d-block"
          [attr.src]="base64 | safe : 'resourceUrl'"
          alt="group attachment preview"
        />
      </div>

      <div *ngSwitchCase="'video'">
        <video
          *ngIf="base64"
          width="1024"
          height="500"
          controls="controls"
          preload="auto"
          autoplay
        >
          <source [attr.src]="base64" type="video/mp4" />
          <source [attr.src]="base64" type="video/webm" />
          <source [attr.src]="base64" type="video/ogg" />

          <p>Your user agent does not support the HTML5 Video element.</p>
        </video>
      </div>

      <div *ngSwitchCase="'audio'">
        <audio
          class="w100"
          controls="controls"
          preload="auto"
          autoplay
          [attr.src]="base64"
        >
          Your browser does not support the audio element.
        </audio>
      </div>

      <div *ngSwitchCase="'pdf'">
        <iframe
          style="--aspect-ratio: 16/9"
          width="1024"
          height="536"
          frameborder="0"
          [attr.src]="base64 | safe : 'resourceUrl'"
          title="pdf preview"
        >
          <p>Your browser does not support iframes.</p>
        </iframe>
      </div>

      <div *ngSwitchCase="'csv'">
        <custom-table
          *ngIf="tableData?.data?.length"
          [tableData]="tableData.data"
          [tableColumns]="tableData.columns"
          [isFilterable]="true"
          [isPageable]="true"
          [paginationSizes]="[10, 20, 50]"
          [defaultPageSize]="10"
          [isControlPanel]="true"
          controlPanelType="duckdb"
        ></custom-table>
      </div>

      <div *ngSwitchCase="'arrow'">
        <custom-table
          *ngIf="tableData?.data?.length"
          [tableData]="tableData.data"
          [tableColumns]="tableData.columns"
          [isFilterable]="true"
          [isPageable]="true"
          [paginationSizes]="[10, 20, 50]"
          [defaultPageSize]="10"
          [isControlPanel]="true"
          controlPanelType="duckdb"
        ></custom-table>
      </div>

      <div *ngSwitchCase="'parquet'">
        <custom-table
          *ngIf="tableData?.data?.length"
          [tableData]="tableData.data"
          [tableColumns]="tableData.columns"
          [isFilterable]="true"
          [isPageable]="true"
          [paginationSizes]="[10, 20, 50]"
          [defaultPageSize]="10"
          [isControlPanel]="true"
          controlPanelType="duckdb"
        ></custom-table>
      </div>

      <app-code-editor
        *ngSwitchCase="'md'"
        (updateContent)="updateContent($event)"
        (startEdit)="setFormTouched()"
        [mode]="mode"
        [content]="data.model"
      ></app-code-editor>

      <app-code-editor
        *ngSwitchCase="'javascript'"
        (updateContent)="updateContent($event)"
        (startEdit)="setFormTouched()"
        [mode]="mode"
        [content]="data.model"
      ></app-code-editor>

      <app-code-editor
        *ngSwitchCase="'python'"
        (updateContent)="updateContent($event)"
        (startEdit)="setFormTouched()"
        [mode]="mode"
        [content]="data.model"
      ></app-code-editor>

      <app-code-editor
        *ngSwitchCase="'text'"
        (updateContent)="updateContent($event)"
        (startEdit)="setFormTouched()"
        [mode]="mode"
        [content]="data.model"
      ></app-code-editor>

      <div *ngSwitchDefault>
        <div class="alert alert-danger" role="alert">
          Sorry preview is no available
        </div>
      </div>
    </ng-container>
  </mat-card-content>

  <mat-card-actions [align]="'end'">
    <ng-container *ngIf="hasWritePermission()">
      <button
        mat-stroked-button
        color="primary"
        [mat-dialog-close]="data"
        *ngIf="data?.confirmCaption"
        class="mr-2"
        [disabled]="loading"
        [class.loading]="loading"
      >
        {{ data.confirmCaption }}
      </button>
    </ng-container>
    <button
      mat-stroked-button
      color="warn"
      [mat-dialog-close]="false"
      *ngIf="data?.cancelCaption"
      [disabled]="loading"
      [class.loading]="loading"
    >
      {{ data.cancelCaption }}
    </button>
  </mat-card-actions>
</mat-card>
