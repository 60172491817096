import { GeneralHelpers } from '../../helpers/general.helper';
import { RuntimeResult } from '../../interfaces/runtime.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';

import { FILE_EXTENSIONS, LOG_SEVERITY } from '../../constants/general.constants';
import { FileModule } from '../../interfaces/file.interface';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { ExceutionHelperError } from '../../interfaces/errors.interface'

export class JavaScriptHelper {
  public static addFetchToContext(context: any): any {
    const fetchFunction = context.newAsyncifiedFunction(
      'fetch',
      async (url: any, options: any = {}) => {
        try {
          // Convert QuickJS objects to normal JS objects
          const config = {
            method: options.method || 'GET',
            headers: options.headers ? context.dump(options.headers) : {},
            body: options.body ? context.dump(options.body) : undefined,
          };

          const nativeResponse = await fetch(context.dump(url), config);
          
          // Create a Response-like object with common methods and properties
          const responseObj = context.newObject();
          
          // Add essential Response properties
          context.setProp(responseObj, 'ok', nativeResponse.ok);
          context.setProp(responseObj, 'status', nativeResponse.status);
          context.setProp(responseObj, 'statusText', context.newString(nativeResponse.statusText));
          context.setProp(responseObj, 'url', context.newString(nativeResponse.url));
          
          // Create headers object
          const headersObj = context.newObject();
          nativeResponse.headers.forEach((value, key) => {
            context.setProp(headersObj, key, context.newString(value));
          });
          context.setProp(responseObj, 'headers', headersObj);
          
          // Add response methods
          const textMethod = context.newAsyncifiedFunction('text', async () => {
            const text = await nativeResponse.text();
            return context.newString(text);
          });
          context.setProp(responseObj, 'text', textMethod);
          
          const jsonMethod = context.newAsyncifiedFunction('json', async () => {
            const json = await nativeResponse.json();
            return this.createContextEntity(context, json);
          });
          context.setProp(responseObj, 'json', jsonMethod);
          
          const arrayBufferMethod = context.newAsyncifiedFunction('arrayBuffer', async () => {
            const buffer = await nativeResponse.arrayBuffer();
            // Create a Uint8Array view of the ArrayBuffer
            const uint8Array = new Uint8Array(buffer);
            // Return it as a proper array to preserve binary data
            return this.createContextEntity(context, Array.from(uint8Array));
          });
          context.setProp(responseObj, 'arrayBuffer', arrayBufferMethod);
          
          const blobMethod = context.newAsyncifiedFunction('blob', async () => {
            const blob = await nativeResponse.blob();
            // For simplicity, we'll just return the size and type
            const blobObj = context.newObject();
            context.setProp(blobObj, 'size', blob.size);
            context.setProp(blobObj, 'type', context.newString(blob.type));
            return blobObj;
          });
          context.setProp(responseObj, 'blob', blobMethod);

          return responseObj;
        } catch (error: any) {
          console.error('Fetch Error:', error);
          throw context.newError(
            'FetchError',
            `Failed to fetch: ${error.message}`
          );
        }
      }
    );

    context.setProp(context.global, 'fetch', fetchFunction);
    fetchFunction.dispose();
    return context;
  }

  public static createContextEntity(context: any, value: any): any {
    const type = GeneralHelpers.trueTypeOf(value);
    let result: any;

    switch (type) {
      case 'number':
        result = context.newNumber(value);
        break;
      case 'string':
        result = context.newString(value);
        break;
      case 'array':
        result = this.interpolateIterableData(
          context,
          context.newArray(),
          value
        );
        break;
      case 'object':
        result = this.interpolateIterableData(
          context,
          context.newObject(),
          value
        );
        break;
      case 'function':
        result = context.newFunction(value);
        break;
      case 'uint32Array': {
        // Create a proper array to preserve the binary data
        const array = context.newArray();
        const uint32Array = new Uint32Array(value);
        for (let i = 0; i < uint32Array.length; i++) {
          context.setProp(array, i, uint32Array[i]);
        }
        result = array;
        break;
      }
      case 'uint16array': {
        // Create a proper array to preserve the binary data
        const array = context.newArray();
        const uint16Array = new Uint16Array(value);
        for (let i = 0; i < uint16Array.length; i++) {
          context.setProp(array, i, uint16Array[i]);
        }
        result = array;
        break;
      }
      case 'uint8array': {
        // Create a proper array to preserve the binary data
        const array = context.newArray();
        const uint8Array = new Uint8Array(value);
        for (let i = 0; i < uint8Array.length; i++) {
          context.setProp(array, i, uint8Array[i]);
        }
        result = array;
        break;
      }
      case 'arraybuffer': {
        // Create a proper array to preserve the binary data
        const array = context.newArray();
        const uint8Array = new Uint8Array(value);
        for (let i = 0; i < uint8Array.length; i++) {
          context.setProp(array, i, uint8Array[i]);
        }
        result = array;
        break;
      }
      // Boolean is a string presentation of a boolean value in quickjs
      case 'boolean':
      default:
        result = context.newString(`${value}`);
        break;
    }

    return result;
  }

  public static addConsoleLog(context: any, chunkContext: ChunkContext): any {
    const logHandle = context.newFunction('log', (...args: any) => {
      const nativeArgs = args.map(context.dump);
      console.log('console.log', ...(nativeArgs as any[]));
      chunkContext.addLog(nativeArgs, LOG_SEVERITY.LOG);
    });

    const infoHandle = context.newFunction('info', (...args: any) => {
      const nativeArgs = args.map(context.dump);
      console.info('console.info', ...(nativeArgs as any[]));
      chunkContext.addLog(nativeArgs, LOG_SEVERITY.INFO);
    });

    const warnHandle = context.newFunction('warn', (...args: any) => {
      const nativeArgs = args.map(context.dump);
      console.warn('console.warn', ...(nativeArgs as any[]));
      chunkContext.addLog(nativeArgs, LOG_SEVERITY.WARN);
    });

    const errorHandle = context.newFunction('error', (...args: any) => {
      const nativeArgs = args.map(context.dump);
      console.error('console.error', ...(nativeArgs as any[]));
      chunkContext.addLog(nativeArgs, LOG_SEVERITY.ERROR);
    });

    const consoleHandle = context.newObject();
    context.setProp(consoleHandle, 'log', logHandle);
    context.setProp(consoleHandle, 'info', infoHandle);
    context.setProp(consoleHandle, 'warn', warnHandle);
    context.setProp(consoleHandle, 'error', errorHandle);
    context.setProp(context.global, 'console', consoleHandle);

    logHandle.dispose();
    infoHandle.dispose();
    warnHandle.dispose();
    errorHandle.dispose();
    consoleHandle.dispose();

    return context;
  }

  private static interpolateIterableData(
    runtimeContext: any,
    newContext: any,
    value: any
  ): any {
    const setValue = (newContext: any, key: number | string, value: any) => {
      const type = GeneralHelpers.trueTypeOf(value);
      let resultContext;
      let interpolatedContext;

      switch (type) {
        case 'number':
          interpolatedContext = runtimeContext.newNumber(value);
          runtimeContext.setProp(newContext, key, interpolatedContext);
          interpolatedContext.dispose();
          break;
        case 'string':
          interpolatedContext = runtimeContext.newString(value);
          runtimeContext.setProp(newContext, key, interpolatedContext);
          interpolatedContext.dispose();
          break;
        case 'boolean':
          runtimeContext.setProp(newContext, key, value);
          break;
        case 'object':
          interpolatedContext = runtimeContext.newObject();
          resultContext = this.interpolateIterableData(
            runtimeContext,
            interpolatedContext,
            value
          );
          runtimeContext.setProp(newContext, key, resultContext);
          interpolatedContext.dispose();
          break;
        case 'array':
          interpolatedContext = runtimeContext.newArray();
          resultContext = this.interpolateIterableData(
            runtimeContext,
            interpolatedContext,
            value
          );
          runtimeContext.setProp(newContext, key, resultContext);
          interpolatedContext.dispose();
          break;
        case 'uint8array':
        case 'uint16array':
        case 'uint32array':
        case 'arraybuffer':
          resultContext = this.createContextEntity(runtimeContext, value);
          runtimeContext.setProp(newContext, key, resultContext);
          break;
        default:
          interpolatedContext = runtimeContext.newString(`${value}`);
          runtimeContext.setProp(newContext, key, interpolatedContext);
          interpolatedContext.dispose();
          break;
      }
    };

    if (GeneralHelpers.trueTypeOf(value) === 'array') {
      value.forEach((value: any, index: number) => {
        setValue(newContext, index, value);
      });
    }

    if (GeneralHelpers.trueTypeOf(value) === 'object') {
      for (const key in value) {
        if (Object.prototype.hasOwnProperty.call(value, key)) {
          setValue(newContext, key, value[key]);
        }
      }
    }

    return newContext;
  }

  public static renderError(error: ExceutionHelperError): RuntimeResult {
    if (!!(error as any) === false) {
      return {
        value: `${error}`,
        resultStatus: 'error',
      };
    }
    const { message, name, stack } = error;
    // Check if the error message contains double quotes, if so, assume it is detailed enough to show to the user w/o the name and stack
    if (message && message.indexOf('"') > 0) {
      return {
        value: `${message}`,
        resultStatus: 'error',
      };
    }
    return {
      value: `${message} ${name} ${stack}`,
      resultStatus: 'error',
    };
  }

  public static renderSuccess(value: any): RuntimeResult {
    if (value === '' || value === undefined || value === null) {
      return {
        value: `${value}`,
        resultStatus: 'warning',
      };
    }

    const type = GeneralHelpers.trueTypeOf(value);

    switch (type) {
      case 'array':
        value = GeneralHelpers.jsonStringify(value);
        break;
      case 'object':
        value = GeneralHelpers.jsonStringify(value);
        break;
      default:
        break;
    }

    return {
      value: value,
      resultStatus: 'success',
    };
  }

  public static addPrintToContext(context: any, chunkContext: any): any {
    const printFunction = context.newFunction('print', (...args: any) => {
      // Print the arguments to the console for visibility
      console.log(...args.map((arg: any) => context.dump(arg)));
      chunkContext.addLog(args.map((arg: any) => context.dump(arg)));
      // Convert and return the arguments as context entities for further use in QuickJS
      if (args.length === 1) {
        // If there is only one argument, return it directly as a context entity
        return JavaScriptHelper.createContextEntity(
          context,
          context.dump(args[0])
        );
      } else {
        // If there are multiple arguments, return them as an array of context entities
        const array = context.newArray();
        args.forEach((arg: any, index: number) => {
          context.setProp(
            array,
            index,
            JavaScriptHelper.createContextEntity(context, context.dump(arg))
          );
        });
        return array;
      }
    });

    context.setProp(context.global, 'print', printFunction);
    printFunction.dispose(); // Dispose of the print function after adding it to global
    return context;
  }

  public static addGlobalData(
    context: any,
    chunkData: Chunk | null,
    notebookVariablesService: any
  ) {
    const objectForKey = context.newObject();

    if (notebookVariablesService?.variableList?.length > 0) {
      for (const element of notebookVariablesService.variableList) {
        if (chunkData && chunkData.sortOrder >= element.sortOrder) {
          const resultEntity = JavaScriptHelper.createContextEntity(
            context,
            element.value
          );

          context.setProp(objectForKey, element.name, resultEntity);

          resultEntity.dispose();
        }
      }
    }

    context.setProp(context.global, 'data', objectForKey);

    objectForKey.dispose();

    return context;
  }

  static async processGlobalModules(
    content: string,
    fileService: any
  ): Promise<any> {
    const list: FileModule[] = await fileService.getFileModuleList([
      FILE_EXTENSIONS.js,
      FILE_EXTENSIONS.json,
    ]);
    const hasModules = Array.isArray(list) && list.length > 0;
    const hasGlobal =
      hasModules && list.some((fileModule: FileModule) => fileModule.isGlobal);
    let result: string = '';
    if (hasGlobal) {
      const promises = list.map((item: FileModule) => {
        return fileService.getLocalforageItem(item.name);
      });
      return await Promise.all(promises).then((results) => {
        for (let index = 0; index < list.length; index++) {
          result += results[index];
        }
        return `${result}
            ${content}`;
      });
    } else {
      return content;
    }
  }
}
