import { Injectable } from '@angular/core';
import { PythonModuleManager } from './python-module-manager.service';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { Chunk } from '../../interfaces/chunk/chunk.interface';

@Injectable({
  providedIn: 'root',
})
export class PythonChunkHandler {
  constructor(private moduleManager: PythonModuleManager) { }

  async handleChunk(chunk: Chunk, context: ChunkContext): Promise<boolean> {
    try {
      // Register chunk as module
      const success = await this.moduleManager.registerModule(
        chunk.name,
        chunk.content,
        context
      );

      if (!success) {
        throw new Error(`Failed to register chunk ${chunk.name} as module`);
      }

      // Update execution context with new module info
      const moduleInfo = this.moduleManager.getModuleInfo(chunk.name);
      if (moduleInfo) {
        context.addMessage(
          `Module ${chunk.name} registered with exports: ${moduleInfo.exports.join(', ')}`,
          'info'
        );
      }

      return true;

    } catch (error) {
      context.addMessage(`Chunk handling failed: ${error}`, 'danger');
      return false;
    }
  }

  async updateChunk(chunk: Chunk, context: ChunkContext): Promise<boolean> {
    try {
      return await this.moduleManager.updateModule(chunk.name, chunk.content, context);
    } catch (error) {
      context.addMessage(`Chunk update failed: ${error}`, 'danger');
      return false;
    }
  }
}
