import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Dialog } from '../../interfaces/create-dialog.interface';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

export interface InputDialogData extends Dialog {
  inputLabel?: string;
  inputType?: 'text' | 'password' | 'number' | 'email';
  inputValue?: string;
  inputPlaceholder?: string;
  inputRequired?: boolean;
  inputPattern?: string;
  inputMinLength?: number;
  inputMaxLength?: number;
}

@AutoUnsubscribe()
@Component({
  selector: 'app-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent implements OnDestroy {
  inputForm: FormGroup;
  showPassword = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData,
    private dialogRef: MatDialogRef<InputDialogComponent>,
    private fb: FormBuilder
  ) {
    this.inputForm = this.fb.group({
      inputValue: ['', this.getValidators()]
    });

    // Set initial value if provided
    if (data.inputValue) {
      this.inputForm.patchValue({ inputValue: data.inputValue });
    }
  }

  // eslint-disable-next-line
  ngOnDestroy() {
    // This is intentional
  }

  private getValidators() {
    const validators = [];

    if (this.data.inputRequired) {
      validators.push(Validators.required);
    }

    if (this.data.inputPattern) {
      validators.push(Validators.pattern(this.data.inputPattern));
    }

    if (this.data.inputMinLength) {
      validators.push(Validators.minLength(this.data.inputMinLength));
    }

    if (this.data.inputMaxLength) {
      validators.push(Validators.maxLength(this.data.inputMaxLength));
    }

    if (this.data.inputType === 'email') {
      validators.push(Validators.email);
    }

    return validators;
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onSubmit() {
    if (this.inputForm.valid) {
      const result = {
        ...this.data,
        inputValue: this.inputForm.get('inputValue')?.value
      };
      this.dialogRef.close(result);
    }
  }
}
