import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WSS_API_BASE, NOTIFICATION } from '../../constants/general.constants';
import { Observable, Subject } from 'rxjs';
import { BaseRequest } from '../../interfaces/notification.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NotificationWebsocketService {
  private WS_URL!: string;
  private TOKEN!: string;
  private socket!: WebSocket;
  public messages: Subject<any> = new Subject<any>();
  private transId = 0;
  private readonly RETRY_TIMER = 2e3;
  private readonly MAX_RETRY_DURATION = 300000;
  private reconnectAttempts = 0;
  private readonly MAX_RECONNECT_ATTEMPTS = 5;
  private isReconnecting = false;

  constructor(
    private readonly toastr: ToastrService,
    private readonly authService: AuthenticationService,
    private readonly router: Router
  ) { }

  public async init() {
    try {
      const token = await this.authService.getIdToken();
      this.setConnection(token);
    } catch (error) {
      console.error('Failed to initialize websocket connection:', error);
      this.handleConnectionFailure('Failed to establish websocket connection');
    }
  }

  private setConnection(token: string | null): void {
    if (!token) {
      return;
    }

    this.TOKEN = token;
    this.WS_URL = `${WSS_API_BASE}${NOTIFICATION}`;
    this.connectToWebsocket(this.WS_URL, this.TOKEN);
  }

  private connectToWebsocket(url: string, authToken: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      return;
    }

    this.socket = new WebSocket(`${url}?token=${authToken}`);

    this.socket.addEventListener('open', (event) => {
      console.log('WebSocket connection opened:', event);
      this.reconnectAttempts = 0;
      this.isReconnecting = false;

      // Resubscribe to watches and get current editor status
      this.watchEditors();
    });

    this.socket.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      if (data.message === 'Error' && data.details) {
        const ignoreErrors = ['editor locked'];
        if (!ignoreErrors.includes(data.details.description)) {
          this.toastr.error(data.details.description, 'Error!');
        }
      }
      this.messages.next(data);
    });

    this.socket.addEventListener('close', (event) => {
      console.log('WebSocket connection closed:', event);

      if (!this.isReconnecting) {
        this.attemptReconnection();
      }
    });

    this.socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event);
      this.toastr.error('A WebSocket error occurred.', 'WebSocket Error');
    });
  }

  private async attemptReconnection(): Promise<void> {
    if (this.isReconnecting) {
      return;
    }

    this.isReconnecting = true;
    this.reconnectAttempts++;

    if (this.reconnectAttempts > this.MAX_RECONNECT_ATTEMPTS) {
      this.handleConnectionFailure('Unable to maintain websocket connection');
      return;
    }

    console.log(
      `Attempting to reconnect... Attempt ${this.reconnectAttempts}/${this.MAX_RECONNECT_ATTEMPTS}`
    );

    try {
      const token = await this.authService.getIdToken();
      if (token) {
        setTimeout(() => {
          this.setConnection(token);
        }, this.RETRY_TIMER * Math.min(this.reconnectAttempts, 5));
      } else {
        this.handleConnectionFailure('Authentication token not available');
      }
    } catch (error) {
      console.error(
        'Failed to get authentication token for reconnection:',
        error
      );
      this.handleConnectionFailure(
        'Failed to authenticate for websocket connection'
      );
    }
  }

  private handleConnectionFailure(message: string): void {
    this.toastr.error(message, 'Connection Error');
    this.isReconnecting = false;
    // Navigate back to project list
    this.router.navigate(['/projects']);
  }

  public sendMessage(message: any): void {
    if (!this.socket) {
      console.warn('WebSocket is not initialized. Message not sent.');
      return;
    }
    if (this.socket.readyState === WebSocket.OPEN) {
      if (message.transId === undefined) {
        message.trans_id = this.getTransId();
      }
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  public async sendMessageAwait(message: any): Promise<void> {
    const startTime = Date.now();

    return new Promise<void>((resolve, reject) => {
      const initializeAndSend = () => {
        if (!this.socket) {
          this.init();
        }

        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
          if (message.trans_id === undefined) {
            message.trans_id = this.getTransId();
          }
          this.socket.send(JSON.stringify(message));
          resolve();
        } else if (Date.now() - startTime < this.MAX_RETRY_DURATION) {
          setTimeout(initializeAndSend, this.RETRY_TIMER);
        } else {
          console.error(
            'Unable to send message: WebSocket unavailable for 5 minutes.'
          );
          reject(new Error('WebSocket unavailable for 5 minutes'));
        }
      };

      initializeAndSend();
    });
  }

  public receiveMessage(): Observable<any> {
    return this.messages.asObservable();
  }

  private getTransId(): number {
    const tid = this.transId;
    this.transId++;
    return tid;
  }

  public async getSubscriptions() {
    const message: BaseRequest = {
      message: 'list-subscriptions',
      trans_id: this.getTransId(),
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  public async watchEditors() {
    const message: BaseRequest = {
      version: 'v1',
      message: 'watch-editors',
      trans_id: this.getTransId(),
      watch: true,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
    }
  }

  // ─── Editor locking functionality ─────────────────────────────────────

  public async lockEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'lock-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async unlockEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'unlock-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public async getCurrentEditor(projectId: number) {
    const message: BaseRequest = {
      version: 'v1',
      message: 'current-editor',
      trans_id: this.getTransId(),
      project_id: projectId,
    };

    try {
      await this.sendMessageAwait(message);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}
