<mat-card>
  <mat-card-header>
    <mat-card-title>File Information</mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null" class="small close">
      <mat-icon>close</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content class="my-4">
    <app-file-info [fileData]="file"></app-file-info>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button mat-stroked-button color="warn" [mat-dialog-close]="null">
      Cancel
    </button>
  </mat-card-actions>
</mat-card>
