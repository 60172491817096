import { Component, input, signal, OnDestroy, OnInit, ElementRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ArrowClient } from 'src/app/shared/services/arrow-websocket/arrow-client';
import { ExecutionContext } from 'src/app/shared/interfaces/chunk/chunk-context.interface'
import { CloudFileDataSource } from './cloud-file-data-source';
import { CloudNode, CloudResponse } from '../cloud-schema.interface';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';
import { SelectionModel } from '@angular/cdk/collections'
import { Subscription } from 'rxjs'
import { MatRow } from '@angular/material/table'

const MAX_KEYS = 100;

@AutoUnsubscribe()
@Component({
  selector: 'arrow-file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrl: './file-explorer.component.scss'
})
export class FileExplorerComponent implements OnDestroy, OnInit, AfterViewInit {
  arrowClient = input.required<ArrowClient>();
  context = input.required<ExecutionContext>();
  readonly #subs = new Subscription();
  dataSource?: CloudFileDataSource;
  readonly loadingStatus = signal<string>('Idle');
  readonly loading = signal<boolean>(false);
  readonly displayedColumns = ['name', 'size', 'lastModified'];
  protected selectionModel = new SelectionModel<CloudNode>(false);
  @ViewChildren(MatRow, {read: ElementRef}) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

  #clickTimer: any;
  #preventSingleClick = false;
  readonly #clickDelay = 200;

  // eslint-disable-next-line
  ngOnDestroy(): void {
    // intentionally empty
    this.#subs.unsubscribe();
  }

  ngOnInit(): void {
    // create the data source
    this.loadRoot();
  }

  ngAfterViewInit(): void {
    // Initialize any view-related functionality after the view is initialized
    if (this.dataSource && this.rows) {
      // Focus on the first row if available
      this.updateRowFocus();
    }
  }

  private updateRowFocus(): void {
    // Implementation for row focus handling
    setTimeout(() => {
      const selectedIndex = this.dataSource?.selectedIndex();
      if (selectedIndex !== undefined && selectedIndex >= 0 && this.rows && this.rows.length > selectedIndex) {
        this.rows.get(selectedIndex)?.nativeElement.focus();
      }
    });
  }

  loadRoot(): void {
    this.loadingStatus.set('Loading file root...');
    this.loading.set(true);
    this.arrowClient().doAction('ListCloud', `{ "prefix": "", "delimiter": "/", "max_keys": ${MAX_KEYS} }`).then((response) => {
      const rsp = JSON.parse(response) as CloudResponse;
      const rootNode = new CloudNode('', [], undefined);
      rootNode.loadFromResponse(rsp);
      this.dataSource = new CloudFileDataSource(rootNode);
      this.#subs.add(this.selectionModel.changed.subscribe((event) => {
        const row = event.added[0];
        this.dataSource?.selectRow(row);
      }));
      }).finally(() => {
      this.loadingStatus.set('Idle');
      this.loading.set(false);
    });
  }

  singleClick(row: CloudNode): void {
    this.#preventSingleClick = false;
    this.#clickTimer = setTimeout(() => {
      if (!this.#preventSingleClick) {
        this.selectionModel.toggle(row);
      }
    }, this.#clickDelay);
  }

  doubleClick(row: CloudNode): void {
    this.#preventSingleClick = true;
    clearTimeout(this.#clickTimer);
    if (!row.isFile) {
      if (row.children.length > 0) {
        this.dataSource?.displayFolder(row);
        return;
      }
      this.loadingStatus.set(`Loading ${row.name}...`);
      this.loading.set(true);
      this.arrowClient().doAction('ListCloud', `{ "prefix": "${row.name}", "delimiter": "/", "max_keys": ${MAX_KEYS} }`).then((response) => {
        const rsp = JSON.parse(response) as CloudResponse;
        row.loadFromResponse(rsp);
        this.dataSource?.displayFolder(row);
      }).finally(() => {
        this.loadingStatus.set('Idle');
        this.loading.set(false);
      });
    }
  }

  loadMore() {
    const node = this.dataSource?.displayedNode();
    if (node?.nextMarker()) {
      console.log('loading more...');
      this.loadingStatus.set('Loading more...');
      this.loading.set(true);
      this.rows.last.nativeElement.scrollIntoView({behavior: 'smooth'});
      const fullPath = this.dataSource?.historyStack().map((node) => node.name).join('/').replace(/^\//, '');
      this.arrowClient().doAction('ListCloud', `{ "prefix": "${fullPath}", "delimiter": "/", "max_keys": ${MAX_KEYS}, "marker": "${node.nextMarker()}" }`).then((response) => {
        const rsp = JSON.parse(response) as CloudResponse;
        node.loadFromResponse(rsp);
        this.dataSource?.displayFolder(node);
      }).finally(() => {
        this.loadingStatus.set('Idle');
        this.loading.set(false);
      });
    }
  }
}
