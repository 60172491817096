import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { Router } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Layout Modules
import { HeaderModule } from './layout/header/header.module';
import { FooterModule } from './layout/footer/footer.module';
import { SpinnerModule } from './layout/spinner/spinner.module';

// Shared Modules
import { SharedModule } from './shared/shared.module';
import { DirectivesModule } from './shared/directives/directives.module';

// Third-Party Modules
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { GuiGridModule } from '@generic-ui/ngx-grid';

// Auth Libraries
import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { AuthConfig, OAuthModule, OAuthService } from 'angular-oauth2-oidc';

// Services
import { JavascriptService } from './shared/services/javascript/javascript.service';
import { PythonService } from './shared/services/python/python.service';
import { DuckDbService } from './shared/services/duck-db/duck-db.service';
import { NotificationWebsocketService } from './shared/services/notification-websocket/notification-websocket.service';
import { Sam2WebsocketService } from './shared/services/sam2-websocket/sam2-websocket.service';
import { PermissionsService } from './shared/services/permissions/permissions.service';
import { AuthenticationService } from './shared/services/authentication/authentication.service';

// Environment
import { environment } from 'src/environments/environment';
import { HttpErrorInterceptor } from './shared/interceptors/error.interceptor';
import { LoaderInterceptor } from './shared/interceptors/loading.interceptor';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { MessageService } from './shared/services/message/message.service';

const authProvider = environment.authProvider;

const staticAuthModules = [
  ...(authProvider === 'auth0'
    ? [
        AuthModule.forRoot({
          domain: environment.auth.auth0.domain,
          clientId: environment.auth.auth0.clientId,
          cacheLocation: environment.auth.auth0.cacheLocation,
          useRefreshTokens: environment.auth.auth0.useRefreshTokens,
          authorizationParams: {
            redirect_uri: environment.auth.auth0.redirectUri,
          },
          httpInterceptor: {
            allowedList: [
              {
                uri: `https://${environment.auth.auth0.domain}/api/*`,
                tokenOptions: {
                  authorizationParams: {
                    audience: `https://${environment.auth.auth0.domain}/api/`,
                    scope: 'read:current_user',
                  },
                },
              },
              { uri: `${environment.apiUrl}*` },
            ],
          },
        }),
      ]
    : []),
  ...(authProvider === 'zitadel'
    ? [
        OAuthModule.forRoot({
          resourceServer: {
            allowedUrls: environment.auth.zitadel.allowedUrls,
            sendAccessToken: true,
          },
        }),
      ]
    : []),
];

const staticAuthProviders = [
  ...(authProvider === 'auth0'
    ? [AuthService]
    : authProvider === 'zitadel'
    ? [AuthenticationService, PermissionsService]
    : []),
];

function createInitializer(service: any) {
  return () => service.init();
}

export function initializeAuth(
  oauthService: OAuthService,
  authService: AuthenticationService,
  permissionsService: PermissionsService,
  router: Router
): () => Promise<void> {
  return async () => {
    if (authProvider !== 'zitadel') return;

    const hasCode = window.location.href.includes('code=');
    const isAuthenticated = await authService.isAuthenticated();

    if (isAuthenticated) {
      try {
        await Promise.all([
          permissionsService.getRoles().toPromise(),
          permissionsService.getPermissions().toPromise(),
        ]);

        // Handle redirect after code flow
        if (hasCode && oauthService.state) {
          const targetUrl = oauthService.state;
          oauthService.state = '';
          if (targetUrl && targetUrl !== '/') {
            await router.navigateByUrl(targetUrl);
          }
        }
      } catch (error) {
        console.error('Error fetching permissions:', error);
        throw error;
      }
    } else {
      try {
        // Initialize auth
        await authService.initializeAuth();

        // Handle code flow if present
        if (hasCode) {
          await new Promise<void>((resolve, reject) => {
            const sub = oauthService.events.subscribe({
              next: (event) => {
                if (event.type === 'token_received') {
                  sub.unsubscribe();
                  resolve();
                }
              },
              error: (error) => {
                sub.unsubscribe();
                reject(error);
              },
            });

            // Set timeout to prevent infinite waiting
            setTimeout(() => {
              sub.unsubscribe();
              reject(new Error('Token exchange timeout'));
            }, 30000);
          });
        } else {
          const currentUrl = router.url;
          await authService.authenticate(currentUrl);
        }
      } catch (error) {
        console.error('Error in auth initialization:', error);
        if ((error as any).message === 'Token exchange timeout') {
          // Schedule a location reload with setTimeout(0)
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }
        throw error;
      }
    }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    HeaderModule,
    FooterModule,
    SpinnerModule,
    SharedModule,
    DirectivesModule,
    GuiGridModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
    }),
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    ...staticAuthModules,
  ],
  providers: [
    // Service Providers
    MessageService,
    JavascriptService,
    PythonService,
    DuckDbService,
    NotificationWebsocketService,
    Sam2WebsocketService,
    ...staticAuthProviders,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [OAuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAuth,
      deps: [OAuthService, AuthenticationService, PermissionsService, Router],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createInitializer,
      deps: [JavascriptService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createInitializer,
      deps: [PythonService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createInitializer,
      deps: [DuckDbService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createInitializer,
      deps: [NotificationWebsocketService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: createInitializer,
      deps: [Sam2WebsocketService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
