import { Injectable } from '@angular/core';
import { tableToIPC, tableFromIPC } from 'apache-arrow';
import init, {
  readParquet,
  Compression,
  writeParquet,
  Table,
  WriterPropertiesBuilder,
} from 'parquet-wasm/esm2/arrow1';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Papa } from 'ngx-papaparse';
import { fromCSV, toArrow } from 'arquero';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class ConvertService {
  constructor(private papa: Papa) { }

  public async csvToArrow(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<Uint8Array> {
    chunkContext.setBusy(true, 'Converting CSV to Arrow');
    const csvData = args[0];

    if (!csvData) {
      const error = new Error('CSV data cannot be null or undefined.');
      chunkContext.addMessage('CSV data cannot be null or undefined.', 'danger');
      chunkContext.setBusy(false);
      throw error;
    }

    try {
      let csvString = '';
      if (
        typeof csvData === 'string' &&
        GeneralHelpers.canBeParsedToNumberArray(csvData)
      ) {
        const bytes = csvData.split(',').map(Number);
        if (bytes.some(isNaN)) {
          const error = new Error('CSV data string contains non-numeric values.');
          chunkContext.addMessage('CSV data string contains non-numeric values.', 'danger');
          chunkContext.setBusy(false);
          throw error;
        }
        csvString = new TextDecoder('utf-8').decode(new Uint8Array(bytes));
      } else if (Array.isArray(csvData)) {
        csvString = this.papa.unparse(csvData);
      } else {
        const error = new Error('Invalid CSV data format: must be a string or an array.');
        chunkContext.addMessage('Invalid CSV data format: must be a string or an array.', 'danger');
        chunkContext.setBusy(false);
        throw error;
      }

      const arqueroTable = fromCSV(csvString);
      const arqueroToArrowTable = toArrow(arqueroTable);
      const arrowTable = tableToIPC(arqueroToArrowTable as any);
      chunkContext.setBusy(false);
      return arrowTable;
    } catch (error: any) {
      console.error(error);
      chunkContext.addMessage('Error processing CSV data: ' + (error.message || error), 'danger');
      chunkContext.setBusy(false);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public async parquetToArrow(
    args: any[],
    chunkContext?: ExecutionContext
  ): Promise<Uint8Array> {
    if (chunkContext) chunkContext.setBusy(true, 'Converting Parquet to Arrow');
    try {
      await init('assets/parquet-wasm/arrow1_bg.wasm');
      const parquetData = args[0];

      if (
        typeof parquetData !== 'string' ||
        !GeneralHelpers.canBeParsedToNumberArray(parquetData)
      ) {
        const error = new Error('Invalid Parquet data format: Data must be a string of numeric byte values.');
        if (chunkContext) {
          chunkContext.addMessage(
            'Invalid Parquet data format: Data must be a string of numeric byte values.',
            'danger'
          );
          chunkContext.setBusy(false);
        }
        throw error;
      }

      const bytes = parquetData.split(',').map(Number);
      const parquetUint8Array = new Uint8Array(bytes);
      const arrowTable = readParquet(parquetUint8Array);
      const arrowIPC = arrowTable.intoIPCStream();
      if (chunkContext) chunkContext.setBusy(false);
      return arrowIPC;
    } catch (error: any) {
      console.error(error);
      if (chunkContext) {
        chunkContext.addMessage(
          'Error converting Parquet to Arrow: ' + (error.message || error),
          'danger'
        );
        chunkContext.setBusy(false);
      }
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public async parquetToArrowForWatchDialog(
    parquetData: Uint8Array,
    chunkContext?: ExecutionContext
  ): Promise<Uint8Array> {
    if (chunkContext) chunkContext.setBusy(true, 'Converting Parquet to Arrow');
    try {
      await init('assets/parquet-wasm/arrow1_bg.wasm');
      const arrowTable = readParquet(parquetData);
      const arrowIPC = arrowTable.intoIPCStream();
      if (chunkContext) chunkContext.setBusy(false);
      return arrowIPC;
    } catch (error) {
      console.error(error);
      if (chunkContext) {
        chunkContext.addMessage(
          'Error converting Parquet to Arrow: ' + (error as Error).message,
          'danger'
        );
        chunkContext.setBusy(false);
      }
      return new Uint8Array();
    }
  }

  public async arrowToParquet(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<Uint8Array> {
    chunkContext.setBusy(true, 'Converting Arrow to Parquet');
    await init('assets/parquet-wasm/arrow1_bg.wasm');
    const arrowData = args[0];

    if (
      typeof arrowData !== 'string' ||
      !GeneralHelpers.canBeParsedToNumberArray(arrowData)
    ) {
      const error = new Error('Invalid Arrow data format: Data must be a string of numeric byte values.');
      chunkContext.addMessage(
        'Invalid Arrow data format: Data must be a string of numeric byte values.',
        'danger'
      );
      chunkContext.setBusy(false);
      throw error;
    }

    try {
      const bytes = arrowData.split(',').map(Number);
      const arrowUint8Array = new Uint8Array(bytes);
      const arrowTable = tableFromIPC(arrowUint8Array);
      const wasmTable = Table.fromIPCStream(tableToIPC(arrowTable, 'stream'));
      const writerProperties = new WriterPropertiesBuilder()
        .setCompression(Compression.SNAPPY)
        .build();
      const parquetUint8Array = await writeParquet(wasmTable, writerProperties);
      chunkContext.setBusy(false);
      return parquetUint8Array;
    } catch (error: any) {
      console.error(error);
      chunkContext.addMessage(
        'Error converting Arrow to Parquet: ' + (error.message || error),
        'danger'
      );
      chunkContext.setBusy(false);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public async jsonToCSV(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<Uint8Array> {
    chunkContext.setBusy(true, 'Converting JSON to CSV');
    if (
      !args[0] ||
      (typeof args[0] !== 'object' &&
        !Array.isArray(args[0]) &&
        !GeneralHelpers.isJSON(args[0]))
    ) {
      const error = new Error('Invalid JSON data format: Data must be an object or array.');
      chunkContext.addMessage(
        'Invalid JSON data format: Data must be an object or array.',
        'danger'
      );
      chunkContext.setBusy(false);
      throw error;
    }

    try {
      const jsonData = args[0];
      const csv = this.papa.unparse(jsonData);
      const result = new TextEncoder().encode(csv);
      chunkContext.setBusy(false);
      return result;
    } catch (error: any) {
      console.error(error);
      chunkContext.addMessage(
        'Error converting JSON to CSV: ' + (error.message || error),
        'danger'
      );
      chunkContext.setBusy(false);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }

  public async csvToJSON(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<any[]> {
    chunkContext.setBusy(true, 'Converting CSV to JSON');
    if (!args[0]) {
      const error = new Error('Invalid CSV data: Data cannot be null or undefined.');
      chunkContext.addMessage(
        'Invalid CSV data: Data cannot be null or undefined.',
        'danger'
      );
      chunkContext.setBusy(false);
      throw error;
    }

    let csvString: string;

    if (typeof args[0] === 'string') {
      if (GeneralHelpers.canBeParsedToNumberArray(args[0])) {
        const bytes = args[0].split(',').map(Number);
        csvString = new TextDecoder('utf-8').decode(new Uint8Array(bytes));
      } else {
        csvString = args[0];
      }
    } else {
      const error = new Error('Invalid CSV data format: Data must be a string or byte string.');
      chunkContext.addMessage(
        'Invalid CSV data format: Data must be a string or byte string.',
        'danger'
      );
      chunkContext.setBusy(false);
      throw error;
    }

    try {
      const result = this.papa.parse(csvString, { header: true });
      if (result.errors && result.errors.length > 0) {
        const error = new Error('CSV parsing error: ' + result.errors.map((e) => e.message).join('; '));
        chunkContext.addMessage(
          'CSV parsing error: ' +
          result.errors.map((e) => e.message).join('; '),
          'danger'
        );
        chunkContext.setBusy(false);
        throw error;
      }
      chunkContext.setBusy(false);
      return result.data;
    } catch (error: any) {
      if (error.message && error.message.includes('CSV parsing error:')) {
        // Error message has already been handled
        throw error;
      }
      console.error(error);
      chunkContext.addMessage(
        'Error converting CSV to JSON: ' + (error.message || error),
        'danger'
      );
      chunkContext.setBusy(false);
      throw error; // Rethrow to allow try/catch blocks to work
    }
  }
}
