import { inject, Injectable } from '@angular/core';
import { WSS_API_BASE, ARROW } from '../../constants/general.constants';
import { ToastrService } from 'ngx-toastr';
import { ArrowAliasService } from '../arrow-alias/arrow-alias.service';
import { ArrowAliasV2, DataSet } from '../../interfaces/arrow-alias.interface';
import { FileService } from '../file/file.service';
import { EventEmitter2 } from 'eventemitter2';
import { ArrowClient, CreateTableParams } from './arrow-client';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { ArrowHelper } from '../../helpers/arrow.helper';
import { Table } from 'apache-arrow';
import { AppStateService } from '../app-state/app-state.service';
import { AuthenticationService } from '../authentication/authentication.service';

/**
 * Represents a service for managing Arrow Websocket connections.
 * This service handles connecting to Arrow aliases, sending and receiving messages,
 * and managing the active connections.
 */
@Injectable({
  providedIn: 'root',
})
export class ArrowWebsocketService {
  #appState = inject(AppStateService);
  private socket!: WebSocket;
  private transId = 0;
  private transactions: Map<number, any> = new Map();
  private pendingTransactions: Map<number, any> = new Map();
  private uploads: Map<number, any> = new Map();
  private emitter: EventEmitter2 = new EventEmitter2({
    wildcard: true,
    ignoreErrors: true,
  });

  #clients = new Map<string, ArrowClient>();
  #currentClient: ArrowClient | undefined;

  constructor(
    private authService: AuthenticationService,
    private toastr: ToastrService,
    private arrowAliasService: ArrowAliasService,
    private fileService: FileService
  ) {}

  // ─────────────────────────────────────────────────────────────────────
  // ─── Do connection ───────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async connectToAliasNamed(
    aliasName: string,
    context?: ExecutionContext,
    standalone = false
  ): Promise<ArrowClient> {
    const projectId = this.#appState.projectId();
    return new Promise((resolve, reject) => {
      const cacheKey = `${aliasName}-${projectId}`;
      if (!standalone) {
        const existing = this.#clients.get(cacheKey);
        if (existing) {
          resolve(existing);
          return;
        }
      }
      // need to connect and return the connection
      this.connect([aliasName], context)
        .then(() => {
          if (this.#currentClient !== undefined) {
            const client = this.#currentClient;
            if (standalone) {
              this.#clients.delete(cacheKey);
              this.#currentClient = undefined;
            }
            resolve(client);
          } else {
            reject(new Error('failed to connect to alias'));
          }
        })
        .catch(() => {
          reject(new Error('failed to connect to alias'));
        });
    });
  }

  public async connect(
    args: any[],
    chunkContext?: ExecutionContext
  ): Promise<void> {
    if (this === undefined) {
      throw new Error('ArrowWebsocketService is undefined');
    }
    if (!args.length || typeof args[0] !== 'string' || !args[0].trim()) {
      if (chunkContext) {
        chunkContext.addMessage('Invalid or missing alias name.', 'danger');
      }
      return;
    }
    const aliasName = args[0];

    if (aliasName === undefined) {
      throw new Error('Alias name must be provided');
    }

    const alias = this.getAliasByName(aliasName);
    if (!alias) {
      this.toastr.error('Alias not found', 'Error');
      throw new Error('Alias not found');
    }
    const cacheKey = `${alias.name}-${alias.projectId}`;

    const existing = this.#clients.get(cacheKey);
    if (existing) {
      if (!existing.connected) {
        this.#clients.delete(cacheKey);
      } else {
        this.#currentClient = existing;
        return;
      }
    }

    // If no existing connection, set up a new one
    const token = await this.getAuthToken();
    if (!token) {
      throw new Error('Authentication token not available');
    }
    const url = `${WSS_API_BASE}${ARROW}/${alias.aliasId}?token=${token}`;
    const client = new ArrowClient(alias, url);

    this.#clients.set(cacheKey, client);
    this.#currentClient = client;
    client.wsClosedCallback = () => {
      this.#clients.delete(cacheKey);
      if (this.#currentClient === client) this.#currentClient = undefined;
    };
    await client.connect();
  }

  private async getAuthToken(): Promise<string | null> {
    try {
      return await this.authService.getIdToken();
    } catch (error) {
      console.error('Failed to get authentication token:', error);
      throw new Error('Authentication token not available');
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Do conenction ───────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async disconnect(args: any[]): Promise<void> {
    if (!this.#currentClient) {
      throw new Error('No active connection to disconnect');
    }
    await this.#currentClient.disconnect();
    this.#clients.delete(
      `${this.#currentClient.alias.name}-${this.#currentClient.alias.projectId}`
    );
    this.#currentClient = undefined;
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Helpers ─────────────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────
  private getAliasByName(name: string): ArrowAliasV2 | undefined {
    return this.arrowAliasService
      .aliasV2List()
      .find((item: ArrowAliasV2) => item.name === name);
  }

  // ─────────────────────────────────────────────────────────────────────
  // ─── Send/Receive message ────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  // ─────────────────────────────────────────────────────────────────────
  // ─── Shared Methods ──────────────────────────────────────────────────
  // ─────────────────────────────────────────────────────────────────────

  public async createDataSet(
    args: any[],
    chunkContext: ExecutionContext
  ): Promise<void> {
    if (this.#currentClient === undefined) {
      chunkContext.addMessage(
        'No active connection to create data set',
        'danger'
      );
      throw new Error('No active connection to create data set');
    }
    return this.#currentClient.createDataSet(
      args[0],
      args[1],
      args[2],
      args[3]
    );
  }

  public async deleteDataSet(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete data set');
    }
    return this.#currentClient.deleteDataSet(args[0]);
  }

  public async createView(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create view');
    }
    return this.#currentClient.createView(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4]
    );
  }

  public async deleteView(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete view');
    }
    return this.#currentClient.deleteView(args[0], args[1]);
  }

  public async listDataSets(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list data sets');
    }
    return this.#currentClient.listDataSets();
  }

  public async refreshDataSetList(): Promise<DataSet[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to refresh data set list');
    }
    return this.#currentClient.refreshDataSetList();
  }

  public async listCloud(args: any[]): Promise<string[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list cloud');
    }
    return this.#currentClient.listCloud(args[0] ?? '');
  }

  public async cloudBasePathExists(args: any[]): Promise<boolean> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to check cloud base path');
    }
    return this.#currentClient.cloudBasePathExists(args[0]);
  }

  public async listViews(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list views');
    }
    return this.#currentClient.listViews(args[0]);
  }

  public async getSchema(args: any[]): Promise<Object> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to get schema');
    }
    return this.#currentClient.getSchema(args[0]);
  }

  public async initCache(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to init cache');
    }
    return this.#currentClient.initCache(args[0]);
  }

  public async performQuery(args: any[]): Promise<Uint8Array> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to perform query');
    }
    if (args.length == 1) {
      return this.#currentClient.performQuery(undefined, args[0]);
    }
    return this.#currentClient.performQuery(args[0], args[1]);
  }

  public async performQueryAsJson(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to query data as json');
    }
    let dsname = args[0];
    let query = args[1];
    if (args.length == 1) {
      query = args[0];
      dsname = undefined;
    }
    // last param gurantees that the data is returned as arrow table
    const table = (await this.#currentClient.performQuery(
      dsname,
      query,
      true
    )) as any as Table;
    const results = ArrowHelper.tableToJson(table);
    return JSON.stringify(results);
  }

  public async saveQueryAsTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to save query as table');
    }
    return this.#currentClient.saveQueryAsTable(args[0], args[1]);
  }

  public async saveQueryToCloud(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to save query to cloud');
    }
    return this.#currentClient.saveQueryToCloud(args[0], args[1], args[2]);
  }

  public async performSqlQuery(args: any[]): Promise<Uint8Array> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to perform query');
    }
    return this.#currentClient.performSqlQuery(args[0], args[1], false);
  }

  public async saveSqlQueryAsTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to save sql query as table');
    }
    return this.#currentClient.saveSqlQueryAsTable(args[0], args[1], args[2]);
  }

  public async doAction(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to do action');
    }
    return this.#currentClient.doAction(args[0], args[1]);
  }

  public async createPut(args: any[]): Promise<number> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create put');
    }
    return this.#currentClient.createPut(
      args[0],
      args[1],
      args[2],
      args[3],
      args[4]
    );
  }

  public async initPut(args: any[]): Promise<number> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to init put');
    }
    return this.#currentClient.initPut(args[0]);
  }

  public async continuePut(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to continue put');
    }
    return this.#currentClient.continuePut(args[0], args[1]);
  }

  public async endPut(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to end put');
    }
    return this.#currentClient.endPut(args[0]);
  }

  public async listTables(args: any[]): Promise<Object[]> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to list tables');
    }
    return this.#currentClient.listTables();
  }

  public async createTable(args: any[]): Promise<Object> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to create table');
    }
    return this.#currentClient.createTable(
      args[0] as unknown as CreateTableParams
    );
  }

  public async uploadTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to upload table');
    }
    return this.#currentClient.uploadTable(args[0], args[1]);
  }

  public async updateTables(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to update tables');
    }
    return this.#currentClient.updateTables();
  }

  public async deleteTable(args: any[]): Promise<void> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to delete table');
    }
    return this.#currentClient.deleteTable(args[0]);
  }

  public async presignUrl(args: any[]): Promise<string> {
    if (this.#currentClient === undefined) {
      throw new Error('No active connection to get presigned url');
    }
    return this.#currentClient.presignUrl(args[0], args[1], args[2]);
  }
}
