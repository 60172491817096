import { inject, Injectable } from '@angular/core';
import {
  PROJECT,
  API_BASE,
  VERSION,
  USERS,
  INVITES,
  OPERATOR_ROLE_ID,
} from '../../constants/general.constants';
import { catchError, EMPTY, map, Observable, Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  Project,
  ProjectCreate,
  Version,
  VersionCreate,
  VersionUpdate,
} from '../../interfaces/project.interface';
import { User } from '../../interfaces/user.interface';
import { PermissionsService } from '../permissions/permissions.service';
import { PermissionId } from '../../interfaces/global-role.interface';
import { AppStateService } from '../app-state/app-state.service';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';

const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  #permissionsService = inject(PermissionsService);
  #appState = inject(AppStateService);

  public $setProjectListSubject = new Subject<Project[]>();
  public projectList: Project[] = [];

  constructor(private http: HttpClient) { }

  // Helper method to construct project-related URLs
  private createProjectUrl(
    projectId?: string | number,
    resource?: string,
    subResource?: string
  ): string {
    let url = `${API_BASE}${PROJECT}`;
    if (projectId !== undefined) {
      url += `/${projectId}`;
    }
    if (resource) {
      url += `/${resource}`;
    }
    if (subResource) {
      url += `/${subResource}`;
    }
    return url;
  }

  // Helper method to handle FormData creation for project import
  private createFormData(file: File, jsonData: {
    projectName: string;
    versionId: number;
    password: string;
  }): FormData {
    const formData = new FormData();

    // Convert JSON object to a string
    const jsonString = JSON.stringify(jsonData);
    const jsonBlob = new Blob([jsonString], { type: 'application/json' });

    // Create a File object from the Blob
    const jsonFile = new File([jsonBlob], 'import-data.json', {
      type: 'application/json',
    });

    // Append the JSON file and project zip file
    formData.append('json', jsonFile);
    formData.append('file', file);

    return formData;
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create Notebook

  /**
   * Create a new project.
   * @param data - The data required to create a new project.
   * @returns An Observable containing the created project.
   */
  create(data: ProjectCreate): Observable<Project> {
    return this.http.post<Project>(this.createProjectUrl(), data);
  }

  /**
   * Fetch all projects.
   * @returns An Observable containing the list of projects.
   */
  get(): Observable<Project> {
    return this.http.get<Project>(this.createProjectUrl()).pipe(
      map((res: any) => {
        if (res === null) return;
        this.$setProjectListSubject.next(res);
        return res;
      })
    );
  }

  /**
   * Fetch paginated projects with server-side sorting.
   * @param offset - The starting position in the results set (defaults to 0)
   * @param limit - The maximum number of results to return (defaults to 50)
   * @param sort - The column to sort by (defaults to "Name") 
   * @param desc - Whether to sort in descending order (defaults to false)
   * @returns An Observable containing the paginated list of projects.
   */
  getPaginated(offset: number = 0, limit: number = 50, sort: string = 'Name', desc: boolean = false) {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sort', sort)
      .set('desc', desc.toString());

    return this.http.get<Project[]>(this.createProjectUrl(), { 
      params,
      observe: 'response' 
    }).pipe(
      map((response) => {
        // Extract total count from header if available
        // The API should include a header like 'X-Total-Count' with the total number of records
        const totalCount = response.headers.get('X-Total-Count');
        const projects = response.body || [];
        
        // Return an object with both the projects and total count
        return {
          projects,
          totalCount: totalCount ? parseInt(totalCount, 10) : undefined
        };
      })
    );
  }

  /**
   * Fetch project by its ID.
   * @param projectId - The ID of the project.
   * @returns An Observable containing the project data.
   */
  getById(projectId: string): Observable<Project> {
    return this.http.get<Project>(this.createProjectUrl(projectId));
  }

  /**
   * Update a project by its ID.
   * @param data - The data to be updated.
   * @param projectId - The ID of the project.
   * @returns An Observable containing the updated project data.
   */
  public update(
    data: { name: string; description: string },
    projectId: string
  ): Observable<Project> {
    return this.http.put<Project>(this.createProjectUrl(projectId), data);
  }

  /**
   * Delete a project by its ID.
   * @param projectId - The ID of the project.
   * @returns An Observable after project deletion.
   */
  public delete(projectId: string | null): Observable<object> {
    if (projectId === null) {
      return EMPTY;
    }
    return this.http.delete<Project>(this.createProjectUrl(projectId));
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Project Versions

  createProjectVersion(
    projectId: number,
    version: VersionCreate
  ): Observable<any> {
    return this.http.post(this.createProjectUrl(projectId, VERSION), version);
  }

  updateProjectVersion(
    projectId: number,
    versionId: number,
    version: VersionUpdate
  ): Observable<any> {
    return this.http.put(
      this.createProjectUrl(projectId, VERSION, `${versionId}`),
      version
    );
  }

  // Remove a user from a project
  removeUserFromProjectTeam(
    projectId: number,
    userId: number
  ): Observable<any> {
    return this.http.delete(
      this.createProjectUrl(projectId, USERS, `${userId}`)
    );
  }

  //--------------------------------------------------------------------------------
  // Project Invites

  // Fetch all invites for a specific project
  getInvitesForProject(projectId: number): Observable<any> {
    return this.http.get(this.createProjectUrl(projectId, INVITES));
  }

  // Invite a user to a project
  inviteUserToProject(
    projectId: number,
    inviteData: { inviteeId?: number; email?: string; roleId: number }
  ): Observable<any> {
    // Create a new object with the correct field names for the API
    const apiInviteData: any = {
      roleId: inviteData.roleId,
      // Always include inviteeId, use 0 as a default if not provided
      inviteeId: inviteData.inviteeId || 0
    };
    
    // If email is provided, add it as an additional field
    if (inviteData.email) {
      apiInviteData.inviteeEmail = inviteData.email;
    }
    
    return this.http.post(
      this.createProjectUrl(projectId, INVITES),
      apiInviteData
    );
  }

  // Get details of a specific invite for a project
  getInviteForProject(projectId: number, inviteId: number): Observable<any> {
    return this.http.get(
      this.createProjectUrl(projectId, INVITES, `${inviteId}`)
    );
  }

  // Cancel an invite for a project
  cancelInviteForProject(projectId: number, inviteId: number): Observable<any> {
    return this.http.put(
      this.createProjectUrl(projectId, INVITES, `${inviteId}`),
      {}
    );
  }

  /**
   * Gets the full information for a project version including files and notebooks
   * @param projectId The id of the project
   * @param versionId The id of the version
   * @returns The full version object
   */
  getFullVersion(
    projectId: number | undefined,
    versionId: number | undefined
  ): Observable<Version> {
    if (projectId === undefined || versionId === undefined) {
      return EMPTY;
    }
    return this.http.get<Version>(
      this.createProjectUrl(projectId, VERSION, `${versionId}`)
    );
  }

  //--------------------------------------------------------------------------------
  // Fill version with notebooks and files based on version

  getProjectNotebookAndFiles(
    projectId: string,
    versionId: string | null = `${this.#appState.versionId()}`
  ): Observable<any> {
    if (!versionId) {
      return EMPTY;
    }
    return this.http.get(this.createProjectUrl(projectId, VERSION, versionId));
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get selected project

  public getSelectedProject(): Project | any {
    const projectId = this.#appState.projectId();
    const projectList = this.#appState.projects();
    let selectedProject: Project | any = {};
    if (!!projectId !== false && projectList.length > 0) {
      for (const element of projectList) {
        if (projectId == element.projectId) {
          selectedProject = element;
          break;
        }
      }
    }
    if (!!projectId !== false && projectList.length > 0) {
      selectedProject = projectList[0];
      this.#appState.navigateToProject(selectedProject.projectId);
    }
    return selectedProject;
  }

  /**
   * Filter projects by name.
   * @param filterValue - The search term.
   * @param offset - The starting position in the results set.
   * @param limit - The maximum number of results to return.
   * @param sort - The column to sort by.
   * @param desc - Whether to sort in descending order.
   * @returns An Observable containing the filtered list of projects.
   */
  public getFiltered(
    filterValue: string, 
    offset: number = 0, 
    limit: number = 50, 
    sort: string = 'Name', 
    desc: boolean = false
  ) {
    const params = new HttpParams()
      .set('offset', offset.toString())
      .set('limit', limit.toString())
      .set('sort', sort)
      .set('desc', desc.toString())
      .set('search', filterValue);
      
    return this.http.get<Project[]>(this.createProjectUrl(), { 
      params,
      observe: 'response' 
    }).pipe(
      map((response) => {
        // Extract total count from header if available
        const totalCount = response.headers.get('X-Total-Count');
        const projects = response.body || [];
        
        // Return an object with both the projects and total count
        return {
          projects,
          totalCount: totalCount ? parseInt(totalCount, 10) : undefined
        };
      })
    );
  }

  public getCurrentUserRoleFromProject(
    project: Project | undefined = this.#appState.project()
  ) {
    if (!project?.users?.length) return;
    const userId = this.#appState.user()?.userId ?? 0;
    const projectUser = project.users.find((u) => u.userId === userId);

    return projectUser ? projectUser.roleName : '';
  }

  /**
   * Filters an array of projects to remove any HEAD versions that the user does not have permission to edit,
   * then removes any projects with no versions left
   * @param projects The array of projects to filter
   * @param user The user to check permissions for
   * @returns A filtered array that excludes projects the user does not have permission to view
   */
  public filterProjectsThatCanBeOpened(
    projects: Project[],
    user: User
  ): Project[] {
    return projects
      .map((project) => {
        if (
          this.getUserHasPermissionForProject(
            project,
            user,
            PermissionId.WRITE
          ) ||
          this.getUserHasPermissionForProject(project, user, PermissionId.READ)
        ) {
          return {
            ...project,
            selectedVersion: this.getHeadVersion(project),
          };
        }
        return project;
      })
      .filter((project) => project.versions && project.versions.length > 0);
  }

  /**
   * Gets the role of the user in the project
   * @param project The project to get the user role from
   * @param user The user to get the role for
   * @param permission The permission to check
   * @returns The role of the user in the project
   */
  public getUserHasPermissionForProject(
    project: Project,
    user: User,
    permission: PermissionId
  ): boolean {
    const projectUser = project.users?.find((u) => u.userId === user.userId);

    if (!projectUser) {
      return false;
    }

    const roleId =
      LocalstorageHelper.getProjectLockFlag() === true || !this.#appState.isHeadVersion()
        ? OPERATOR_ROLE_ID
        : projectUser.roleId;
    const hasPermission = this.#permissionsService.roleHasPermission(
      this.#permissionsService.roleWithId(roleId),
      permission
    );

    return hasPermission;
  }

  /**
   * Checks to see if the current user has a specific permission for the current project
   * @param permission The desired permission
   * @returns true if the user has the permission, false otherwise
   */
  public getCurrentUserHasProjectPermission(permission: PermissionId) {
    const project = this.#appState.project();
    const user = this.#appState.user();
    if (!project || !user) {
      return false;
    }
    const hasPermission = this.getUserHasPermissionForProject(
      project,
      user,
      permission
    );
    return hasPermission;
  }

  /**
   * Gets the head version of the project
   * @param project The project to get the head version from
   * @returns The head version of the project
   */
  public getHeadVersion(project: Project): Version | undefined {
    return (
      project.versions?.find(
        (version) => version.majorVersion === 0 && version.minorVersion === 0
      ) || project.versions?.[0]
    );
  }

  /**
   * Export a project by its ID.
   * @param projectId - The ID of the project to export.
   * @param password - The password to encrypt project secrets.
   * @returns An Observable for the export operation.
   */
  exportProject(projectId: string, password: string): Observable<Blob> {
    return this.http.post(
      this.createProjectUrl(projectId, 'export'),
      { password: password },
      { responseType: 'blob' }
    );
  }

  /**
   * Import a project or version from an exported file with password support
   * @param file - The exported project file (zip)
   * @param projectName - Name for the imported project
   * @param versionId - Version ID
   * @param password - Password for decrypting project secrets
   * @returns Observable containing import results and any warnings
   */
  importProject(
    file: File,
    projectName: string,
    versionId: number,
    password: string
  ): Observable<any> {
    if (file.size > MAX_FILE_SIZE) {
      return throwError(() => new Error('File size exceeds maximum allowed'));
    }

    if (!file.name.endsWith('.zip')) {
      return throwError(
        () => new Error('Invalid file type. Only zip files are allowed')
      );
    }

    // Password validation
    if (!password || password.length < 8) {
      return throwError(
        () => new Error('Password must be at least 8 characters long')
      );
    }

    const formData = this.createFormData(file, {
      projectName,
      versionId,
      password
    });

    return this.http.post<any>(`${API_BASE}import`, formData).pipe(
      catchError((error) => {
        console.error('Import failed:', error);
        const errorMessage = error.error?.message || 'Import failed. Please try again.';
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  automatedImportProject(
    blob: Blob,
    projectName: string,
    versionId: number,
    password: string
  ): Observable<any> {
    const file = new File([blob], 'project_export.zip', {
      type: 'application/zip',
    });

    if (file.size > MAX_FILE_SIZE) {
      return throwError(() => new Error('File size exceeds maximum allowed'));
    }

    // Password validation
    if (!password || password.length < 8) {
      return throwError(
        () => new Error('Password must be at least 8 characters long')
      );
    }

    const formData = this.createFormData(file, {
      projectName,
      versionId,
      password
    });

    return this.http.post<any>(`${API_BASE}import`, formData).pipe(
      catchError((error) => {
        console.error('Automated import failed:', error);
        const errorMessage = error.error?.message || 'Automated import failed. Please try again.';
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  /**
   * Add a feature to a project
   * @param projectId - The ID of the project
   * @param featureId - The ID of the feature to add
   * @returns An Observable containing the updated project
   */
  addProjectFeature(projectId: number, featureId: number): Observable<Project> {
    return this.http.post<Project>(
      this.createProjectUrl(projectId, 'feature', `${featureId}`),
      {}
    );
  }

  /**
   * Remove a feature from a project
   * @param projectId - The ID of the project
   * @param featureId - The ID of the feature to remove
   * @returns An Observable containing the updated project
   */
  removeProjectFeature(
    projectId: number,
    featureId: number
  ): Observable<Project> {
    return this.http.delete<Project>(
      this.createProjectUrl(projectId, 'feature', `${featureId}`)
    );
  }

  /**
   * Checks if a user can access HEAD version based on their role
   * @returns boolean indicating if user has HEAD version access
   */
  canAccessHeadVersion(): boolean {
    const currentRole = this.getCurrentUserRoleFromProject();
    return currentRole !== 'operator';
  }

  /**
   * Checks if current user can lock/unlock the project
   * @returns boolean indicating if user can perform lock operations
   */
  canPerformLockOperations(): boolean {
    // Only users who can see HEAD version and have write permission can lock
    let canPerformLockOperation = false

    for (const permission in Object.values(PermissionId)) {
      const permissionId = Number(permission);

      if (permissionId > PermissionId.READ) {
        canPerformLockOperation = this.getCurrentUserHasProjectPermission(Number(permissionId));

        if (canPerformLockOperation) break;
      }
    }

    return this.canAccessHeadVersion() && canPerformLockOperation;
  }

  /**
   * Checks if current user can force unlock the project
   * @returns boolean indicating if user can force unlock
   */
  canForceUnlock(): boolean {
    return this.#appState.isProjectOwner() // Admin roleId
  }
}
