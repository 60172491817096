import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE } from '../../constants/general.constants';

// Define the Feature interface
export interface Feature {
  featureId: number;
  name: string;
  displayName: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureService {
  private readonly featuresEndpoint = `${API_BASE}features`;

  constructor(private http: HttpClient) {}

  // ────────────────────────────────────────────────────────────────────────────────
  // Global Features Management

  /**
   * Get all available features in the system
   * @returns Observable<Feature[]> List of all features
   */
  getFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.featuresEndpoint);
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Helper Methods

  /**
   * Check if a feature is enabled for a user based on their featureIds
   * @param userFeatureIds Array of feature IDs enabled for the user
   * @param featureId Feature ID to check
   * @returns boolean Whether the feature is enabled
   */
  isFeatureEnabled(userFeatureIds: number[], featureId: number): boolean {
    return userFeatureIds.includes(featureId);
  }

  /**
   * Get feature by ID from a list of features
   * @param features Array of features
   * @param featureId Feature ID to find
   * @returns Feature | undefined The found feature or undefined
   */
  getFeatureById(features: Feature[], featureId: number): Feature | undefined {
    return features.find((feature) => feature.featureId === featureId);
  }

  /**
   * Get feature by name from a list of features
   * @param features Array of features
   * @param featureName Feature name to find
   * @returns Feature | undefined The found feature or undefined
   */
  getFeatureByName(
    features: Feature[],
    featureName: string
  ): Feature | undefined {
    return features.find((feature) => feature.name === featureName);
  }
}
