<form>
  <mat-form-field class="search-input no-wrapper w100">
    <mat-icon matPrefix>search</mat-icon>
    <input
      matInput
      placeholder="Search projects, notebooks and chunks..."
      [formControl]="searchCtrl"
      [matAutocomplete]="auto"
    />
    <mat-spinner
      matSuffix
      diameter="20"
      *ngIf="isLoading"
    ></mat-spinner>
  </mat-form-field>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayResult"
    (optionSelected)="handleSelection($event)"
  >
    <mat-option
      *ngFor="let result of filteredResults | async"
      [value]="result"
      [class]="'result-type-' + result.resultType"
    >
      <mat-icon [class]="result.resultType + '-icon'">
        {{
          result.resultType === 'project' ? 'folder' :
          result.resultType === 'notebook' ? 'book' : 'snippet_folder'
        }}
      </mat-icon>
      <span class="result-text">{{ displayResult(result) }}</span>
    </mat-option>
  </mat-autocomplete>
</form>
