import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddProjectDialogComponent } from './add-project-dialog.component';
// ─────────────────────────────────────────────────────────────────────────────
// Material
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';

// ─────────────────────────────────────────────────────────────────────────────
// Form support
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// ─────────────────────────────────────────────────────────────────────────────
// Layput
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ─────────────────────────────────────────────────────────────────────────────
// File upload module
import { FileUploadModule } from '@iplab/ngx-file-upload';
// ─────────────────────────────────────────────────────────────────────────────
// Manage team module
import { ManageTeamModule } from '../manage-team/manage-team.module';



@NgModule({
  imports: [
    CommonModule,
    // Material
    MatStepperModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatSelectModule,
    MatListModule,
    // Form support
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    // Layout
    FlexLayoutModule,
    // File upload module
    FileUploadModule,
    // Custom modules
    ManageTeamModule,
  ],
  declarations: [AddProjectDialogComponent],
  exports: [AddProjectDialogComponent],
})
export class AddProjectDialogModule { }